import {Action} from '@ngrx/store';
import { TableAllDevice} from '@interfaces';
import {AccidentsActionTypes} from './accidents.action';
import {GlobalActionTypes} from './global.actions';


export enum VolumesActionTypes {
  SetVolumes = '[SetVolumes] Set Accidents',
  UpdateVolume = '[SetVolumes] UpdateVolume',
  Clear = '[Volumes] Loguot',
  SetVolumesType = '[SetVolumesType] Set Volumes Type',
  SetFromDateVolumes = '[Volumes] Set From Date',
  SetToDateVolumes = '[Volumes] Set To Date',
  SetTooDateVolumes = '[Volumes] Set Too Date'
}
export class ClearStateVolumesAction implements Action {
  readonly type = VolumesActionTypes.Clear;
  constructor() {}
}
export class SetVolumesActions implements Action {
  readonly type = VolumesActionTypes.SetVolumes;
  constructor(public payload: TableAllDevice) {}
}

export class UpdateVolumeActions implements Action {
  readonly type = VolumesActionTypes.UpdateVolume;
  constructor(public payload: any) {}
}
export class SetVolumesTypeActions implements Action {
  readonly type = VolumesActionTypes.SetVolumesType;
  constructor(public payload: string) {}
}
export class SetDateFromVolumeAction implements Action {
  readonly type = VolumesActionTypes.SetFromDateVolumes;
  constructor(public payload: Date) {}
}

export class SetDateToVolumeAction implements Action {
  readonly type = VolumesActionTypes.SetToDateVolumes;
  constructor(public payload: Date) {}
}

export class SetDateTooVolumeAction implements Action {
  readonly type = VolumesActionTypes.SetTooDateVolumes;
  constructor(public payload: Date) {}
}

export type VolumesAction = SetVolumesActions | UpdateVolumeActions | ClearStateVolumesAction | SetVolumesTypeActions | SetDateFromVolumeAction | SetDateToVolumeAction | SetDateTooVolumeAction;

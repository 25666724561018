export const getRandomSoftColorHex = (alpha: number): string => {
  alpha = Math.max(0, Math.min(255, alpha));

  const getRandomComponent = (): number => {
    return Math.floor(Math.random() * 106) + 150;
  };

  const r = getRandomComponent();
  const g = getRandomComponent();
  const b = getRandomComponent();

  const componentToHex = (c: number): string => {
    const hex = c.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  };

  const rHex = componentToHex(r);
  const gHex = componentToHex(g);
  const bHex = componentToHex(b);
  const alphaHex = alpha.toString(16).toUpperCase();

  return `#${rHex}${gHex}${bHex}${alphaHex}`;
};

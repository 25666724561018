import { AppState } from "./../../redux/app.state";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { map } from "rxjs/internal/operators/map";
import { take } from "rxjs/internal/operators/take";

@Injectable({
  providedIn: "root",
})
export class PermissionService {
  constructor(private store: Store<AppState>) {}

  public checkStartPagePermission(path: string): string {
    let result: string = "";
    // if (environment.pressure) {
    //   result ='/main/telem';
    // } else {
    if (this.checkPermission() === "true" || this.checkPermission() === null) {
      result = path;
    } else {
      result = path === "/main" || path === "/main/devices" ? path : "/main";
    }
    // }
    return result;
  }

  public checkPermission(): string {
    let result: string;
    this.store
      .pipe(
        map((state: AppState) => {
          return state.globalApp.user.permissions;
        }),
        take(1),
      )
      .subscribe((perm) => {
        result = perm;
      })
      .unsubscribe();
    return result;
  }
}

import { NgModule, Optional, SkipSelf } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { StoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { ReducerApp } from "../redux/reducers/reducers";
import { JwtInterceptor } from "./service/interceptors/jwt";
import { AllResponces } from "./service/interceptors/success";
import { ErrorInterceptor } from "./service/interceptors/errors";
import { PrimeNgConfigModule } from "./prime-ng-config/prime-ng-config.module";
import { rootReducer } from "@pressure-state/root";
import { routerReducer } from "@ngrx/router-store";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
    PrimeNgConfigModule,
    StoreModule.forRoot(
      { ...ReducerApp, ...rootReducer },
      {
        runtimeChecks: {
          strictStateImmutability: false,
          strictActionImmutability: false,
        },
      },
    ),
    StoreDevtoolsModule.instrument(),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AllResponces, multi: true },
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error("CoreModule is already loaded. Import only in AppModule");
    }
  }
}

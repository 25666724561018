import {Action} from '@ngrx/store';
import { TableAllDevice} from '@interfaces';
import {AccidentsActionTypes} from './accidents.action';
import {GlobalActionTypes} from './global.actions';


export enum VolumesTelemActionTypes {
  SetVolumes = '[SetVolumesTelem] Set Accidents',
  UpdateVolume = '[SetVolumesTelem] UpdateVolume',
  Clear = '[VolumesTelem] Loguot',
  SetVolumesType = '[SetVolumesTelemType] Set Volumes Type',
  SetFromDateVolumes = '[VolumesTelem] Set From Date',
  SetToDateVolumes = '[VolumesTelem] Set To Date',
  SetTooDateVolumes = '[VolumesTelem] Set Too Date'
}
export class ClearStateVolumesTelemAction implements Action {
  readonly type = VolumesTelemActionTypes.Clear;
  constructor() {}
}
export class SetVolumesTelemActions implements Action {
  readonly type = VolumesTelemActionTypes.SetVolumes;
  constructor(public payload: TableAllDevice) {}
}

export class UpdateVolumeTelemActions implements Action {
  readonly type = VolumesTelemActionTypes.UpdateVolume;
  constructor(public payload: any) {}
}
export class SetVolumesTelemTypeActions implements Action {
  readonly type = VolumesTelemActionTypes.SetVolumesType;
  constructor(public payload: string) {}
}
export class SetDateFromVolumeTelemAction implements Action {
  readonly type = VolumesTelemActionTypes.SetFromDateVolumes;
  constructor(public payload: Date) {}
}

export class SetDateToVolumeTelemAction implements Action {
  readonly type = VolumesTelemActionTypes.SetToDateVolumes;
  constructor(public payload: Date) {}
}

export class SetDateTooVolumeTelemAction implements Action {
  readonly type = VolumesTelemActionTypes.SetTooDateVolumes;
  constructor(public payload: Date) {}
}

export type VolumesTelemAction = SetVolumesTelemActions | UpdateVolumeTelemActions | ClearStateVolumesTelemAction 
| SetVolumesTelemTypeActions | SetDateFromVolumeTelemAction | SetDateToVolumeTelemAction | SetDateTooVolumeTelemAction;

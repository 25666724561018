import { Dimension } from "../types";

export class DimensionsHelpers {
  static getDimension(inputValue: unknown, dimension: Dimension): string {
    const value: number = Number(inputValue);

    if (!value || isNaN(value)) {
      return "";
    }

    let tmp: number;

    if (dimension === "kgssm") {
      tmp = value;
    }
    if (dimension === "kpa") {
      tmp = value / 0.0101972;
    }
    return tmp.toString();
  }
}

import { AuthorizationService } from "./core/service/authorization.service";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { DetectedHostService } from "./core/service/detected.host.service";
import { LocalizationService } from "./core/service/localization.service";
import { filter, take } from "rxjs/operators";
import { NavigationEnd, Router } from "@angular/router";
import { AppState } from "@state";
import { Store } from "@ngrx/store";
import { environment } from "@env";
import { Title } from "@angular/platform-browser";
import { SwUpdate } from "@angular/service-worker";
import { ToasterService } from "./core/service/toaster.service";
import { TranslateService } from "@ngx-translate/core";
import { LocalStorService } from "./core/service/localStor.service";
const { version: appVersion } = require("../../package.json");

import { Subscription } from "rxjs";
import { PrimeNgConfigService } from "./core/prime-ng-config/services/prime-ng-config.service";
import { SetActivePageAction } from "./redux/actions/global.actions";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.sass"],
})
export class AppComponent implements OnInit, OnDestroy {
  public subLang: Subscription;
  private title =
    environment.titleLeft +
    environment.titleRight +
    " - " +
    environment.subTitle;
  constructor(
    private host: DetectedHostService,
    private local: LocalizationService,
    private auth: AuthorizationService,
    private translateService: TranslateService,
    private router: Router,
    private store: Store<AppState>,
    private titleService: Title,
    private primeConfig: PrimeNgConfigService,
  ) {
    this.auth.getCredentional();
    this.initFonts();
    this.translateService.use("ua-UK").pipe(take(1)).subscribe();
  }

  public ngOnInit(): void {
    console.info(
      "%cSmart Pressure: Logged to version: " + appVersion,
      "color: white; background-color: #2a2266; padding: 2px; font-size: 14px;",
    );
    this.titleService.setTitle(this.title);
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        if (event.url.substr(1) === "") {
          this.store.dispatch(new SetActivePageAction("main"));
        } else {
          this.store.dispatch(new SetActivePageAction(event.url.substr(1)));
        }
      });
    this.local.autoLang();
    this.host.setHost();

    // TODO IMPROVE UPDATE LOGIC
    // this.swUpdate.versionUpdates
    //   .subscribe (update => {
    //     document.location.reload();
    //     console.log('new ver');
    //   });
    this.isUpdateApp();

    this.primeConfig.init();
  }

  isUpdateApp() {
    // TODO IMPROVE UPDATE LOGIC
    // if(this.localStor.getAppVer() !== appVersion) {
    //   setTimeout(()=>{
    //     this.toaster.show('success', '', this.translateService.instant('updated.new.version') + ' ' +  appVersion);
    //   }, 1000);
    //   this.localStor.saveAppVer();
    // }
  }

  ngOnDestroy(): void {
    if (this.subLang) this.subLang.unsubscribe();
  }

  private initFonts(): void {
    const myCss = document.createElement("link");
    myCss.rel = "stylesheet";
    myCss.href = "assets/fonts/fonts.css";
    document.head.insertBefore(
      myCss,
      document.head.childNodes[document.head.childNodes.length - 1].nextSibling,
    );
  }
}

import { Action } from "@ngrx/store";
import { City, District, Navigation } from "@interfaces";
import { TableSizePage } from "../../shared/interfaces/table-size-page";

export enum GlobalActionTypes {
  SetActivePage = "[Global] Set Active Page",
  SetLang = "[Global] Set Lang",
  SetFromDate = "[Global] Set From Date",
  SetToDate = "[Global] Set To Date",
  SetTooDate = "[Global] Set Too Date",
  SELECTED_TEMPLATE_TELEMETRY_PAGE_VISIBLE = "[Global] SELECTED_TEMPLATE_TELEMETRY_PAGE_VISIBLE",
  DEFAULT_TEMPLATE_TELEMETRY_PAGE_VISIBLE = "[Global] DEFAULT_TEMPLATE_TELEMETRY_PAGE_VISIBLE",
  SELECTED_TEMPLATE_VOLUMES_PAGE_VISIBLE = "[Global] SELECTED_TEMPLATE_VOLUMES_PAGE_VISIBLE ",
  SetUser = "[Global] Set User",
  LoadedUserInfo = "[Global] Loaded User Info",
  GetPermissionUser = "[Global] Get Permission User",
  SetEditUser = "[Global] Set Edit User",
  GetPermissionUserMenu = "[Global] Get Permission User Menu",
  SetCity = "[Global] Set City",
  SetTablePageSize = "[Global] SetTablePageSize",
  SetRegion = "[Global] Set Region",
  SetDistrict = "[Global] Set District",
  SetTablePagesSize = "[Global] SetTablePagesSize",
  SetVisibleChildMainPanel = "[Global] SetVisibleChildMainPanel",
  Clear = "[Global] Loguot",
}

export class SetVisibleChildMainPanelAction implements Action {
  readonly type = GlobalActionTypes.SetVisibleChildMainPanel;
  constructor(public payload: boolean) {}
}

export class SetCityAction implements Action {
  readonly type = GlobalActionTypes.SetCity;
  constructor(public payload: City) {}
}

export class SetTablePageSizeAction implements Action {
  readonly type = GlobalActionTypes.SetTablePageSize;

  constructor(public payload: number) {}
}

export class SetTablePagesSizeAction implements Action {
  readonly type = GlobalActionTypes.SetTablePagesSize;

  constructor(public payload: TableSizePage) {}
}

export class SetRegionAction implements Action {
  readonly type = GlobalActionTypes.SetRegion;

  constructor(public payload: string[]) {}
}
export class SetDistrictAction implements Action {
  readonly type = GlobalActionTypes.SetDistrict;

  constructor(public payload: District) {}
}

export class SetActivePageAction implements Action {
  readonly type = GlobalActionTypes.SetActivePage;

  constructor(public payload: string) {}
}

export class SetLangAction implements Action {
  readonly type = GlobalActionTypes.SetLang;

  constructor(public payload: string) {}
}

export class SetUserAction implements Action {
  readonly type = GlobalActionTypes.SetUser;

  constructor(public payload: string) {}
}

export class LoadedUserInfoAction implements Action {
  readonly type = GlobalActionTypes.LoadedUserInfo;

  constructor(public payload: boolean) {}
}

export class GetPermissionUserAction implements Action {
  readonly type = GlobalActionTypes.GetPermissionUser;

  constructor(public payload: string) {}
}
export class SetEditUserAction implements Action {
  readonly type = GlobalActionTypes.SetEditUser;

  constructor(public payload: boolean) {}
}
export class GetPermissionUserMenuAction implements Action {
  readonly type = GlobalActionTypes.GetPermissionUserMenu;

  constructor(public payload: Navigation[]) {}
}

export class SetDateFromAction implements Action {
  readonly type = GlobalActionTypes.SetFromDate;

  constructor(public payload: Date) {}
}

export class SetDateToAction implements Action {
  readonly type = GlobalActionTypes.SetToDate;

  constructor(public payload: Date) {}
}
export class SetDateTooAction implements Action {
  readonly type = GlobalActionTypes.SetTooDate;

  constructor(public payload: Date) {}
}

export class SelectedTemplateTelemetryPageVisibleAction implements Action {
  readonly type = GlobalActionTypes.SELECTED_TEMPLATE_TELEMETRY_PAGE_VISIBLE;
  constructor(public payload: "table" | "speedometer") {}
}

export class DefaultTemplateTelemetryPageVisibleAction implements Action {
  readonly type = GlobalActionTypes.DEFAULT_TEMPLATE_TELEMETRY_PAGE_VISIBLE;
}

export class SelectedTemplateVolumesPageVisibleAction implements Action {
  readonly type = GlobalActionTypes.SELECTED_TEMPLATE_VOLUMES_PAGE_VISIBLE;
  constructor(public payload: "table" | "speedometer") {}
}

export class ClearStateGlobalAction implements Action {
  readonly type = GlobalActionTypes.Clear;
  constructor() {}
}

export type GlobalActions =
  | SetActivePageAction
  | SetLangAction
  | SetCityAction
  | SetRegionAction
  | SetDistrictAction
  | ClearStateGlobalAction
  | SetDateFromAction
  | SetDateToAction
  | SetDateTooAction
  | SelectedTemplateTelemetryPageVisibleAction
  | SetUserAction
  | SetEditUserAction
  | DefaultTemplateTelemetryPageVisibleAction
  | GetPermissionUserAction
  | LoadedUserInfoAction
  | SelectedTemplateVolumesPageVisibleAction
  | GetPermissionUserMenuAction
  | SetTablePageSizeAction
  | SetTablePagesSizeAction
  | SetVisibleChildMainPanelAction;

import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  AfterViewInit,
  ElementRef,
  ViewChild,
  OnDestroy,
} from "@angular/core";
import {
  Pressure,
  PressureAccidents,
  CancelAccidents,
  GlobalApp,
} from "@interfaces";
import { DateAppService } from "app/core/service/date-app.service";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { Observable, Subscription } from "rxjs";
import { AppState } from "@state";
import { Store } from "@ngrx/store";
import { TelemetryPage } from "../../interfaces/telemetry-page";
import { map } from "rxjs/operators";
import { environment } from "@env";
import { Env } from "app/shared/interfaces/env";
import { TranslateService } from "@ngx-translate/core";

am4core.useTheme(am4themes_animated);

@Component({
  selector: "app-telem-widget",
  templateUrl: "./telem-widget.component.html",
  styleUrls: ["./telem-widget.component.sass"],
})
export class TelemWidgetComponent implements AfterViewInit, OnDestroy {
  @Output() onSendTelemetrySensorPressure: EventEmitter<any> =
    new EventEmitter();
  @Output() onNavigateToDevice: EventEmitter<any> = new EventEmitter();
  @Output() onOpenMapSensor: EventEmitter<string[]> = new EventEmitter();
  @Output() onOpenAccidents: EventEmitter<Pressure> = new EventEmitter();
  @Input() widgetData: Pressure;

  @ViewChild("charRef") charRef: ElementRef;

  data: { score: number; gradingData: any[] };

  public env = environment;

  public page: Observable<TelemetryPage>;
  public page$: Subscription;
  public chart;

  constructor(
    public time: DateAppService,
    private store: Store<AppState>,
    private translateService: TranslateService,
  ) {}

  ngOnDestroy(): void {
    if (this.page$ !== undefined) {
      this.page$.unsubscribe();
    }
    if (this.chart) {
      this.chart.dispose();
    }
  }

  public styleStatusCauge(
    status: string | number,
    ranges: {
      min: string | number;
      max: string | number;
      pmin: string | number;
      pmax: string | number;
    },
  ): {
    "status-gauge-alarm": boolean;
    "status-gauge-normal": boolean;
    "status-gauge-warn": boolean;
  } {
    if (!ranges) {
      return {
        "status-gauge-alarm": false,
        "status-gauge-normal": true,
        "status-gauge-warn": false,
      };
    }
    if (+status < +ranges.min || +status > +ranges.max) {
      return {
        "status-gauge-alarm": true,
        "status-gauge-normal": false,
        "status-gauge-warn": false,
      };
    } else if (
      (+status >= +ranges.min && +status <= +ranges.pmin) ||
      (+status <= +ranges.max && +status >= +ranges.pmax)
    ) {
      return {
        "status-gauge-alarm": false,
        "status-gauge-normal": false,
        "status-gauge-warn": true,
      };
    } else {
      return {
        "status-gauge-alarm": false,
        "status-gauge-normal": true,
        "status-gauge-warn": false,
      };
    }
  }

  public colorStatusCauge(
    status: string | number,
    ranges: {
      min: string | number;
      max: string | number;
      pmin: string | number;
      pmax: string | number;
    },
  ): string {
    if (!ranges) {
      return "#52D327";
    }
    if (+status < +ranges.min || +status > +ranges.max) {
      return "#FF6666";
    } else if (
      (+status >= +ranges.min && +status <= +ranges.pmin) ||
      (+status <= +ranges.max && +status >= +ranges.pmax)
    ) {
      return "#f3b234";
    } else {
      return "#52D327";
    }
  }

  public setPulsateStyle(
    valueDevice: string | number,
    ranges: { min: string | number; max: string | number },
  ): boolean {
    if (!ranges) {
      return false;
    }
    if (+valueDevice < +ranges.min || +valueDevice > +ranges.max) {
      return true;
    } else {
      return false;
    }
  }

  public getScale(value: number): number {
    return (value * 30) / 100;
  }

  public sendTelemetrySensorPressure(e) {
    this.onSendTelemetrySensorPressure.emit(e);
  }

  public toLink(e) {
    this.onNavigateToDevice.emit(e);
  }

  public openMap(coor: string[]) {
    this.onOpenMapSensor.emit(coor);
  }

  public openAccidents(accidents: Pressure) {
    this.onOpenAccidents.emit(accidents);
  }

  public customizeTooltip(arg: any) {
    return {
      text: arg.valueText + " кПа",
    };
  }

  ngAfterViewInit(): void {
    // this.page = this.store.pipe(map((state: AppState) => {
    //   return state.telemPage;
    // }));

    this.page$ = this.page.subscribe((data) => {
      let title = "";
      this.store
        .pipe(map((state: AppState) => state?.["telemPage"]))
        .subscribe((e) => (title = e.dimensionPressure))
        .unsubscribe();

      title =
        title === "kpa"
          ? this.translateService.instant("page.telem.kpa")
          : this.translateService.instant("page.telem.kgs.cm2");

      this.createChart(
        title,
        this.widgetData.currentVal,
        1000,
        am4core.ease.cubicOut,
      );
    });
  }

  createChart(titleInput: string, dataInput, duratation, cubicOut) {
    const chartMin =
      this.widgetData.currentVal < this.widgetData.thMin
        ? this.widgetData.currentVal - this.widgetData.currentVal * 1.1 - 1
        : this.widgetData.thMin - this.widgetData.thMin / 5 - 1;
    const chartMax =
      this.widgetData.currentVal > this.widgetData.thMax
        ? this.widgetData.currentVal + this.widgetData.currentVal * 1.1 + 1
        : this.widgetData.thMax + this.widgetData.thMax / 5 + 1;

    this.data = { score: this.widgetData.currentVal, gradingData: [] };

    this.data.gradingData.push({
      title: titleInput,
      color: "#ee1f25",
      lowScore: chartMin - 30,
      highScore: this.widgetData.thMin,
    });

    this.data.gradingData.push({
      title: titleInput,
      color: "#f3b234",
      lowScore: this.widgetData.thMin,
      highScore: this.widgetData.preThMin,
    });

    this.data.gradingData.push({
      title: titleInput,
      color: "#65ee31",
      lowScore: this.widgetData.preThMin,
      highScore: this.widgetData.preThMax,
    });
    this.data.gradingData.push({
      title: titleInput,
      color: "#f3b234",
      lowScore: this.widgetData.preThMax,
      highScore: this.widgetData.thMax,
    });
    this.data.gradingData.push({
      title: titleInput,
      color: "#ee1f25",
      lowScore: this.widgetData.thMax,
      highScore: chartMax + 30,
    });

    const data = this.data;

    function lookUpGrade(lookupScore, grades) {
      // Only change code below this line
      for (let i = 0; i < grades.length; i++) {
        if (
          grades[i].lowScore <= lookupScore &&
          grades[i].highScore >= lookupScore
        ) {
          return grades[i];
        }
      }
      return null;
    }

    // create chart
    this.chart = am4core.create(
      this.charRef.nativeElement,
      am4charts.GaugeChart,
    );
    this.chart.hiddenState.properties.opacity = 0;
    this.chart.fontSize = 11;
    this.chart.innerRadius = am4core.percent(95);
    this.chart.resizable = true;

    /**
     * Normal axis
     */

    const axis = this.chart.xAxes.push(
      new am4charts.ValueAxis<am4charts.AxisRendererCircular>(),
    );
    axis.min = chartMin;
    axis.max = chartMax;
    axis.strictMinMax = true;
    axis.renderer.radius = am4core.percent(80);
    axis.renderer.inside = true;
    axis.renderer.line.strokeOpacity = 0.1;
    axis.renderer.ticks.template.disabled = false;
    axis.renderer.ticks.template.strokeOpacity = 1;
    axis.renderer.ticks.template.strokeWidth = 0.1;
    axis.renderer.ticks.template.length = 5;
    axis.renderer.grid.template.disabled = true;
    axis.renderer.labels.template.radius = am4core.percent(15);
    axis.renderer.labels.template.fontSize = "10px";

    /**
     * Axis for ranges
     */

    const axis2 = this.chart.xAxes.push(
      new am4charts.ValueAxis<am4charts.AxisRendererCircular>(),
    );
    axis2.min = chartMin;
    axis2.max = chartMax;
    axis2.strictMinMax = true;
    axis2.renderer.labels.template.disabled = true;
    axis2.renderer.ticks.template.disabled = true;
    axis2.renderer.grid.template.disabled = false;
    axis2.renderer.grid.template.opacity = 0.5;
    axis2.renderer.labels.template.bent = true;
    axis2.renderer.labels.template.fill = am4core.color("#000");
    axis2.renderer.labels.template.fontWeight = "bold";
    axis2.renderer.labels.template.fillOpacity = 0.3;

    for (const grading of data.gradingData) {
      const range = axis2.axisRanges.create();
      range.axisFill.fill = am4core.color(grading.color);
      range.axisFill.fillOpacity = 0.8;
      range.axisFill.zIndex = -1;
      range.value = grading.lowScore > chartMin ? grading.lowScore : chartMin;
      range.endValue =
        grading.highScore < chartMax ? grading.highScore : chartMax;
      range.grid.strokeOpacity = 0;
      // range.stroke = am4core.color(grading.color).lighten(-0.1);
      range.label.inside = true;
      // range.label.text = grading.title.toUpperCase();
      range.label.inside = true;
      range.label.location = 0.5;
      range.label.inside = true;
      // range.label.radius = am4core.percent(10);
      range.label.paddingBottom = -5; // ~half font size
      range.label.fontSize = "10px";
    }

    const matchingGrade = lookUpGrade(data.score, data.gradingData);
    /**
     * Label 1
     */

    const label = this.chart.radarContainer.createChild(am4core.Label);
    label.isMeasured = false;
    label.fontSize = "20px";
    label.x = am4core.percent(50);
    label.paddingBottom = 15;
    label.horizontalCenter = "middle";
    label.verticalCenter = "bottom";
    // label.dataItem = data;
    label.text = data.score.toFixed(2);
    label.text = "{score}";
    label.fill = am4core.color(matchingGrade.color);

    /**
     * Label 2
     */

    const label2 = this.chart.radarContainer.createChild(am4core.Label);
    label2.isMeasured = false;
    label2.fontSize = "14px";
    label2.horizontalCenter = "middle";
    label2.verticalCenter = "bottom";
    label2.text = matchingGrade.title.toUpperCase();
    label2.fill = am4core.color(matchingGrade.color);

    /**
     * Hand
     */

    const hand = this.chart.hands.push(new am4charts.ClockHand());
    hand.axis = axis2;
    hand.innerRadius = am4core.percent(55);
    hand.startWidth = 8;
    hand.pin.disabled = true;
    hand.value = data.score;
    hand.fill = am4core.color("#444");
    hand.stroke = am4core.color("#000");

    hand.events.on("positionchanged", function () {
      label.text = axis2.positionToValue(hand.currentPosition).toFixed(2);
      const value2 = axis.positionToValue(hand.currentPosition);
      const matchingGrade = lookUpGrade(
        axis.positionToValue(hand.currentPosition),
        data,
      );

      if (matchingGrade !== null) {
        label2.text = matchingGrade.title;
        label2.fill = am4core.color(matchingGrade.color);
        label2.stroke = am4core.color(matchingGrade.color);
        label.fill = am4core.color(matchingGrade.color);
      }
    });

    hand.showValue(dataInput, 1000, cubicOut);
  }
}

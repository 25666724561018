import { Action } from '@ngrx/store';
import { Widget} from '@interfaces';

export enum MainPageActionTypes {
  SetOneWidget= '[Main.Page] Set One Widget',
  SetDatePage = '[Main.Page] Set Date Page',
  SetMonthAnalizBlockPage = '[Main.Page] Set Month Analiz Block Page',
  SetFromDateAnalizBlockPage = '[Main.Page] Set From Date Analiz Block Page',
  SetToDateAnalizBlockPage = '[Main.Page] Set To Date Analiz Block Page',
  LoadWidgets = '[Main.Page] Load Widgets',
  LoadCharts = '[Main.Page] Load Charts',
  LoadAnalizCharts = '[Main.Page] Load Analiz Charts',
  LoadNodes= '[Main.Page] Load Nodes',
  SetNodeSelected = '[Main.Page] Set Node Selected',
  SetDownloadTitleNode = '[Main.Page] Set Download Title Node'
}

export class SetOneWidgetPageAction implements Action {
  readonly type = MainPageActionTypes.SetOneWidget;

  constructor(public payload: Widget) {}
}

export class SetDatePageAction implements Action {
  readonly type = MainPageActionTypes.SetDatePage;

  constructor(public payload: Date) {}
}

export class SetFromDateAnalizBlockPageAction implements Action {
  readonly type = MainPageActionTypes.SetFromDateAnalizBlockPage;

  constructor(public payload: Date) {}
}

export class SetToDateAnalizBlockPageAction implements Action {
  readonly type = MainPageActionTypes.SetToDateAnalizBlockPage;

  constructor(public payload: Date) {}
}

export class SetMonthAnalizBlockPageAction implements Action {
  readonly type = MainPageActionTypes.SetMonthAnalizBlockPage;

  constructor(public payload: Date) {}
}

export class LoadWidgetsAction implements Action {
  readonly type = MainPageActionTypes.LoadWidgets;

  constructor(public payload: Widget[]) {}
}

export class LoadChartsAction implements Action {
  readonly type = MainPageActionTypes.LoadCharts;

  constructor(public payload: any[]) {}
}

export class LoadAnalizChartsAction implements Action {
  readonly type = MainPageActionTypes.LoadAnalizCharts;

  constructor(public payload: any[]) {}
}

export class LoadNodesAction implements Action {
  readonly type = MainPageActionTypes.LoadNodes;

  constructor(public payload: any[]) {}
}

export class SetNodeSelectedAction implements Action {
  readonly type = MainPageActionTypes.SetNodeSelected;

  constructor(public payload: any) {}
}

export class SetDownloadTitleNodeAction implements Action {
  readonly type = MainPageActionTypes.SetDownloadTitleNode;

  constructor(public payload: string) {}
}


export type MainPageActions = SetDatePageAction | LoadWidgetsAction | LoadChartsAction
  | SetMonthAnalizBlockPageAction | SetFromDateAnalizBlockPageAction | SetOneWidgetPageAction
  | SetToDateAnalizBlockPageAction | LoadAnalizChartsAction | LoadNodesAction
  | SetNodeSelectedAction | SetDownloadTitleNodeAction;

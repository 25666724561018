import { Action } from "@ngrx/store";
import { Message } from "@interfaces";

export namespace MESSAGE_ACTION {
  export const IS_VISIBLE_MESSAGE = "IS_VISIBLE_MESSAGE";
  export const IS_HIDDEN_MESSAGE = "IS_HIDDEN_MESSAGE";
}

export class MessageVisibleAction implements Action {
  readonly type = MESSAGE_ACTION.IS_VISIBLE_MESSAGE;

  constructor(public payload: Message) {}
}
export class MessageHiddenAction implements Action {
  readonly type = MESSAGE_ACTION.IS_HIDDEN_MESSAGE;

  constructor(public payload: number) {}
}

export type MessageActions = MessageVisibleAction | MessageHiddenAction;

import { RxStomp } from "@stomp/rx-stomp";
import { Message, StompConfig } from "@stomp/stompjs";
import { Observable } from "rxjs";

export class WebSocketMessage {
  private readonly messagesTelem: Observable<Message>;
  private stompService: RxStomp;

  constructor(
    socketUrl: string,
    public userLogin: string,
  ) {
    // Create Stomp Configuration
    const stompConfig: StompConfig = {
      brokerURL: socketUrl,
      heartbeatIncoming: 0,
      heartbeatOutgoing: 20000,
      reconnectDelay: 5000,
    };

    // Create Stomp Service
    this.stompService = new RxStomp();
    this.stompService.configure(stompConfig);
    this.stompService.activate();

    // Connect to a Stream
    this.messagesTelem = this.stompService.watch({
      destination: "/user/industry-sensor/one",
    });
  }

  public stopPublishMessagingIntoTelem() {
    this.stompService.publish({
      destination: "/app/usermessage/greg",
      body: JSON.stringify({
        userid: this.userLogin,
        talkto: "stopTelem",
      }),
    });
  }

  public publishMessagingIntoTelem() {
    this.stompService.publish({
      destination: "/app/usermessage/greg",
      body: JSON.stringify({
        userid: this.userLogin,
        talkto: "startTelem",
      }),
    });
  }

  public startPublishMessagingIntoTelem() {
    this.stompService.connectionState$.subscribe((e) => {
      this.publishMessagingIntoTelem();
    });
  }

  public streamTelem(): Observable<Message> {
    return this.messagesTelem;
  }

  public closeConnection() {
    this.stompService.deactivate().then();
  }
}

import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
} from "@angular/common/http";
import { Observable } from "rxjs/internal/Observable";
import { tap } from "rxjs/operators";
import { AppState } from "@state";
import { Store } from "@ngrx/store";
import { MessageService } from "../message.service";
import { ToasterService } from "../toaster.service";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class AllResponces implements HttpInterceptor {
  constructor(
    private store: Store<AppState>,
    private toaster: ToasterService,
    private translateService: TranslateService,
    private message: MessageService,
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap((resp) => {
        if (
          resp instanceof HttpResponse &&
          resp.status === 200 &&
          (request.method === "POST" || request.method === "PUT") &&
          !request.body.login
        ) {
          // console.log(request);
          // console.log(resp);
          // this.message.newSuccses(resp.status + ' Успіх');
          this.toaster.show(
            "success",
            "",
            this.translateService.instant("success.post"),
          );
          if (resp.url.indexOf("sensors-pressure-thresholds") !== -1) {
            // notify(resp.status + ' Успіх', 'success', 2500);
            this.toaster.show(
              "success",
              "",
              this.translateService.instant("success.post"),
            );
          } else if (
            resp.url.indexOf("industry-updDevInf-water") !== -1 &&
            request.method === "PUT"
          ) {
            this.toaster.show(
              "success",
              "",
              "Устройству " +
                resp.body.serNum +
                " установлен статус " +
                this.translateService.instant("status" + resp.body.status),
            );
          }
        }
      }),
    );
  }
}

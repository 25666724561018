export class VegaTmUtil {
  static getCaptionVegaTmBySerNum(serNum?: any): string {
    const serNumTmp = Number(serNum);
    const stringValue = "vega_tm";
    if (!serNum) return stringValue;
    let typeModem = 1;

    if (serNumTmp >= 0 && serNumTmp <= 100000) typeModem = 1;
    else if (serNumTmp >= 100001 && serNumTmp <= 200000) typeModem = 2;
    else if (serNumTmp >= 200001 && serNumTmp <= 300000) typeModem = 3;
    else if (serNumTmp >= 700001 && serNumTmp <= 800000) typeModem = 4;
    else if (serNumTmp >= 400001 && serNumTmp <= 500000) typeModem = 5;
    else if (serNumTmp >= 800001 && serNumTmp <= 900000) typeModem = 6;
    else if (serNumTmp >= 600001 && serNumTmp <= 700000) typeModem = 0;

    return stringValue + "." + typeModem;
  }
}

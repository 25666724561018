export enum NavigationPoints {
  main = "main",
  devices = "main/devices",
  routes = "main/routes",
  oneRoute = "main/routes/one-route",
  accidents = "main/accidents",
  modems = "main/modems",
  oneModem = "main/modems/one-modem",
  logs = "main/logs",
  logsDevices = "main/logs/devices",
  logsModems = "main/logs/modems",
  logsSensors = "main/logs/sensors",
  sensors = "main/sensors",
  oneSensor = "main/sensors/one-sensor",
  denied = "main/denied",
  login = "login",
}

import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { TokensService } from "../tokens.service";
import { ReqestCloneJwt } from "./reqest.clone.jwt";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(
    private tokens: TokensService,
    private clone: ReqestCloneJwt,
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (
      this.tokens.checkTokens() &&
      request.url.indexOf("refreshToken") === -1
    ) {
      request = this.clone.addTokenToRequest(request);
    }
    return next.handle(request);
  }
}

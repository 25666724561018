import { environment } from "@env";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";
import { TokensService } from "./tokens.service";
import { LoginUser, Tokens } from "@interfaces";
import { map, share, take } from "rxjs/operators";
import { DetectedHostService } from "./detected.host.service";
import { AppState } from "@state";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { LocalStorService } from "./localStor.service";
import { WebsocketService } from "./websocket/websocket.service";
import { TranslateService } from "@ngx-translate/core";
import { IsEnterAppLoadingAction } from "app/redux/actions/loading.actions";
import { JwtHelperService } from "@auth0/angular-jwt";
import { MessageVisibleAction } from "../../redux/actions/message.actions";
import { NavigationPoints } from "@enum";
@Injectable({
  providedIn: "root",
})
export class AuthorizationService {
  constructor(
    private http: HttpClient,
    private host: DetectedHostService,
    private router: Router,
    private localStor: LocalStorService,
    private translateService: TranslateService,
    private websocketService: WebsocketService,
    private store: Store<AppState>,

    private tokens: TokensService,
  ) {}

  public login(data: LoginUser): void {
    this.store.dispatch(new IsEnterAppLoadingAction(true));
    this.http
      .post("//" + this.host.getAuthHost() + "/auth/login", data)
      .subscribe(
        (tokens: Tokens) => {
          const jwtHelper = new JwtHelperService();
          const date =
            jwtHelper.getTokenExpirationDate(tokens.access) ?? new Date();
          const delay_millis = date.valueOf() - new Date().valueOf();
          window.setTimeout(() => {
            this.router.navigate([NavigationPoints.login]);
          }, delay_millis);

          this.tokens.setTokens(tokens);
          this.getCredentional();
          this.store.dispatch(new IsEnterAppLoadingAction(false));
        },
        (error) => {
          if (error.status === 404 || error.status === 403) {
            this.store.dispatch(
              new MessageVisibleAction({
                type: "error",
                message: this.translateService.instant(
                  "err.not.code.loginpass",
                ),
              }),
            );
          }
          if (error.status === 0 || error.status === 500) {
            this.store.dispatch(
              new MessageVisibleAction({
                type: "error",
                message: this.translateService.instant(
                  "err.not.code.server.not.found",
                ),
              }),
            );
          }
          this.store.dispatch(new IsEnterAppLoadingAction(false));
        },
      );
    // this.store.dispatch(new SetUserAction(data.login));
    this.localStor.saveUserName(data.login);
    // this.router.navigate(['/main']);
  }

  public getCredentional(): void {
    if (!this.tokens.checkTokens()) return;

    this.http
      .get("//" + this.host.getHost() + "/api/v1/industry-user-pages-water")
      .subscribe(
        (pages: { pages: boolean; edit: boolean }) => {
          // TODO LEGACY BLOCK START
          // this.store.dispatch(new GetPermissionUserAction(pages.pages.toString()));
          // this.store.dispatch(new SetEditUserAction(pages.edit));
          // this.store.dispatch(new LoadedUserInfoAction(true))
          // TODO LEGACY BLOCK END

          if (this.router.url === "/login") {
            this.router.navigate([NavigationPoints.devices]);
          }
        },
        (error) => {
          // TODO LEGACY BLOCK START
          // this.store.dispatch(new GetPermissionUserAction('true'))
          // this.store.dispatch(new LoadedUserInfoAction(true))
          // if(this.router.url === '/login' ) {
          //   this.router.navigate([this.localStor.getStartPage()]); // TODO костыль блять для сранного говна с интерфейсом давления, хотя сука милион раз говорилось что сука рест должен быть бля одинаковый нахуй, но как в очко... только эхо
          // }
          // TODO LEGACY BLOCK END
        },
      );
    // this.store.dispatch(new GetPermissionUserMenuAction(environment.menu))
  }

  public setCashePermission(cashe: boolean): void {
    sessionStorage.setItem("cashePerms", cashe.toString());
  }

  public getCashePermission(): any {
    return sessionStorage.getItem("cashePerms") === "true" ? true : false;
  }

  public clearCashePermission(): void {
    sessionStorage.removeItem("cashePerms");
  }

  public logout(): any {
    this.tokens.clearTokens();
    // this.store.dispatch(new GetPermissionUserAction(null));
    this.router.navigate([NavigationPoints.login]);
  }

  public refresh(): Observable<boolean> {
    const headers: HttpHeaders = new HttpHeaders({
      Authorization: "Bearer " + this.tokens.getTokenRefresh(),
      "Content-Type": "application/x-www-form-urlencoded",
    });
    return this.http
      .get("//" + this.host.getAuthHost() + "/auth/refreshToken", {
        headers: headers,
      })
      .pipe(
        share(),
        map((tokens: Tokens) => {
          if (tokens.refresh && tokens.access) {
            this.tokens.setTokens(tokens);
            return true;
          }
        }),
      );
  }
}

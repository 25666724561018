import {Action} from '@ngrx/store';
import {UserControl} from '../../../shared/interfaces/control';

export enum ControlActionTypes {
  SetUsers = '[SetUsers] Set Users',
  AddUser = '[AddUser] Add User',
  DellUser = '[DellUser] Dell User',
  AddGroupDevices = '[AddGroupDevices] Add Grou pDevices'
}

export class SetUsersControlActions implements Action {
  readonly type = ControlActionTypes.SetUsers;
  constructor(public payload: UserControl[]) {}
}
export class SetUsersGroupDeviceActions implements Action {
  readonly type = ControlActionTypes.AddGroupDevices;
  constructor(public payload: string[]) {}
}

export class AddUserControlActions implements Action {
  readonly type = ControlActionTypes.AddUser;
  constructor(public payload: UserControl) {}
}

export class DellUserControlActions implements Action {
  readonly type = ControlActionTypes.DellUser;
  constructor(public payload: UserControl) {}
}
export type ControlActions = SetUsersControlActions | AddUserControlActions | DellUserControlActions | SetUsersGroupDeviceActions;

import { Action } from '@ngrx/store';
import { TableAllDevice, Widget } from '@interfaces';
import { VentsArchive } from 'app/shared/interfaces/vents-page';

export enum VentsActionTypes {
  Set_Filter_Table = '[Vents] Set_Filter_Table',
  LoadDevicesVents = '[Vents] Load Devicess' ,
  LoadVents = '[Vents] Load Vents',
  LoadWidgetsVents = '[Vents] Load Widgets Vents',
  SetCurrentDeviceVent = '[Vents] Set Current Device Vent',
  SetCurrentDeviceVentReceipt = '[Vents] Set Current Device Vent Receipt',
  SetCurrentDeviceVentArchive = '[Vents] Set Current Device Vent Archive',
  UPDATE_ONE_DEVICE_TABLE_OBJECT = '[Vents] UPDATE_ONE_DEVICE_TABLE_OBJECT'
}

export class SetFilterTableActions implements Action {
  readonly type = VentsActionTypes.Set_Filter_Table;
  constructor(public payload: string) {}
}

export class LoadVents implements Action {
  readonly type = VentsActionTypes.LoadVents;
  constructor(public payload: any[]) {}
}
export class UpdateOneDeviceTableObjectAction implements Action {
  readonly type = VentsActionTypes.UPDATE_ONE_DEVICE_TABLE_OBJECT;
  constructor(public payload: any) {}
}

export class LoadDevicessVents implements Action {
  readonly type = VentsActionTypes.LoadDevicesVents;

  constructor(public payload: TableAllDevice) {}
}

export class LoadWidgetsVentsAction implements Action {
  readonly type = VentsActionTypes.LoadWidgetsVents;

  constructor(public payload: Widget[]) {}
}

export class SetCurrentDeviceVent implements Action {
  readonly type = VentsActionTypes.SetCurrentDeviceVent;

  constructor(public payload: any) {}
}

export class SetCurrentDeviceVentArchive implements Action {
  readonly type = VentsActionTypes.SetCurrentDeviceVentArchive;

  constructor(public payload: VentsArchive[]) {}
}

export class SetCurrentDeviceVentReceipt implements Action {
  readonly type = VentsActionTypes.SetCurrentDeviceVentReceipt;

  constructor(public payload: any) {}
}


export type VentsActions = LoadVents | SetCurrentDeviceVent | SetCurrentDeviceVentReceipt | LoadWidgetsVentsAction | SetFilterTableActions |
  SetCurrentDeviceVentArchive | LoadDevicessVents | UpdateOneDeviceTableObjectAction;

import { LoadingActions, LoadingActionTypes } from "../actions/loading.actions";
import { Loading } from "@interfaces";

export const initialStateLoading: Loading = {
  isWidgetsLoading: false,
  loading: false,
  isEnterAppLoading: false,
};

export function reducerLoading(
  state = initialStateLoading,
  action: LoadingActions,
): { loading: boolean; isWidgetsLoading: boolean } {
  switch (action.type) {
    case LoadingActionTypes.isLoadings:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
}

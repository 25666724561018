import { Injectable } from "@angular/core";
import { TableAllDevice } from "@interfaces";

@Injectable({
  providedIn: "root",
})
export class HelperMutationPressureService {
  constructor() {}

  public daily(arr: TableAllDevice) {
    if (arr.pressure.length === 0) return arr;

    const myArr = new Map();

    arr.pressure.forEach((obj) => {
      myArr.set(obj.date.substring(0, obj.date.indexOf("T")), obj);
    });

    arr.data.forEach((item) => {
      if (item.date && myArr.get(item.date)) {
        if (
          item.date ===
          myArr
            .get(item.date)
            .date.substring(0, myArr.get(item.date).date.indexOf("T"))
        ) {
          item.currentVal = myArr.get(item.date).currentVal;
        }
      }
    });

    return arr;
  }
}

import { Injectable } from "@angular/core";
import { AppState } from "@state";
import { Store } from "@ngrx/store";
import {
  MessageHiddenAction,
  MessageVisibleAction,
} from "../../redux/actions/message.actions";

@Injectable({
  providedIn: "root",
})
export class MessageService {
  constructor(private store: Store<AppState>) {}

  public newError(m: string) {
    const id = this.getRandomIntID(1, 999999);
    this.store.dispatch(
      new MessageVisibleAction({ type: "error", message: m, id: id }),
    );
    this.delMessage(id);
  }

  public newSuccses(m: string) {
    const id = this.getRandomIntID(1, 999999);
    this.store.dispatch(
      new MessageVisibleAction({ type: "success", message: m, id: id }),
    );
    this.delMessage(id);
  }

  private delMessage(id: number) {
    this.timerV(id);
  }

  private getRandomIntID(min, max) {
    return Math.floor(Math.random() * (max - min)) + min;
  }

  private timerV(id: number) {
    setTimeout(() => {
      this.store.dispatch(new MessageHiddenAction(id));
    }, 3000);
  }
}

import { messageReducer } from "./message.reducer";
import { reducerGlobalApp } from "./global.reducer";
import { reducerLoading } from "./is-loading.reducer";

// TODO LEGACY BLOCK! WE NEED TO MOVE THAT TO NEW ROOT REDUCER

export const ReducerApp = {
  globalApp: reducerGlobalApp,
  messageBlock: messageReducer,
  isLoading: reducerLoading,
};

// export const ReducerApp = {
//   globalApp: reducerGlobalApp,
//   messageBlock: messageReducer,
//   mainPage: reducerMainPage,
//   devicePage: reducerAllDevice,
//   telemPage: reducerTelemetry,
//   ventsPage: reducerVents,
//   controlPage: reducerControl,
//   isLoading: reducerLoading,
//   accidentsPage: reducerAccidents,
//   volumesPage: reducerVolumes,
//   volumesTelemPage: reducerVolumesTelem,
//   logDevicesPage: reducerLogDevices,
//   logTelemPage: reducerLogTelem
// };

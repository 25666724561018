import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class IsLoadingService {
  private isLoadServiceSet = new Set<string>();

  public isLoading(nameService: string): boolean {
    return this.isLoadServiceSet.has(nameService);
  }

  public setLoading(nameService: string): void {
    this.isLoadServiceSet.add(nameService);
  }
  public dellLoading(nameService: string): void {
    this.isLoadServiceSet.delete(nameService);
  }
}

import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { AppState } from "@state";
import { Store } from "@ngrx/store";
import { DetectedHostService } from "./detected.host.service";
import { Widget } from "../../shared/interfaces/widget";
import { Chart } from "../../shared/interfaces/charts";

import { filter, map } from "rxjs/operators";
import { DateAppService } from "./date-app.service";
import {
  TableAllDevice,
  Pressure,
  ValidationDataPressure,
  Thresholds,
  InfoActiveThreshold,
  CurrentIperlReceipt,
  CurrentDeviceStatus,
  DeviceForStatus,
  Accidents,
  Loggers,
  TableDataHead,
  CurrentDevice,
} from "@interfaces";
import { Observable } from "rxjs";
import {
  AnalizCharts,
  ListNode,
} from "../../shared/interfaces/analiz-chart-block";

import {
  VentsArchive,
  VentsReceipts,
} from "../../shared/interfaces/vents-page";
import {
  IsLoadings,
  IsWidgetsLoading,
} from "../../redux/actions/loading.actions";
import { ToasterService } from "./toaster.service";
import { TranslateService } from "@ngx-translate/core";
import { HelperMutationPressureService } from "./helper-mutation-pressure.service";
import { IsLoadingService } from "./is-loading.service";
import { VegaTmUtil } from "@utils";
import {
  LoadAnalizChartsAction,
  LoadChartsAction,
  LoadNodesAction,
  LoadWidgetsAction,
  SetDownloadTitleNodeAction,
  SetNodeSelectedAction,
} from "../../legacy/redux/actions/main.page.actions";
import {
  LoadDevicessVents,
  LoadWidgetsVentsAction,
  SetCurrentDeviceVentArchive,
  SetCurrentDeviceVentReceipt,
} from "../../legacy/redux/actions/vents.actions";
import { SetVolumesActions } from "../../legacy/redux/actions/volumes.action";
import { SetVolumesTelemActions } from "../../legacy/redux/actions/volumes.telem.action";
import { SetAccidentsActions } from "../../legacy/redux/actions/accidents.action";
import {
  LoadAllTablePressureAction,
  LoadDataAllDataTablePressureAction,
  LoadDataCurrentTablePressureAction,
  LoadInfoUpdateThresholdsForOneDevice,
} from "../../legacy/redux/actions/telemetry.actions";
import {
  SetUsersControlActions,
  SetUsersGroupDeviceActions,
} from "../../legacy/redux/actions/control.actions";
import { SetLogDevicesActions } from "../../legacy/redux/actions/log-devices.action";
import { SetLogTelemActions } from "../../legacy/redux/actions/log-telem.actions";
import {
  LoadCurrentDeviceAction,
  LoadCurrentIperlReceiptAction,
  LoadCurrentStatusDeviceAction,
  LoadDevicesAction,
  LoadWidgetsDevicessAction,
  SetActiveStatusAction,
  SetCurrentStatusDeviceAction,
} from "../../legacy/redux/actions";

@Injectable({
  providedIn: "root",
})
export class DataService {
  [x: string]: any;
  tempListNode: ListNode[] = [
    {
      id: 1,
      name: "КП Радужный-1",
    },
    {
      id: 2,
      name: "КП Радужный-2",
    },
    {
      id: 3,
      name: "КП Радужный-3",
    },
    {
      id: 4,
      name: "КП Радужный-4",
    },
    {
      id: 5,
      name: "КП Радужный-5",
    },
    {
      id: 6,
      name: "КП Радужный-6",
    },
    {
      id: 7,
      name: "КП Радужный-7",
    },
  ];
  tempAnalizCharts: AnalizCharts[] = [
    {
      id: 1,
      ayis: "КП Радужный-1",
      pressure: 60,
      volume: 11967,
      elec: 502,
      over: 0,
      nomination: 79,
    },
    {
      id: 1,
      ayis: "КП Радужный-2",
      pressure: 80,
      volume: 12067,
      elec: 702,
      over: 11,
      nomination: 0,
    },
    {
      id: 1,
      ayis: "КП Радужный-3",
      pressure: 60,
      volume: 11887,
      elec: 667,
      over: 0,
      nomination: 4,
    },
    {
      id: 1,
      ayis: "КП Радужный-4",
      pressure: 86,
      volume: 11967,
      elec: 442,
      over: 0,
      nomination: 179,
    },
    {
      id: 1,
      ayis: "КП Радужный-5",
      pressure: 56,
      volume: 1467,
      elec: 301,
      over: 0,
      nomination: 282,
    },
    {
      id: 1,
      ayis: "КП Радужный-6",
      pressure: 169,
      volume: 12967,
      elec: 902,
      over: 404,
      nomination: 0,
    },
    {
      id: 1,
      ayis: "КП Радужный-7",
      pressure: 77,
      volume: 1947,
      elec: 498,
      over: 0,
      nomination: 88,
    },
  ];
  tempAnalizChartNode: AnalizCharts[] = [
    {
      id: 1,
      ayis: "2019-07-01",
      pressure: 60,
      volume: 1967,
      elec: 180,
      over: 0,
      nomination: 20,
    },
    {
      id: 1,
      ayis: "2019-07-02",
      pressure: 80,
      volume: 2067,
      elec: 112,
      over: 0,
      nomination: 88,
    },
    {
      id: 1,
      ayis: "2019-07-03",
      pressure: 160,
      volume: 2887,
      elec: 200,
      over: 67,
      nomination: 0,
    },
    {
      id: 1,
      ayis: "2019-07-04",
      pressure: 86,
      volume: 1967,
      elec: 142,
      over: 0,
      nomination: 68,
    },
    {
      id: 1,
      ayis: "2019-07-05",
      pressure: 56,
      volume: 467,
      elec: 201,
      over: 1,
      nomination: 0,
    },
    {
      id: 1,
      ayis: "2019-07-06",
      pressure: 81,
      volume: 997,
      elec: 202,
      over: 2,
      nomination: 0,
    },
    {
      id: 1,
      ayis: "2019-07-07",
      pressure: 77,
      volume: 947,
      elec: 198,
      over: 0,
      nomination: 2,
    },
    {
      id: 1,
      ayis: "2019-07-08",
      pressure: 77,
      volume: 947,
      elec: 98,
      over: 0,
      nomination: 102,
    },
    {
      id: 1,
      ayis: "2019-07-09",
      pressure: 77,
      volume: 947,
      elec: 108,
      over: 0,
      nomination: 92,
    },
    {
      id: 1,
      ayis: "2019-07-10",
      pressure: 77,
      volume: 947,
      elec: 128,
      over: 0,
      nomination: 72,
    },
    {
      id: 1,
      ayis: "2019-07-11",
      pressure: 77,
      volume: 947,
      elec: 155,
      over: 0,
      nomination: 45,
    },
    {
      id: 1,
      ayis: "2019-07-12",
      pressure: 77,
      volume: 947,
      elec: 163,
      over: 0,
      nomination: 37,
    },
    {
      id: 1,
      ayis: "2019-07-13",
      pressure: 87,
      volume: 1047,
      elec: 200,
      over: 18,
      nomination: 0,
    },
    {
      id: 1,
      ayis: "2019-07-14",
      pressure: 117,
      volume: 1347,
      elec: 200,
      over: 98,
      nomination: 0,
    },
    {
      id: 1,
      ayis: "2019-07-15",
      pressure: 177,
      volume: 1947,
      elec: 200,
      over: 198,
      nomination: 0,
    },
  ];

  constructor(
    private store: Store<AppState>,
    private hmps: HelperMutationPressureService,
    private http: HttpClient,
    private translateService: TranslateService,
    private toaster: ToasterService,
    private isLoadingService: IsLoadingService,
    private host: DetectedHostService,
    private myTime: DateAppService,
  ) {}

  selectPageForRequest(): void {
    let page: string;
    this.store
      .pipe(map((state: AppState) => state.globalApp.activePage))
      .subscribe((active) => (page = active))
      .unsubscribe();
    switch (page) {
      case "main/sensors":
        {
          this.loadTelemetrySensorsPressure();
        }
        break;
    }
  }

  getMainpageChartData() {
    if (this.isLoadingService.isLoading("getMainpageChartData")) return null;

    this.isLoadingService.setLoading("getMainpageChartData");
    let date: string;
    this.store
      .pipe(map((data) => data?.["mainPage"].date))
      .subscribe((d) => (date = this.myTime.dateToLocalString(d)))
      .unsubscribe();
    this.store.dispatch(new IsLoadings(true));
    const params = new HttpParams().set("date", date);
    this.http
      .get(
        "//" + this.host.getHost() + "/api/v1/industry-pieChartsData-water",
        { params },
      )
      .subscribe(
        (data: Chart[]) => {
          this.isLoadingService.dellLoading("getMainpageChartData"); //////////////////////////////////////////////////
          this.store.dispatch(new IsLoadings(false)); //закомментирвоала я см.строку 456
          if (data as Chart[]) {
            this.store.dispatch(new LoadChartsAction(data));
          }
        },
        (error) => {
          this.isLoadingService.dellLoading("getMainpageChartData");
          this.store.dispatch(new IsLoadings(false));
        },
      );
  }
  getMainPageWidgets() {
    if (this.isLoadingService.isLoading("getMainPageWidgets")) return null;

    this.isLoadingService.setLoading("getMainPageWidgets");
    const date: string = new Date().toString();
    this.store.dispatch(new IsWidgetsLoading(true));
    // this.store.pipe(map(data => data.mainPage.date))
    //   .subscribe(d => date = this.myTime.dateToLocalString(d)).unsubscribe();
    const params = new HttpParams().set("date", date);
    this.http
      .get("//" + this.host.getHost() + "/api/v1/industry-widgets-water", {
        params,
      })
      .subscribe(
        (data: Widget[]) => {
          localStorage.setItem("objectWidg", JSON.stringify(data));

          this.isLoadingService.dellLoading("getMainPageWidgets");
          this.store.dispatch(new IsWidgetsLoading(false));
          if (data as Widget[]) {
            this.store.dispatch(new LoadWidgetsAction(data));
            data.forEach((widget) => {
              if (widget.titleWidget === "widget.water.vent") {
                this.store.dispatch(
                  new LoadWidgetsVentsAction([
                    {
                      titleWidget: widget[0].titleWidget,
                      data: +widget[0].data,
                      colorWidget: "#5cb85c",
                      iconWidget: "dx-icon-todo",
                    },
                    {
                      titleWidget: widget[1].titleWidget,
                      data: +widget[1].data,
                      colorWidget: "#f26e74",
                      iconWidget: "dx-icon-close",
                    },
                    {
                      titleWidget: widget[2].titleWidget,
                      data: +widget[2].data,
                      colorWidget: "#9eb8e8",
                      iconWidget: "dx-icon-globe",
                    },
                  ]),
                );
              }
            });
          }
        },
        (error) => {
          this.isLoadingService.dellLoading("getMainPageWidgets");
          this.store.dispatch(new IsWidgetsLoading(false));
        },
      );
  }

  getMainPageAnalizChartsClosedDay() {
    const date: { from: Date; to: Date } = { from: new Date(), to: new Date() };
    const params = new HttpParams()
      .set(
        "from",
        this.myTime.dateToLocalString(
          new Date(date.from.setDate(date.from.getDate() - 1)),
        ),
      )
      .set("to", this.myTime.dateToLocalString(date.to));
    this.store.dispatch(new LoadAnalizChartsAction([]));
    this.http
      .get("//" + this.host.getHost() + "/api/v1/analiz-charts-water", {
        params,
      })
      .subscribe(
        (data: AnalizCharts[]) => {
          if (data as AnalizCharts[]) {
            this.store.dispatch(
              new LoadAnalizChartsAction(this.tempAnalizCharts),
            );
          }
        },
        (error) =>
          this.store.dispatch(
            new LoadAnalizChartsAction(this.tempAnalizCharts),
          ),
      );
  }

  getMainPageAnalizChartsMonth() {
    const date: Date = new Date();
    const params = new HttpParams().set(
      "month",
      this.myTime.dateToLocalString(date),
    );
    this.store.dispatch(new LoadAnalizChartsAction([]));
    this.http
      .get("//" + this.host.getHost() + "/api/v1/analiz-charts-water", {
        params,
      })
      .subscribe(
        (data: AnalizCharts[]) => {
          if (data as AnalizCharts[]) {
            this.store.dispatch(
              new LoadAnalizChartsAction(this.tempAnalizCharts),
            );
          }
        },
        (error) =>
          this.store.dispatch(
            new LoadAnalizChartsAction(this.tempAnalizCharts),
          ),
      );
  }

  getMainPageAnalizChartSelectedNode() {
    const date: { from: Date; to: Date } = { from: new Date(), to: new Date() };
    const node: ListNode = { id: null, name: null };
    const params = new HttpParams()
      .set("from", this.myTime.dateToLocalString(date.from))
      .set("to", this.myTime.dateToLocalString(date.to))
      .set("id", node.id.toString());
    this.store.dispatch(new LoadAnalizChartsAction([]));
    this.store.dispatch(new IsLoadings(true));
    this.http
      .get("//" + this.host.getHost() + "/api/v1/analiz-charts-water", {
        params,
      })
      .subscribe(
        (data: AnalizCharts[]) => {
          this.store.dispatch(new IsLoadings(false));
          if (data as AnalizCharts[]) {
            this.store.dispatch(
              new LoadAnalizChartsAction(this.tempAnalizChartNode),
            );
            this.store.dispatch(new SetDownloadTitleNodeAction(node.name));
          }
        },
        (error) => {
          this.store.dispatch(new IsLoadings(false));
          this.store.dispatch(
            new LoadAnalizChartsAction(this.tempAnalizChartNode),
          );
          this.store.dispatch(new SetDownloadTitleNodeAction(node.name));
        },
      );
  }

  getListNodeForAnalizChart() {
    // TODO del this.tempListNode & add real responce
    this.store.dispatch(new IsLoadings(true));
    this.http
      .get("//" + this.host.getHost() + "/api/v1/listNodes-water")
      .subscribe(
        (list: ListNode[]) => {
          this.store.dispatch(new IsLoadings(false));
          if (this.tempListNode as ListNode[]) {
            this.store.dispatch(new LoadNodesAction(this.tempListNode));
            if (this.tempListNode.length > 0) {
              this.store.dispatch(
                new SetNodeSelectedAction(this.tempListNode[0]),
              );
              this.getMainPageAnalizChartSelectedNode();
            }
          }
        },
        (error) => {
          this.store.dispatch(new IsLoadings(false));
          this.store.dispatch(new LoadNodesAction(this.tempListNode));
          if (this.tempListNode.length > 0) {
            this.store.dispatch(
              new SetNodeSelectedAction(this.tempListNode[0]),
            );
            this.getMainPageAnalizChartSelectedNode();
          }
        },
      );
  }

  getPressureAllDevicesPage(): Observable<TableAllDevice> {
    const date: string = this.myTime.dateToLocalString(new Date());
    const params: HttpParams = new HttpParams()
      .set("date", date)
      .set("filter", "all");
    return this.http.get<TableAllDevice>(
      "//" + this.host.getHost() + "/api/v1/industry-allDevDat-water",
      { params },
    );
  }

  getAllDevices(filter: string) {
    if (this.isLoadingService.isLoading("getAllDevices")) return null;

    this.isLoadingService.setLoading("getAllDevices");
    let date: string = new Date().toString(),
      filterR = "all";
    switch (filter) {
      case "widget.water.device.all":
        filterR = "all";
        break;
      case "widget.water.device.online":
        filterR = "online";
        break;
      case "widget.water.device.offline":
        filterR = "offline";
        break;
      case "widget.water.device.vent":
        filterR = "valves";
        break;
      // case 'widget.water.novalidate': filterR = 'novalidate'; break;
    }
    const params = new HttpParams().set("date", date).set("filter", filterR);
    this.store.dispatch(new IsLoadings(true));
    this.http
      .get("//" + this.host.getHost() + "/api/v1/industry-allDevDat-water", {
        params,
      })
      .subscribe(
        (data: TableAllDevice) => {
          this.isLoadingService.dellLoading("getAllDevices"); //закомментировала я см строку 296
          setTimeout(() => {
            this.store.dispatch(new IsLoadings(false));
          }, 2000);

          if (filterR === "all") {
            // let online = 0;
            // let offline = 0;
            // const all = data.data.length;
            // let novalid = 0;

            data.data.forEach((e) => {
              if (e.devType === "vega_tm") {
                e.devType = VegaTmUtil.getCaptionVegaTmBySerNum(e.serNum);
              }

              // if(e.devType === 'iperl' && e.ppSerC === '0' ) {
              //   novalid++;
              // }

              // const dateSelect = new Date(date);
              // dateSelect.setSeconds(0);
              // dateSelect.setHours(0);
              // dateSelect.setMilliseconds(0);
              // dateSelect.setDate(dateSelect.getDate() - 4);

              // const dateElementTmp = e.contactDate.split(' ');
              //
              // if(dateSelect.getTime() > new Date(dateElementTmp[0]).getTime()) {
              //   offline++;
              // } else {
              //   online++;
              // }
            });
            // let object = JSON.parse (localStorage.getItem ("objectWidg"));
            // const widgets = [
            //   {titleWidget:'widget.water.device.online',
            //     data:object?.[0]?.data, colorWidget:'#5cb85c',iconWidget:'dx-icon-todo'},
            //      {titleWidget:'widget.water.device.offline',data:object[1].data,colorWidget:'#5cb85c',iconWidget:'dx-icon-todo'},
            //      {titleWidget:'widget.water.device.all',data:object[2].data,colorWidget:'#9EB8E8',iconWidget:'dx-icon-globe'}
            //      // {titleWidget:'widget.water.novalidate',data:novalid,colorWidget:'#ABAAB5',iconWidget:'dx-icon-help',iconButtonWidget:[{routing:'filter',name:'chevron-left'}],items:[]}
            //    ];

            this.store.dispatch(new LoadWidgetsDevicessAction([]));
          }
          this.store.dispatch(new LoadDevicesAction(data));
        },
        (error) => {
          this.isLoadingService.dellLoading("getAllDevices");
          this.store.dispatch(new IsLoadings(false));
        },
      );
  }

  public newMarshrut(sn: any, num: any) {
    const arr = Array.from(sn);
    const idDevices = arr;
    const body = {
      department: num.pidroz,
      routeName: num.namemar,
      groupName: num.grsname,
      deviation: num.vidhilust,
      idDevices,
    };

    //  this.store.pipe(select(selectCheckedReceiptsDevices)).subscribe(data => receipts.request = data).unsubscribe();

    localStorage.setItem("Receipt5", JSON.stringify(sn));
    const devicesObj5 = JSON.parse(localStorage.getItem("Receipt5"));

    this.store.dispatch(new IsLoadings(true));
    const params = new HttpParams()
      .set("subdivision", num.pidroz)
      .set("route.name", num.namemar)
      .set("name.grs", num.grsname)
      .set("set.deviation", num.vidhilust);

    this.http
      .put("//" + this.host.getHost() + "/api/v1/route/create", body)
      .subscribe(
        (receipt: CurrentIperlReceipt) => {
          this.store.dispatch(new IsLoadings(false));
          if ((receipt as CurrentIperlReceipt) || null)
            this.store.dispatch(new LoadCurrentIperlReceiptAction(receipt));
        },
        (error) => {
          this.store.dispatch(new IsLoadings(false));
        },
      );
  }

  getAllDevicesVents(filter: string) {
    if (this.isLoadingService.isLoading("getAllDevicesVents")) return null;

    this.isLoadingService.setLoading("getAllDevicesVents");
    let date: string = new Date().toString(),
      filterR: string;
    // switch (filter) {
    //   case 'widget.water.device.all': filterR = 'all'; break;
    //   case 'widget.water.device.online': filterR = 'online'; break;
    //   case 'widget.water.device.offline': filterR = 'offline'; break;
    //   case 'widget.water.device.vent': filterR = 'valves'; break;
    //   case 'widget.water.novalidate': filterR = 'novalidate'; break;
    // }
    const params = new HttpParams().set("date", date).set("filter", filterR);
    this.store.dispatch(new IsLoadings(true));
    this.http
      .get("//" + this.host.getHost() + "/api/v1/industry-allDevDat-water", {
        params,
      })
      .subscribe(
        (data: TableAllDevice) => {
          this.isLoadingService.dellLoading("getAllDevicesVents");
          setTimeout(() => {
            this.store.dispatch(new IsLoadings(false));
          }, 2000);
          if (filter === "widget.water.device.vent") {
            let opend = 0;
            let closed = 0;
            const all = data.data.length;
            data.data.forEach((e) => {
              if (e.statusValve === "false") {
                closed++;
              } else {
                opend++;
              }
              if (e.devType === "vega_tm") {
                e.devType = VegaTmUtil.getCaptionVegaTmBySerNum(e.serNum);
              }
            });

            this.store.dispatch(
              new LoadWidgetsVentsAction([
                {
                  titleWidget: "open.ve",
                  data: opend,
                  colorWidget: "#5cb85c",
                  iconWidget: "dx-icon-todo",
                },
                {
                  titleWidget: "closed.vent",
                  data: closed,
                  colorWidget: "#f26e74",
                  iconWidget: "dx-icon-close",
                },
                {
                  titleWidget: "all.vent",
                  data: all,
                  colorWidget: "#9eb8e8",
                  iconWidget: "dx-icon-globe",
                },
              ]),
            );
          }
          this.store.dispatch(new LoadDevicessVents(data));
        },
        (error) => {
          this.store.dispatch(new IsLoadings(false));
          this.isLoadingService.dellLoading("getAllDevicesVents");
        },
      );
  }

  getWidgetsDevices() {
    if (this.isLoadingService.isLoading("getWidgetsDevices")) return null;

    this.isLoadingService.setLoading("getWidgetsDevices");
    this.store.dispatch(new IsWidgetsLoading(true));
    const date: string = new Date().toString();
    const params = new HttpParams().set("date", date);
    this.http
      .get(
        "//" + this.host.getHost() + "/api/v1/industry-allDevDat-widget-water",
        { params },
      )
      .subscribe(
        (data: Widget[]) => {
          this.isLoadingService.dellLoading("getWidgetsDevices");
          this.store.dispatch(new IsWidgetsLoading(false));
          this.store.dispatch(new LoadWidgetsDevicessAction(data));
        },
        (error) => {
          this.isLoadingService.dellLoading("getWidgetsDevices");
          this.store.dispatch(new IsWidgetsLoading(false));
        },
      );
  }

  allOneDeviceReqest() {
    const type: string = "water.daily";
    switch (type) {
      case "water.daily":
        this.getOneDevicesDaily();
        break;
      case "water.hourly":
        this.getOneDevicesHoursRoute();
        break; //////////////
      case "water.operation.routes":
        this.getOneDevicesOperationsRoute();
        break; //////////////
      case "water.hour":
        this.getOneDevicesHours();
        break;
      case "water.telemetry":
        this.getOneDevicesTelem();
        break;
      case "water.operation":
        this.getOperationDevices();
        break; /////////////
    }
  }

  getOneDevicesDaily() {
    this.store.dispatch(new IsLoadings(true));
    const req: any = {};
    let ff = localStorage.getItem("idRouteNew");
    if (ff == null) {
      const object = JSON.parse(localStorage.getItem("ekonomiyNaVsem"));
      ff = object.data[0].id;
    } else {
      ff = localStorage.getItem("idRouteNew");
    }
    this.store
      .pipe(
        map((device) => device?.["devicePage"].currentDevice),
        filter((value: CurrentDevice) => !!value && !!value.device),
      )
      .subscribe((dev: CurrentDevice): void => {
        const typeDevTmp = dev.device.devType.split(".");
        let saveDevTmp = dev.device.devType;
        if (typeDevTmp[0] === "vega_tm") saveDevTmp = typeDevTmp[0];

        req.serNUM;
        req.manufacturer = dev.device.manufacturer;
        req.devType = saveDevTmp;
        req.twoCounters = dev.device.twoCounters;
      })
      .unsubscribe();
    let from;
    let to;
    this.store
      .pipe(map((date) => date?.["volumesPage"].date))
      .subscribe((reqdate) => {
        const obj = { ...reqdate };
        from = this.myTime.dateToLocalString(obj.from);
        to = this.myTime.dateToLocalString(obj.to);
        localStorage.setItem("fromfrom", from);
        localStorage.setItem("toto", to);
      })
      .unsubscribe();
    const params = new HttpParams()
      .set("idRoute", ff)
      .set("from", from)
      .set("to", to)
      .set("typeRequest", "daily");

    this.http
      .get("//" + this.host.getHost() + "/api/v1/route/readOne", { params })
      // this.http.get('http://localhost:3000/industry-dailyDev-water', {params})
      .subscribe(
        (responce: TableAllDevice) => {
          if (responce.pressure) responce = this.hmps.daily(responce);
          this.store.dispatch(new LoadCurrentDeviceAction(responce));
          this.store.dispatch(new IsLoadings(false));
        },
        (error) => this.store.dispatch(new IsLoadings(false)),
      );
  }

  getOneDevicesHoursRoute() {
    this.store.dispatch(new IsLoadings(true));
    const req: any = {};
    let ff = localStorage.getItem("idRouteNew");
    if (ff == null) {
      const object = JSON.parse(localStorage.getItem("ekonomiyNaVsem"));
      ff = object.data[0].id;
    } else {
      ff = localStorage.getItem("idRouteNew");
    }
    this.store
      .pipe(map((device) => device?.["devicePage"].currentDevice))
      .subscribe((dev) => {
        const typeDevTmp = dev.device.devType.split(".");
        let saveDevTmp = dev.device.devType;
        if (typeDevTmp[0] === "vega_tm") saveDevTmp = typeDevTmp[0];

        req.serNUM;
        req.manufacturer = dev.device.manufacturer;
        req.devType = saveDevTmp;
        req.twoCounters = dev.device.twoCounters;
      })
      .unsubscribe();
    let from;
    let to;
    this.store
      .pipe(map((date) => date?.["volumesPage"].date))
      .subscribe((reqdate) => {
        const obj = { ...reqdate };
        from = this.myTime.dateToLocalString(obj.from);
        to = this.myTime.dateToLocalString(obj.to);
      })
      .unsubscribe();
    const params = new HttpParams()
      .set("idRoute", ff)
      .set("from", from)
      .set("to", to)
      .set("typeRequest", "hourly");

    this.http
      .get("//" + this.host.getHost() + "/api/v1/route/readOne", { params })
      // this.http.get('http://localhost:3000/industry-dailyDev-water', {params})
      .subscribe(
        (responce: TableAllDevice) => {
          if (responce.pressure) responce = this.hmps.daily(responce);
          this.store.dispatch(new LoadCurrentDeviceAction(responce));
          this.store.dispatch(new IsLoadings(false));
        },
        (error) => this.store.dispatch(new IsLoadings(false)),
      );
  }

  getOneDevicesOperationsRoute() {
    this.store.dispatch(new IsLoadings(true));
    const req: any = {};
    let ff = localStorage.getItem("idRouteNew");
    if (ff == null) {
      const object = JSON.parse(localStorage.getItem("ekonomiyNaVsem"));
      ff = object.data[0].id;
    } else {
      ff = localStorage.getItem("idRouteNew");
    }
    this.store
      .pipe(map((device) => device?.["devicePage"].currentDevice))
      .subscribe((dev) => {
        const typeDevTmp = dev.device.devType.split(".");
        let saveDevTmp = dev.device.devType;
        if (typeDevTmp[0] === "vega_tm") saveDevTmp = typeDevTmp[0];

        req.serNUM;
        req.manufacturer = dev.device.manufacturer;
        req.devType = saveDevTmp;
        req.twoCounters = dev.device.twoCounters;
      })
      .unsubscribe();
    let from;
    let to;
    this.store
      .pipe(map((date) => date?.["volumesPage"].date))
      .subscribe((reqdate) => {
        const obj = { ...reqdate };
        from = this.myTime.dateToLocalString(obj.from);
        to = this.myTime.dateToLocalString(obj.to);
      })
      .unsubscribe();
    const params = new HttpParams()
      .set("idRoute", ff)
      .set("from", from)
      .set("to", from)
      .set("typeRequest", "operational");

    this.http
      .get("//" + this.host.getHost() + "/api/v1/route/readOne", { params })
      // this.http.get('http://localhost:3000/industry-dailyDev-water', {params})
      .subscribe(
        (responce: TableAllDevice) => {
          if (responce.pressure) responce = this.hmps.daily(responce);
          this.store.dispatch(new LoadCurrentDeviceAction(responce));
          this.store.dispatch(new IsLoadings(false));
        },
        (error) => this.store.dispatch(new IsLoadings(false)),
      );
  }

  getOneDevicesHours() {
    this.store.dispatch(new IsLoadings(true));
    const req: any = {};
    this.store
      .pipe(map((device) => device?.["devicePage"].currentDevice))
      .subscribe((dev) => {
        const typeDevTmp = dev.device.devType.split(".");
        let saveDevTmp = dev.device.devType;
        if (typeDevTmp[0] === "vega_tm") saveDevTmp = typeDevTmp[0];

        req.serNUM = dev.device.numberCorrector;
        req.manufacturer = dev.device.manufacturer;
        req.devType = saveDevTmp;
        req.twoCounters = dev.device.twoCounters;
      })
      .unsubscribe();
    this.store
      .pipe(map((date) => date?.["volumesPage"].date))
      .subscribe((reqdate) => {
        req.from = this.myTime.dateToLocalString(reqdate.from);
        req.to = this.myTime.dateToLocalString(reqdate.to);
      })
      .unsubscribe();
    const params = new HttpParams()
      .set("serNUM", req.serNUM)
      .set("from", req.from)
      .set("to", req.to)
      .set("mfDEV", req.manufacturer)
      .set("typeDEV", req.devType)
      .set("twoCounters", req.twoCounters);
    this.http
      .get("//" + this.host.getHost() + "/api/v1/industry-hourDev-water", {
        params,
      })
      .subscribe(
        (responce: TableAllDevice) => {
          this.store.dispatch(new LoadCurrentDeviceAction(responce));
          this.store.dispatch(new IsLoadings(false));
        },
        (error) => {
          this.store.dispatch(new IsLoadings(false));
        },
      );
  }

  getOperationDevices() {
    this.store.dispatch(new IsLoadings(true));
    const req: any = {};
    this.store
      .pipe(map((device) => device?.["devicePage"].currentDevice))
      .subscribe((dev) => {
        const typeDevTmp = dev.device.devType.split(".");
        let saveDevTmp = dev.device.devType;
        if (typeDevTmp[0] === "vega_tm") saveDevTmp = typeDevTmp[0];

        req.serNUM = dev.device.numberCorrector;
        req.manufacturer = dev.device.manufacturer;
        req.devType = saveDevTmp;
        req.twoCounters = dev.device.twoCounters;
      })
      .unsubscribe();
    this.store
      .pipe(map((date) => date?.["volumesPage"].date))
      .subscribe((reqdate) => {
        req.from = this.myTime.dateToLocalString(reqdate.opeat);
        req.to = this.myTime.dateToLocalString(reqdate.opeat);
      })
      .unsubscribe();
    const params = new HttpParams()
      .set("serNUM", req.serNUM)
      .set("from", req.to)
      .set("to", req.to)
      .set("mfDEV", req.manufacturer)
      .set("typeDEV", req.devType)
      .set("twoCounters", req.twoCounters);
    this.http
      .get("//" + this.host.getHost() + "/api/v1/industry-oneHourDev-water", {
        params,
      })
      .subscribe(
        (responce: TableAllDevice) => {
          this.store.dispatch(new LoadCurrentDeviceAction(responce));
          this.store.dispatch(new IsLoadings(false));
        },
        (error) => {
          this.store.dispatch(new IsLoadings(false));
        },
      );
  }

  getOneDevicesTelem() {
    this.store.dispatch(new IsLoadings(true));
    const req: any = {};
    this.store
      .pipe(map((device) => device?.["devicePage"].currentDevice))
      .subscribe((dev) => {
        const typeDevTmp = dev.device.devType.split(".");
        let saveDevTmp = dev.device.devType;
        if (typeDevTmp[0] === "vega_tm") saveDevTmp = typeDevTmp[0];
        req.serNUM = dev.device.numberCorrector;
        req.manufacturer = dev.device.manufacturer;
        req.typeDEV = dev.device.devType;
      })
      .unsubscribe();
    this.store
      .pipe(map((date) => date.globalApp.date))
      .subscribe((reqdate) => {
        req.from = this.myTime.dateToLocalString(reqdate.from);
        req.to = this.myTime.dateToLocalString(reqdate.to);
      })
      .unsubscribe();

    const params = new HttpParams()
      .set("serNUM", req.serNUM)
      .set("from", req.from)
      .set("to", req.to)
      .set("mfDEV", req.manufacturer)
      .set("typeDEV", req.typeDEV);
    this.http
      .get("//" + this.host.getHost() + "/api/v1/industry-telemetryDev-water", {
        params,
      })
      .subscribe(
        (responce: TableAllDevice) => {
          responce.data.forEach((e, i) => {
            e.idxtc = i;
            if (e.tpDev === "vega_tm") {
              e.tpDev = VegaTmUtil.getCaptionVegaTmBySerNum(e.serNUM);
            }
          });
          this.store.dispatch(new LoadCurrentDeviceAction(responce));
          this.store.dispatch(new IsLoadings(false));
        },
        (error) => {
          this.store.dispatch(new IsLoadings(false));
        },
      );
  }

  loadPrintData(
    date: { from: Date; to: Date },
    eics: string[],
  ): Observable<any> {
    // this.store.dispatch(new IsLoadings(true));
    const params = new HttpParams()
      .set("from", this.myTime.dateToLocalString(date.from))
      .set("to", this.myTime.dateToLocalString(date.to));
    return this.http.post(
      "//" + this.host.getHost() + "/api/v1/industry-reporting-water",
      eics,
      { params },
    );
  }
  //
  getStatement(date: { from: Date; to: Date; opeat: Date }) {
    let isFrom = true;
    this.store
      .pipe(map((date) => date?.["volumesPage"].type))
      .subscribe((type) => {
        isFrom = type !== "water.operation";
      })
      .unsubscribe();

    if (this.isLoadingService.isLoading("getStatement")) return null;

    this.isLoadingService.setLoading("getStatement");
    this.store.dispatch(new IsLoadings(true));
    const params = new HttpParams().set("operational", !isFrom + "");
    //  this.http.get('http://localhost:3000/industry-statement-water', {params}).subscribe((table: TableAllDevice)
    this.http
      .get("//" + this.host.getHost() + "/api/v1/route/readAll")
      .subscribe(
        (table: TableAllDevice) => {
          this.isLoadingService.dellLoading("getStatement");
          if (table as TableAllDevice) {
            table.data.forEach((e, i) => {
              e.idxtc = i;
              if (e.typeDev === "vega_tm") {
                e.typeDev = VegaTmUtil.getCaptionVegaTmBySerNum(e.serNum);
              }
            });
            this.store.dispatch(new SetVolumesActions(table));
            this.store.dispatch(new IsLoadings(false));
          }
        },
        (error) => {
          this.isLoadingService.dellLoading("getStatement");
          this.store.dispatch(new IsLoadings(false));
        },
      );
  }

  getStatementVolumeTelem(date: { from: Date; to: Date }) {
    let isFrom = true;
    this.store
      .pipe(map((date) => date?.["volumesTelemPage"].type))
      .subscribe((type) => {
        isFrom = type !== "water.operation";
      })
      .unsubscribe();

    if (this.isLoadingService.isLoading("getStatementVolumeTelem")) return null;

    this.isLoadingService.setLoading("getStatementVolumeTelem");
    this.store.dispatch(new IsLoadings(true));
    const params = new HttpParams()
      .set(
        "from",
        isFrom
          ? this.myTime.dateToLocalString(date.from)
          : this.myTime.dateToLocalString(date.to),
      )
      .set("to", this.myTime.dateToLocalString(date.to))
      .set("operational", !isFrom + "");

    this.http
      .get(
        "//" + this.host.getHost() + "/api/v1/industry-telemetry-list-water",
        { params },
      )
      .subscribe(
        (table: TableAllDevice) => {
          this.isLoadingService.dellLoading("getStatementVolumeTelem");
          if (table as TableAllDevice) {
            table.data.forEach((e, i) => {
              e.idxtc = i;
              if (e.typeDev === "vega_tm") {
                e.typeDev = VegaTmUtil.getCaptionVegaTmBySerNum(e.serNum);
              }
            });
            this.store.dispatch(new SetVolumesTelemActions(table));
            this.store.dispatch(new IsLoadings(false));
          }
        },
        (error) => {
          this.isLoadingService.dellLoading("getStatementVolumeTelem");
          this.store.dispatch(new IsLoadings(false));
        },
      );
  }

  getAccidents(date: { from: string; to: string }): void {
    // this.store.dispatch(new IsLoadings(true));
    const params = new HttpParams().set("date", date.from);
    this.http
      .get("//" + this.host.getHost() + "/api/v1/industry-allAccidents-water", {
        params,
      })
      .subscribe(
        (accidents: Accidents) => {
          this.store.dispatch(new SetAccidentsActions(accidents));
          this.store.dispatch(new IsLoadings(false));
        },
        (error) => {
          this.store.dispatch(new IsLoadings(false));
        },
      );
  }

  updateDevice1(data) {
    // this.store.dispatch(new IsLoadings(true));
    const httpHeaders = new HttpHeaders({
      "Content-Type": "application/json;charset=utf-8",
    });
    const options = { headers: httpHeaders };

    // let typeDevTmp = data.devType.split('.');
    // let saveDevTmp = data.devType;
    // if( typeDevTmp[0] === 'vega_tm') saveDevTmp = typeDevTmp[0];

    const body = {
      department: data.department,
      groupName: data.groupName,
      routeName: data.routeName,
      deviation: data.deviation,
      note: data.note,
      id: data.id,
    };

    this.http
      .post("//" + this.host.getHost() + "/api/v1/route/update", body, options)
      .subscribe();
  }

  updateDevice(data) {
    // this.store.dispatch(new IsLoadings(true));
    const httpHeaders = new HttpHeaders({
      "Content-Type": "application/json;charset=utf-8",
    });
    const options = { headers: httpHeaders };

    const typeDevTmp = data.devType.split(".");
    let saveDevTmp = data.devType;
    if (typeDevTmp[0] === "vega_tm") saveDevTmp = typeDevTmp[0];

    const body = {
      book: data.book,
      city: data.city,
      code: data.code,
      twoCounters: data.devType === "vega_tm" ? data.twoCounters : false,
      indicationCh0:
        data.indicationCh0 !== null ? "" + data.indicationCh0 : "0",
      indicationCh1:
        data.indicationCh1 !== null ? "" + data.indicationCh1 : "0",
      coeffCh0: "" + data.coeffCh0,
      coeffCh1: "" + data.coeffCh1,
      contactDate: data.contactDate,
      ddTelNum: data.ddTelNum,
      devType: saveDevTmp,
      district: data.district,
      house: data.house,
      mfDev: data.mfDev,
      ownerAccountPersonal: data.ownerAccountPersonal,
      ownerNameDevice: data.ownerNameDevice,
      ppEastLong: "" + data.ppEastLong,
      ppNorthWidth: "" + data.ppNorthWidth,
      ppSerC:
        saveDevTmp !== "vega_tm"
          ? data.ppSerC
          : data.ppSerC1 + "/" + data.ppSerC2,
      ppVersRtv: data.ppVersRtv,
      ppVersRtvLow: data.ppVersRtvLow,
      ppVersRtvTop: data.ppVersRtvTop,
      region: data.region,
      serNum: data.serNum,
      startedIndication: data.startedIndication,
      street: data.street,
      typeCount: data.typeCount,
      valve: data.valve,
      validation: data.validation,
      subscriberCode: data.subscriberCode,
      status: data.status,
    };

    this.http
      .post(
        "//" + this.host.getHost() + "/api/v1/industry-updDevInf-water",
        body,
        options,
      )
      .subscribe();
  }

  saveMarshruti(idDevices) {
    const idRoute1 = localStorage.getItem("idRouteNew");
    const body = {
      idDevices,
      id: idRoute1,
    };
    this.http
      .post("//" + this.host.getHost() + "/api/v1/route/saveOrdenDev", body)
      .subscribe();
  }

  updateMarshruti(data) {
    // this.store.dispatch(new IsLoadings(true));
    const httpHeaders = new HttpHeaders({
      "Content-Type": "application/json;charset=utf-8",
    });
    const options = { headers: httpHeaders };

    const typeDevTmp = data.devType.split(".");
    let saveDevTmp = data.devType;
    if (typeDevTmp[0] === "vega_tm") saveDevTmp = typeDevTmp[0];

    const body = {
      book: data.book,
      city: data.city,
      code: data.code,
      twoCounters: data.devType === "vega_tm" ? data.twoCounters : false,
      indicationCh0:
        data.indicationCh0 !== null ? "" + data.indicationCh0 : "0",
      indicationCh1:
        data.indicationCh1 !== null ? "" + data.indicationCh1 : "0",

      contactDate: data.contactDate,
      ddTelNum: data.ddTelNum,
      devType: saveDevTmp,
      district: data.district,
      house: data.house,
      mfDev: data.mfDev,
      ownerAccountPersonal: data.ownerAccountPersonal,
      ownerNameDevice: data.ownerNameDevice,
      ppEastLong: "" + data.ppEastLong,
      ppNorthWidth: "" + data.ppNorthWidth,
      ppSerC:
        saveDevTmp !== "vega_tm"
          ? data.ppSerC
          : data.ppSerC1 + "/" + data.ppSerC2,

      region: data.region,
      serNum: data.serNum,
      startedIndication: data.startedIndication,
      street: data.street,
      typeCount: data.typeCount,
      valve: data.valve,
      validation: data.validation,
      subscriberCode: data.subscriberCode,
      status: data.status,
    };

    this.http
      .post("//" + this.host.getHost() + "/api/v1/updateRoutes", body, options)
      .subscribe();
  }

  public loadTelemetrySensorsPressure(): void {
    if (this.isLoadingService.isLoading("loadTelemetrySensorsPressure"))
      return null;
    this.isLoadingService.setLoading("loadTelemetrySensorsPressure");
    this.store.dispatch(new IsLoadings(true));
    this.http
      .get<TableDataHead>(
        "//" + this.host.getHost() + "/api/v1/industry-list-sensors",
      )
      .subscribe(
        (table) => {
          this.isLoadingService.dellLoading("loadTelemetrySensorsPressure");

          // setTimeout(()=>{this.store.dispatch(new IsLoadings(false))},2000);//закомментировала я
          //потому как из за этой строки прыгает таблица при первичной загрузке

          // if (table as Pressure[]) {
          //   console.log(table);
          // LoadDataAllDataTablePressureAction
          // table.forEach(e=>{
          if (table as TableDataHead) {
            table.data.forEach((e) => {
              if (e.devType === "vega_tm") {
                e.devType = VegaTmUtil.getCaptionVegaTmBySerNum(e.serNum);
              }
              if (
                e.sensorInBuiltPressure &&
                (e.sensorInBuiltPressure.data.currentVal === 999.9 ||
                  e.sensorInBuiltPressure.data.currentVal === -999.9)
              ) {
                e.sensorInBuiltPressure.data.currentVal = "sensor.accident";
              }
              if (
                e.sensorAmperageLoopPressure &&
                (e.sensorAmperageLoopPressure.data.currentVal === 999.9 ||
                  e.sensorAmperageLoopPressure.data.currentVal === -999.9)
              ) {
                e.sensorAmperageLoopPressure.data.currentVal =
                  "sensor.accident";
              }
              if (
                e.sensorTemperature &&
                (e.sensorTemperature.data.currentVal === 999.9 ||
                  e.sensorTemperature.data.currentVal === -999.9)
              ) {
                e.sensorTemperature.data.currentVal = "sensor.accident";
              }
            });

            this.store.dispatch(new LoadAllTablePressureAction(table));
            this.store.dispatch(new LoadDataAllDataTablePressureAction(table));
          }
          this.store.dispatch(new IsLoadings(false));
        },
        (error) => {
          this.isLoadingService.dellLoading("loadTelemetrySensorsPressure");
          this.store.dispatch(new IsLoadings(false));
        },
      );
  }

  allOneDeviceReqestTelem() {
    let type: string;
    this.store
      .pipe(map((data) => data?.["devicePage"].currentDevice.datePeriod.type))
      .subscribe((t) => (type = t))
      .unsubscribe();
    switch (type) {
      case "water.daily":
        this.loadTelemetryOneDeviceSensorsPressureDaily();
        break;
      case "water.hour":
        this.loadTelemetryOneDeviceSensorsPressureHourly();
        break;
      case "water.operation":
        this.loadTelemetryOneDeviceSensorsPressure();
        break;
      // case 'water.telemetry': this.getOneDevicesTelem(); break;
      case "water.telemetry":
        this.loadTelemetryDetailsOneDeviceSensorsPressure();
        break;
    }
  }

  public loadTelemetryOneDeviceSensorsPressureDaily(): void {
    this.store.dispatch(new IsLoadings(true));
    let date: { from: Date; to: Date }, sensor: string, devEUI: number;
    this.store
      .pipe(map((state: AppState) => state.globalApp.date))
      .subscribe((dateGl) => (date = dateGl))
      .unsubscribe();
    this.store
      .pipe(map((state: AppState) => state?.["telemPage"].details.current))
      .subscribe((data) => {
        if (data) {
          sensor = data.sensorStruct;
          devEUI = data.serNum;
        }
      })
      .unsubscribe();
    const params = new HttpParams()
      .set("from", this.myTime.dateToLocalString(date.from))
      .set("to", this.myTime.dateToLocalString(date.to))
      .set("sensor", sensor)
      .set("devEUI", devEUI.toString());
    this.http
      .get<
        any[]
      >("//" + this.host.getHost() + "/api/v1/industry-daily-data-list-sensors", { params })
      .subscribe(
        (table) => {
          // table.forEach(e=>{
          //   if(e.currentVal && (e.currentVal === 999.90 || e.currentVal === -999.90)) {
          //     e.currentVal = 'sensor.accident'
          //   }
          //   if(e.currentValAmpLoop && (e.currentValAmpLoop === 999.90 || e.currentValAmpLoop === -999.90)) {
          //     e.currentValAmpLoop = 'sensor.accident'
          //   }
          // });
          this.store.dispatch(new LoadDataCurrentTablePressureAction(table));

          this.store.dispatch(new IsLoadings(false));
        },
        (error) => {
          this.store.dispatch(new IsLoadings(false));
        },
      );
  }

  public loadTelemetryOneDeviceSensorsPressureHourly(): void {
    this.store.dispatch(new IsLoadings(true));
    let date: { from: Date; to: Date }, sensor: string, devEUI: number;
    this.store
      .pipe(map((state: AppState) => state.globalApp.date))
      .subscribe((dateGl) => (date = dateGl))
      .unsubscribe();
    this.store
      .pipe(map((state: AppState) => state?.["telemPage"].details.current))
      .subscribe((data) => {
        if (data) {
          sensor = data.sensorStruct;
          devEUI = data.serNum;
        }
      })
      .unsubscribe();
    const params = new HttpParams()
      .set("from", this.myTime.dateToLocalString(date.from))
      .set("to", this.myTime.dateToLocalString(date.to))
      .set("sensor", sensor)
      .set("devEUI", devEUI.toString());
    this.http
      .get<
        any[]
      >("//" + this.host.getHost() + "/api/v1/industry-hourly-data-list-sensors", { params })
      .subscribe(
        (table) => {
          table.forEach((e) => {
            if (
              e.currentVal &&
              (e.currentVal === 999.9 || e.currentVal === -999.9)
            ) {
              e.currentVal = "sensor.accident";
            }
            if (
              e.currentValAmpLoop &&
              (e.currentValAmpLoop === 999.9 || e.currentValAmpLoop === -999.9)
            ) {
              e.currentValAmpLoop = "sensor.accident";
            }
          });
          this.store.dispatch(new LoadDataCurrentTablePressureAction(table));
          this.store.dispatch(new IsLoadings(false));
        },
        (error) => {
          this.store.dispatch(new IsLoadings(false));
          console.log(error);
        },
      );
  }

  public loadTelemetryOneDeviceSensorsPressure(): void {
    this.store.dispatch(new IsLoadings(true));
    let date: { from: Date; to: Date; opeat: Date },
      sensor: string,
      devEUI: number;
    this.store
      .pipe(map((state: AppState) => state.globalApp.date))
      .subscribe((dateGl) => (date = dateGl))
      .unsubscribe();
    this.store
      .pipe(map((state: AppState) => state?.["telemPage"].details.current))
      .subscribe((data) => {
        if (data) {
          sensor = data.sensorStruct;
          devEUI = data.serNum;
        }
      })
      .unsubscribe();
    const params = new HttpParams()
      .set("from", this.myTime.dateToLocalString(date.opeat))
      .set("to", this.myTime.dateToLocalString(date.opeat))
      .set("sensor", sensor)
      .set("devEUI", devEUI.toString());
    this.http
      .get<
        Pressure[]
      >("//" + this.host.getHost() + "/api/v1/industry-data-list-sensors", { params })
      .subscribe(
        (table: any) => {
          // if (table as Pressure[]) {
          table.forEach((e) => {
            if (
              e.currentVal &&
              (e.currentVal === 999.9 || e.currentVal === -999.9)
            ) {
              e.currentVal = "sensor.accident";
            }
            if (
              e.currentValAmpLoop &&
              (e.currentValAmpLoop === 999.9 || e.currentValAmpLoop === -999.9)
            ) {
              e.currentValAmpLoop = "sensor.accident";
            }
          });

          this.store.dispatch(new LoadDataCurrentTablePressureAction(table));
          // }
          this.store.dispatch(new IsLoadings(false));
        },
        (error) => {
          this.store.dispatch(new IsLoadings(false));
        },
      );
  }
  //////////////////////////////////////////////////////////////////////Телеметрия
  public loadTelemetryDetailsOneDeviceSensorsPressure(): void {
    this.store.dispatch(
      new LoadDataAllDataTablePressureAction({ head: [], data: [] }),
    );
    this.store.dispatch(new IsLoadings(true));
    let date: { from: Date; to: Date },
      manufacturer: string,
      serNUM: number,
      typeDEV: string;
    this.store
      .pipe(map((state: AppState) => state.globalApp.date))
      .subscribe((dateGl) => (date = dateGl))
      .unsubscribe();
    this.store
      .pipe(map((state: AppState) => state?.["telemPage"].details.current))
      .subscribe((data) => {
        if (data) {
          const typeDevTmp = data.devType.split(".");
          let saveDevTmp = data.devType;
          if (typeDevTmp[0] === "vega_tm") saveDevTmp = typeDevTmp[0];

          manufacturer = data.mfDev;
          typeDEV = saveDevTmp;
          serNUM = data.serNum;
        }
      })
      .unsubscribe();
    const params = new HttpParams()
      .set("from", this.myTime.dateToLocalString(date.from))
      .set("to", this.myTime.dateToLocalString(date.to))
      .set("mfDEV", manufacturer)
      .set("typeDEV", typeDEV)
      .set("serNUM", serNUM.toString());

    this.http
      .get<any>(
        "//" + this.host.getHost() + "/api/v1/industry-telemetryDev-water",
        { params },
      )
      .subscribe(
        (table: any) => {
          if (table as any) {
            table.head.forEach((item) => {
              item.key = item.valueField;
              item.caption = this.translateService.instant(item.caption);
            });
            this.store.dispatch(new LoadDataAllDataTablePressureAction(table));
            // console.log(table);
            // this.columnList.push({key: item.valueField, caption: this.translateService.instant(item.caption)});
          }
          this.store.dispatch(new IsLoadings(false));
        },
        (error) => {
          this.store.dispatch(new IsLoadings(false));
          console.log(error);
        },
      );
  }

  public updateSensorPressureDevice(data) {
    this.store.dispatch(new IsLoadings(true));
    const httpHeaders = new HttpHeaders({
      "Content-Type": "application/json;charset=utf-8",
    });
    const options = { headers: httpHeaders };
    this.http
      .post<ValidationDataPressure>(
        "//" +
          this.host.getHost() +
          "/api/v1/industry-updPressureSensorInf-water",
        {
          district: data.district,
          code: data.code,
          book: data.book,
          city: data.city,
          house: data.house,
          ppEastLong: data.ppEastLong,
          ppNorthWidth: data.ppNorthWidth,
          sensorStruct: data.sensorStruct,
          region: data.region,
          devEUI: data.serNum,
          street: data.street,
        },
        options,
      )
      .subscribe(
        (data: ValidationDataPressure) => {
          this.store.dispatch(new IsLoadings(false));
          if (data as ValidationDataPressure) {
            this.loadTelemetrySensorsPressure();
            // this.store.dispatch(new UpdateOneDevicePressureAction(data));
          }
        },
        (error) => {
          this.store.dispatch(new IsLoadings(false));
        },
      );
  }

  public updateThresholdsDevice(
    data,
    serNum,
    sensorStruct,
    type,
    isCorrectionData = true,
  ) {
    let dimensionPressure = "kpa";
    this.store
      .pipe(map((state: AppState) => state?.["telemPage"]))
      .subscribe((e) => (dimensionPressure = e.dimensionPressure))
      .unsubscribe();

    let sensorType = "A";
    switch (type) {
      case 1:
        sensorType = "I";
        break;
      case 2:
        sensorType = "A";
        break;
      case 3:
        sensorType = "temperature";
        break;
    }

    if (dimensionPressure !== "kpa" && isCorrectionData) {
      data.preThMax = data.preThMax / 0.0101972;
      data.preThMin = data.preThMin / 0.0101972;
      data.thMax = data.thMax / 0.0101972;
      data.thMin = data.thMin / 0.0101972;
    }

    this.store.dispatch(new IsLoadings(true));
    let endpoint: string;
    const params = new HttpParams()
      .set("serNUM", serNum)
      .set("sensorStruct", sensorStruct)
      .set("sensorType", sensorType);
    switch (sensorStruct) {
      case "11":
        endpoint = "industry-thresholds-lora";
        break;
      default:
        endpoint = "sensors-pressure-thresholds";
        break;
    }
    this.http
      .put("//" + this.host.getHost() + "/api/v1/" + endpoint, data, { params })
      .subscribe(
        (responce) => {
          this.store.dispatch(new IsLoadings(false));
          this.toaster.show(
            "success",
            "",
            this.translateService.instant("core.request.update.device") +
              serNum +
              this.translateService.instant("core.request.update.device2"),
          );
        },
        (error) => {
          this.toaster.show(
            "error",
            "",
            this.translateService.instant(
              "core.err.add.request.update.device",
            ) +
              " " +
              serNum,
          );
          console.log(error);
          this.store.dispatch(new IsLoadings(false));
        },
      );
  }

  public infoUpdateThresholdsPressureDevice(sensorStruct, serNum, type) {
    let sensorType = "A";
    switch (type) {
      case 1:
        sensorType = "I";
        break;
      case 2:
        sensorType = "A";
        break;
      case 3:
        sensorType = "temperature";
        break;
    }
    this.store.dispatch(new IsLoadings(true));
    let endpoint: string;
    switch (sensorStruct) {
      case "11":
        endpoint = "industry-existed-receipts-lora";
        break;
      default:
        endpoint = "sensors-pressure-thresholds";
        break;
    }
    const params = new HttpParams()
      .set("sensorStruct", sensorStruct)
      .set("serNUM", serNum)
      .set("sensorType", sensorType);
    this.http
      .get<
        Thresholds<InfoActiveThreshold>
      >("//" + this.host.getHost() + "/api/v1/" + endpoint, { params })
      .subscribe(
        (responce) => {
          this.store.dispatch(new IsLoadings(false));
          if (responce as Thresholds<InfoActiveThreshold>) {
            this.store.dispatch(
              new LoadInfoUpdateThresholdsForOneDevice(responce),
            );
          }
        },
        (error) => {
          this.store.dispatch(new IsLoadings(false));
        },
      );
  }

  // TODO та же штука что и infoUpdateThresholdsPressureDevice() но не делает апдейт стора,
  //  и не ламает динамичное отоюражание компоннета -> DetailTelemMainComponent
  public hotUpdateThresholdsPressureDeviceForModalForm(
    sensorStruct,
    serNum,
    type,
  ): Observable<Thresholds<InfoActiveThreshold>> {
    let sensorType = "A";
    switch (type) {
      case 1:
        sensorType = "I";
        break;
      case 2:
        sensorType = "A";
        break;
      case 3:
        sensorType = "TEMPO";
        break;
    }
    let endpoint: string;
    switch (sensorStruct) {
      case "11":
        endpoint = "industry-existed-receipts-lora";
        break;
      default:
        endpoint = "sensors-pressure-thresholds";
        break;
    }
    const params = new HttpParams()
      .set("sensorStruct", sensorStruct)
      .set("serNUM", serNum)
      .set("sensorType", sensorType);
    return this.http.get<Thresholds<InfoActiveThreshold>>(
      "//" + this.host.getHost() + "/api/v1/" + endpoint,
      { params },
    );
  }

  public cancelNewThresholdRequestPressureDevice(
    id: number,
    sensorStruct,
    serNum,
    type,
  ) {
    let sensorType = "A";
    switch (type) {
      case 1:
        sensorType = "I";
        break;
      case 2:
        sensorType = "A";
        break;
      case 3:
        sensorType = "temperature";
        break;
    }
    this.store.dispatch(new IsLoadings(true));
    let endpoint: string;
    switch (sensorStruct) {
      case "11":
        endpoint = "industry-theresholds-lora-del";
        break;
      default:
        endpoint = "sensors-pressure-thresholds";
        break;
    }
    const params = new HttpParams()
      .set("id", id.toString())
      .set("serNUM", serNum)
      .set("sensorType", sensorType);
    this.http
      .delete<
        Thresholds<InfoActiveThreshold>
      >("//" + this.host.getHost() + "/api/v1/" + endpoint, { params })
      .subscribe(
        (responce) => {
          this.store.dispatch(new IsLoadings(false));
          this.toaster.show(
            "success",
            "",
            this.translateService.instant("core.request.threshold.new.device") +
              " " +
              serNum +
              " " +
              this.translateService.instant(
                "core.request.threshold.new.device2",
              ),
          );
          this.toaster.show(
            "success",
            "",
            this.translateService.instant("core.request.threshold.new.device") +
              " " +
              serNum +
              " " +
              this.translateService.instant(
                "core.request.threshold.new.device2",
              ),
          );
          this.infoUpdateThresholdsPressureDevice(sensorStruct, serNum, type);
        },
        (error) => {
          this.toaster.show(
            "error",
            "",
            this.translateService.instant("data.not.reques") + " " + serNum,
          );
          console.log(error);
          this.store.dispatch(new IsLoadings(false));
        },
      );
  }

  public cancelAccumuletedRequest(ss, sn, page?: string) {
    this.store.dispatch(new IsLoadings(true));
    const params = new HttpParams().set("sensorStruct", ss).set("serNum", sn);
    this.http
      .post(
        "//" + this.host.getHost() + "/api/v1/cancel-pressure-accidents",
        {},
        { params },
      )
      .subscribe(
        (responce) => {
          this.store.dispatch(new IsLoadings(false));
          // if (responce === 'OK') { this.loadTelemetrySensorsPressure(); }
          if (responce === "OK" && page === "accident") {
            this.getAccidentsActive();
          } else {
            this.loadTelemetrySensorsPressure();
          }
        },
        (error) => {
          this.store.dispatch(new IsLoadings(false));
        },
      );
  }

  public sendChangeVentStaus(status: string) {
    this.store.dispatch(new IsLoadings(true));

    let serNUM: string;
    let typeDEV: string;
    this.store
      .pipe(map((state: AppState) => state?.["ventsPage"].currentDeviceVent))
      .subscribe((vent) => {
        const typeDevTmp = vent.devType.split(".");
        let saveDevTmp = vent.devType;
        if (typeDevTmp[0] === "vega_tm") saveDevTmp = typeDevTmp[0];

        serNUM = vent.serNum;
        typeDEV = saveDevTmp;
      })
      .unsubscribe();
    const params = new HttpParams()
      .set("serNUM", serNUM)
      .set("typeDEV", typeDEV)
      .set("status", status);
    this.http
      .get("//" + this.host.getHost() + "/api/v1/industry-valve-water", {
        params,
      })
      .subscribe(
        (vector) => {
          this.store.dispatch(new IsLoadings(false));
          if (vector) {
            this.industryValveWaterReceipts();
          }
        },
        (error) => {
          this.store.dispatch(new IsLoadings(false));
        },
      );
  }

  public industryValveWaterReceipts() {
    this.store.dispatch(new IsLoadings(true));
    let serNUM: string;
    let typeDEV: string;
    this.store
      .pipe(map((state: AppState) => state?.["ventsPage"].currentDeviceVent))
      .subscribe((vent) => {
        const typeDevTmp = vent.devType.split(".");
        let saveDevTmp = vent.devType;
        if (typeDevTmp[0] === "vega_tm") saveDevTmp = typeDevTmp[0];

        serNUM = vent.serNum;
        typeDEV = saveDevTmp;
      })
      .unsubscribe();
    const params = new HttpParams()
      .set("serNUM", serNUM)
      .set("typeDEV", typeDEV);
    this.http
      .get<VentsReceipts>(
        "//" + this.host.getHost() + "/api/v1/industry-valve-water-receipts",
        { params },
      )
      .subscribe(
        (receipt: VentsReceipts) => {
          this.store.dispatch(new IsLoadings(false));
          if (receipt as VentsReceipts) {
            this.store.dispatch(new SetCurrentDeviceVentReceipt(receipt));
          }
        },
        (error) => {
          this.store.dispatch(new IsLoadings(false));
        },
      );
  }

  public industryValveWaterDeleteReceipt(id: number) {
    // this.store.dispatch(new IsLoadings(true));
    const params = new HttpParams().set("id", id.toString());
    this.http
      .delete(
        "//" +
          this.host.getHost() +
          "/api/v1/industry-valve-water-delete-receipt",
        { params },
      )
      .subscribe(
        (vector) => {
          // this.store.dispatch(new IsLoadings(false));
          // console.log(vector);
          if (vector === "OK") {
            this.industryValveWaterReceipts();
          }
        },
        (error) => {
          console.log("err");
          // this.store.dispatch(new IsLoadings(false));
        },
      );
  }

  public sendIperlCurrentReceipt(sn: string, num: number) {
    this.store.dispatch(new IsLoadings(true));
    const params = new HttpParams()
      .set("serNumCounter", num.toString())
      .set("serNum", sn.toString());
    this.http
      .post(
        "//" + this.host.getHost() + "/api/v1/validate-iperl-counter",
        {},
        { params },
      )
      .subscribe(
        (receipt: CurrentIperlReceipt) => {
          this.store.dispatch(new IsLoadings(false));
          if ((receipt as CurrentIperlReceipt) || null)
            this.store.dispatch(new LoadCurrentIperlReceiptAction(receipt));
        },
        (error) => {
          this.store.dispatch(new IsLoadings(false));
        },
      );
  }

  public getIperlCurrentReceipt(sn: string) {
    this.store.dispatch(new IsLoadings(true));
    const params = new HttpParams().set("serNUM", sn.toString());
    this.http
      .get("//" + this.host.getHost() + "/api/v1/validate-iperl-counter", {
        params,
      })
      .subscribe(
        (receipt: CurrentIperlReceipt | null) => {
          this.store.dispatch(new IsLoadings(false));
          if (receipt as CurrentIperlReceipt | null)
            this.store.dispatch(new LoadCurrentIperlReceiptAction(receipt));
        },
        (error) => {
          this.store.dispatch(new IsLoadings(false));
        },
      );
  }

  public deleteIperlCurrentReceipt() {
    this.store.dispatch(new IsLoadings(true));
    let idx: number;

    this.store
      .pipe(map((receipt) => receipt?.["devicePage"].currentIperlReceipt.id))
      .subscribe((id) => (idx = id))
      .unsubscribe();
    const params = new HttpParams().set("id", idx.toString());
    this.http
      .delete("//" + this.host.getHost() + "/api/v1/validate-iperl-counter", {
        params,
      })
      .subscribe(
        (_) => {
          this.store.dispatch(new IsLoadings(false));
          this.store.dispatch(new LoadCurrentIperlReceiptAction(null));
        },
        (error) => {
          this.store.dispatch(new IsLoadings(false));
        },
      );
  }

  public getVentsArchiveFromTo() {
    this.store.dispatch(new IsLoadings(true));
    let from: Date;
    let to: Date;
    let serNUM: string;
    let typeDEV: string;

    this.store
      .pipe(map((state: AppState) => state.globalApp.date))
      .subscribe((date) => {
        from = date.from;
        to = date.to;
      })
      .unsubscribe();
    this.store
      .pipe(map((state: AppState) => state?.["ventsPage"].currentDeviceVent))
      .subscribe((vent) => {
        const typeDevTmp = vent.devType.split(".");
        let saveDevTmp = vent.devType;
        if (typeDevTmp[0] === "vega_tm") saveDevTmp = typeDevTmp[0];

        serNUM = vent.serNum;
        typeDEV = saveDevTmp;
      })
      .unsubscribe();

    const params = new HttpParams()
      .set("serNUM", serNUM)
      .set("sensorStruct", typeDEV)
      .set("from", this.myTime.dateToLocalString(from))
      .set("to", this.myTime.dateToLocalString(to));

    this.http
      .get<
        VentsArchive[]
      >("//" + this.host.getHost() + "/api/v1/valves-receipt-archive", { params })
      .subscribe(
        (archives: VentsArchive[]) => {
          this.store.dispatch(new IsLoadings(false));
          if (archives as VentsArchive[]) {
            this.store.dispatch(new SetCurrentDeviceVentArchive(archives));
          }
        },
        (error) => {
          this.store.dispatch(new IsLoadings(false));
        },
      );
  }

  public getUsersControl() {
    const tmp = [
      {
        name: "Dasha",
        login: "dashalogin",
        password: "qwerty",
        position: "developer",
        role: "admin",
        groupDevices: "group1",
        statusUser: "true",
        mail: "grhtjjkbjk.cg",
      },
      {
        name: "Pasha",
        login: "pashalogin",
        password: "qwertyy",
        role: "user",
        groupDevices: "group2",
        position: "manager",
        statusUser: "false",
        mail: "grhtjjkbjk.cg",
      },
    ];

    this.store.dispatch(new SetUsersControlActions(tmp));
  }

  public getUsersControlGroupDevices() {
    const tmp = ["group1", "group2", "group3"];
    this.store.dispatch(new SetUsersGroupDeviceActions(tmp));
  }

  public getVentAutotest(serNUM: string, mfDEV: string, typeDEV: string) {
    const params = new HttpParams()
      .set("serNUM", serNUM)
      .set("mfDEV", mfDEV)
      .set("typeDEV", typeDEV)
      .set("date", this.myTime.dateToLocalString(new Date()));

    return this.http.get(
      "//" + this.host.getHost() + "/api/v1/industry-valve-water-test",
      { params },
    );
  }

  public setStatusDevice(data: {
    status: number;
    device: Record<string, unknown>;
  }): void {
    const { status, device } = data;

    const newDevice: DeviceForStatus = {
      twoCounters: device.twoCounters as boolean,
      serNum: device.serNum as string,
      devType: device.devType as string,
      mfDev: device.mfDev as string,
      pressure: device.pressure as boolean,
      status,
    };

    this.http
      .put(
        "//" + this.host.getHost() + "/api/v1/industry-updDevInf-water",
        newDevice,
      )
      .subscribe((responce: DeviceForStatus) => {
        this.store.dispatch(new SetCurrentStatusDeviceAction(newDevice));
        this.store.dispatch(new LoadCurrentStatusDeviceAction(newDevice));
        this.store.dispatch(new SetActiveStatusAction(responce.status));
      });
  }

  getAccidentsActive() {
    if (this.isLoadingService.isLoading("getAccidentsActive")) return null;

    this.isLoadingService.setLoading("getAccidentsActive");
    this.store.dispatch(new IsLoadings(true));
    this.http
      .get(
        "//" +
          this.host.getHost() +
          "/api/v1/industry-all-active-accidents-water",
      ) ////////////////////////////////////
      .subscribe(
        (accidents: Accidents) => {
          this.isLoadingService.dellLoading("getAccidentsActive");
          this.store.dispatch(new IsLoadings(false));
          accidents.data.forEach((e) => {
            if (e.typeDev === "vega_tm") {
              e.typeDev = VegaTmUtil.getCaptionVegaTmBySerNum(e.serNum);
            }
          });

          // let ttt = accidents.data[0];
          //   for (let i = 0; i < 1000000; i++) {
          //     accidents.data.push(ttt)
          //   }

          this.store.dispatch(new SetAccidentsActions(accidents));
          // this.store.dispatch(new SetAccidentsActions(accidents));
          this.store.dispatch(new IsLoadings(false));
        },
        (error) => {
          this.isLoadingService.dellLoading("getAccidentsActive");
          this.store.dispatch(new IsLoadings(false));
        },
      );
  }
  getAccidentsDaily() {
    this.http
      .get(
        "//" +
          this.host.getHost() +
          "/api/v1/industry-all-current-accidents-water",
      )
      .subscribe(
        (accidents: Accidents) => {
          accidents.data.forEach((e) => {
            if (e.typeDev === "vega_tm") {
              e.typeDev = VegaTmUtil.getCaptionVegaTmBySerNum(e.serNum);
            }
          });
          this.store.dispatch(new SetAccidentsActions(accidents));
          this.store.dispatch(new IsLoadings(false));
        },
        (error) => {
          this.store.dispatch(new IsLoadings(false));
        },
      );
  }
  getAccidentsArchive(date: { from: string; to: string }) {
    this.store.dispatch(new IsLoadings(true));
    const params = new HttpParams().set("from", date.from).set("to", date.to);
    this.http
      .get(
        "//" +
          this.host.getHost() +
          "/api/v1/industry-allAccidentsArchive-water",
        { params },
      )
      .subscribe(
        (accidents: Accidents) => {
          accidents.data.forEach((e) => {
            if (e.typeDev === "vega_tm") {
              e.typeDev = VegaTmUtil.getCaptionVegaTmBySerNum(e.serNum);
            }
          });
          this.store.dispatch(new SetAccidentsActions(accidents));
          this.store.dispatch(new IsLoadings(false));
        },
        (error) => {
          this.store.dispatch(new IsLoadings(false));
        },
      );
  }

  dellAccidentsDetail() {
    console.log("dellAccidentsDetail");
  }

  getLogDevicesFromTo(from: string, to: string) {
    if (this.isLoadingService.isLoading("getLogDevicesFromTo")) return null;

    this.isLoadingService.setLoading("getLogDevicesFromTo");
    this.store.dispatch(new IsLoadings(true));
    const params = new HttpParams().set("from", from).set("to", to);
    this.http
      .get(
        "//" + this.host.getHost() + "/api/v1/industry-allInfoUpdates-water",
        { params },
      )
      .subscribe(
        (log: Loggers) => {
          log.data.forEach((e) => {
            if (e.devType === "vega_tm") {
              e.devType = VegaTmUtil.getCaptionVegaTmBySerNum(e.serNum);
            }
          });
          this.isLoadingService.dellLoading("getLogDevicesFromTo");
          this.store.dispatch(new SetLogDevicesActions(log));
          this.store.dispatch(new IsLoadings(false));
        },
        (error) => {
          this.isLoadingService.dellLoading("getLogDevicesFromTo");
          this.store.dispatch(new IsLoadings(false));
        },
      );
  }

  getLogTelemFromTo(from: string, to: string) {
    let dimensionPressure = "kpa";
    this.store
      .pipe(map((state: AppState) => state?.["telemPage"].dimensionPressure))
      .subscribe((value) => (dimensionPressure = value))
      .unsubscribe();

    if (this.isLoadingService.isLoading("getLogTelemFromTo")) return null;

    this.isLoadingService.setLoading("getLogTelemFromTo");
    this.store.dispatch(new IsLoadings(true));
    const params = new HttpParams().set("from", from).set("to", to);
    this.http
      .get(
        "//" +
          this.host.getHost() +
          "/api/v1/industry-allThresholdsUpdates-water",
        { params },
      )

      .subscribe(
        (log: Loggers) => {
          this.isLoadingService.dellLoading("getLogTelemFromTo");
          this.store.dispatch(new SetLogTelemActions(log, dimensionPressure));
          this.store.dispatch(new IsLoadings(false));
          log.data.forEach((e) => {
            if (e.devType === "vega_tm") {
              e.devType = VegaTmUtil.getCaptionVegaTmBySerNum(e.serNum);
            }
          });
        },
        (error) => {
          this.isLoadingService.dellLoading("getLogTelemFromTo");
          this.store.dispatch(new IsLoadings(false));
        },
      );
  }

  public deleteMarsh(data) {
    this.store.dispatch(new IsLoadings(true));
    const params = new HttpParams().set("id", data);

    this.http
      .delete<any>("//" + this.host.getHost() + "/api/v1/route/delete", {
        params,
      })
      .subscribe(
        (responce: any) => {
          this.store.dispatch(new IsLoadings(false));
          //  this.store.dispatch(new DellCheckedAllSubtractedScriptsAction());

          this.toaster.show(
            "success",
            "",
            this.translateService.instant("marsh.toster"),
          );
        },
        (error) => {
          this.store.dispatch(new IsLoadings(false));
          console.log(error);
        },
      );
  }
  public drop(e) {
    this.store.dispatch(new LoadCurrentDeviceAction(e));
    this.store.dispatch(new IsLoadings(false));
  }
}

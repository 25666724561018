import { Component, Input, OnInit } from "@angular/core";
import { Messages } from "@interfaces";
import { Observable } from "rxjs";
import { AppState } from "@state";
import { Store } from "@ngrx/store";

@Component({
  selector: "app-message",
  templateUrl: "./message.component.html",
  styleUrls: ["./message.component.sass"],
})
export class MessageComponent implements OnInit {
  public messages: Observable<Messages>;
  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.messages = this.store.select("messageBlock");
  }

  public getTypeMessage(
    type: "error" | "success",
  ): { "dx-toast-error": true } | { "dx-toast-success": true } {
    if (type === "error") return { "dx-toast-error": true };
    else if (type === "success") return { "dx-toast-success": true };
  }
}

import {Action} from "@ngrx/store";

export enum LogTelemActionTypes {
  SetLogTelem = '[SetLogTelem] Set LogTelem',
}

export class SetLogTelemActions implements Action {
  readonly type = LogTelemActionTypes.SetLogTelem;
  constructor(public payload: any, public dimensionPressure: string) {}
}

export type LogTelemAction = SetLogTelemActions;

import { Injectable } from "@angular/core";
import { AppState } from "@state";
import { Observable } from "rxjs";
import { GlobalApp } from "@interfaces";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import {
  SetCityAction,
  SetDistrictAction,
  SetRegionAction,
} from "../../redux/actions/global.actions";

@Injectable({
  providedIn: "root",
})
export class TranslateDeviceExeleService {
  public pageGlobal: Observable<GlobalApp>;
  constructor(
    private store: Store<AppState>,
    private translateService: TranslateService,
  ) {}

  customizeExcelCell(options) {
    if (options.value === "radmirtech") {
      options.value = this.translateService.instant("radmirtech");
    }
    if (options.value === "Radmirtech") {
      options.value = this.translateService.instant("Radmirtech");
    }
    if (options.value === "sensus" || options.value === "Sensus") {
      options.value = this.translateService.instant("sensus");
    }
    if (options.value === "VodService") {
      options.value = this.translateService.instant("VodService");
    }
    if (options.value === "rapid_ap") {
      options.value = this.translateService.instant("rapid_ap");
    }
    if (options.value === "grempis") {
      options.value = this.translateService.instant("grempis");
    }
    if (options.value === "tandem") {
      options.value = this.translateService.instant("tandem");
    }
    if (options.value === "ukrgaztech") {
      options.value = this.translateService.instant("ukrgaztech");
    }
    if (options.value === "ukrintech") {
      options.value = this.translateService.instant("ukrintech");
    }
    if (options.value === "izodrom") {
      options.value = this.translateService.instant("izodrom");
    }
    if (options.value === "gammaflou") {
      options.value = this.translateService.instant("gammaflou");
    }
    if (options.value === "vymiruvalnitechnologii") {
      options.value = this.translateService.instant("vymiruvalnitechnologii");
    }
    if (options.value === "allIzmTech") {
      options.value = this.translateService.instant("allIzmTech");
    }
    if (options.value === "00") {
      options.value = this.translateService.instant("00");
    }
    if (options.value === "epl") {
      options.value = this.translateService.instant("epl");
    }
    if (options.value === "Epl") {
      options.value = this.translateService.instant("Epl");
    }

    if (options.value === "kplg_1_01") {
      options.value = this.translateService.instant("kplg_1_01");
    }
    if (options.value === "kplg_1_02r") {
      options.value = this.translateService.instant("kplg_1_02r");
    }
    if (options.value === "kplg_1_02rv" || options.value === "kplg_1_02rv") {
      options.value = this.translateService.instant("sensus");
    }
    if (options.value === "kplg_2_01r") {
      options.value = this.translateService.instant("kplg_2_01r");
    }
    if (options.value === "vega_1_01") {
      options.value = this.translateService.instant("vega_1_01");
    }
    if (options.value === "vega_1_01vch") {
      options.value = this.translateService.instant("vega_1_01vch");
    }
    if (options.value === "vega_1_02") {
      options.value = this.translateService.instant("vega_1_02");
    }
    if (options.value === "vega_2_01") {
      options.value = this.translateService.instant("vega_2_01");
    }
    if (options.value === "vega_2_01vch") {
      options.value = this.translateService.instant("vega_2_01vch");
    }
    if (options.value === "vega_2_02") {
      options.value = this.translateService.instant("vega_2_02");
    }
    if (options.value === "kvr_1_01") {
      options.value = this.translateService.instant("kvr_1_01");
    }
    if (options.value === "kvr_1_02") {
      options.value = this.translateService.instant("kvr_1_02");
    }

    if (options.value === "kvt_1_01") {
      options.value = this.translateService.instant("kvt_1_01");
    }
    if (options.value === "kvt_1_02") {
      options.value = this.translateService.instant("kvt_1_02");
    }
    if (options.value === "vega_1_01n") {
      options.value = this.translateService.instant("vega_1_01n");
    }
    if (options.value === "vega_2_01n") {
      options.value = this.translateService.instant("vega_2_01n");
    }
    if (options.value === "kvr_1_01n") {
      options.value = this.translateService.instant("kvr_1_01n");
    }
    if (options.value === "kvr_1_02n") {
      options.value = this.translateService.instant("kvr_1_02n");
    }
    if (options.value === "kvt_1_01n") {
      options.value = this.translateService.instant("kvt_1_01n");
    }
    if (options.value === "kvt_1_02n") {
      options.value = this.translateService.instant("kvt_1_02n");
    }
    if (options.value === "kvu_1_01n") {
      options.value = this.translateService.instant("kvu_1_01n");
    }
    if (options.value === "kvu_1_02n") {
      options.value = this.translateService.instant("kvu_1_02n");
    }

    if (options.value === "tkb_1") {
      options.value = this.translateService.instant("tkb_1");
    }
    if (options.value === "tkb") {
      options.value = this.translateService.instant("tkb");
    }
    if (options.value === "vega_1_01_nvch") {
      options.value = this.translateService.instant("vega_1_01_nvch");
    }
    if (options.value === "vega_2_01_nvch") {
      options.value = this.translateService.instant("vega_2_01_nvch");
    }
    if (options.value === "vega_tsg") {
      options.value = this.translateService.instant("vega_tsg");
    }
    if (options.value === "vega_tsv") {
      options.value = this.translateService.instant("vega_tsv");
    }
    if (options.value === "vega_tsd") {
      options.value = this.translateService.instant("vega_tsd");
    }
    if (options.value === "vega_td") {
      options.value = this.translateService.instant("vega_td");
    }
    if (options.value === "iperl") {
      options.value = this.translateService.instant("iperl");
    }
    if (options.value === "vega_tm") {
      options.value = this.translateService.instant("vega_tm");
    }
    if (options.value === "vega_tmkb") {
      options.value = this.translateService.instant("vega_tmkb");
    }
    if (options.value === "vega_di_gsm") {
      options.value = this.translateService.instant("vega_di_gsm");
    }
    if (options.value === "vega_di_lora") {
      options.value = this.translateService.instant("vega_di_lora");
    }
    if (options.value === "radio_modul") {
      options.value = this.translateService.instant("radio_modul");
    }
    if (options.value === "000") {
      options.value = this.translateService.instant("000");
    }
    if (options.value === "epl_water") {
      options.value = this.translateService.instant("epl_water");
    }
    if (options.value === "rt_clever") {
      options.value = this.translateService.instant("rt_clever");
    }
  }

  translateAddress() {
    this.store.dispatch(
      new SetCityAction({
        kharkov: [
          this.translateService.instant("ukraine.city.kharkov"),
          this.translateService.instant("ukraine.city.kh.lozovaya"),
          this.translateService.instant("ukraine.city.kh.kupyansk"),
          this.translateService.instant("ukraine.city.kh.izum"),
          this.translateService.instant("ukraine.city.kh.balakley"),
          this.translateService.instant("ukraine.city.kh.borvenkovo"),
          this.translateService.instant("ukraine.city.kh.bohoduhov"),
          this.translateService.instant("ukraine.city.kh.valki"),
          this.translateService.instant("ukraine.city.kh.volchansk"),
          this.translateService.instant("ukraine.city.kh.dergachi"),
          this.translateService.instant("ukraine.city.kh.zmiev"),
          this.translateService.instant("ukraine.city.kh.krasnograd"),
          this.translateService.instant("ukraine.city.kh.lubotin"),
          this.translateService.instant("ukraine.city.kh.posbelyevka"),
          this.translateService.instant("ukraine.city.kh.merefa"),
          this.translateService.instant("ukraine.city.kh.pervomaisk"),
          this.translateService.instant("ukraine.city.kh.pivdennoe"),
          this.translateService.instant("ukraine.city.kh.chuhuev"),
        ],

        odessa: [
          this.translateService.instant("ukraine.city.odessa.odesa"),
          this.translateService.instant("ukraine.city.odessa.ananev"),
          this.translateService.instant("ukraine.city.odessa.arciz"),
          this.translateService.instant("ukraine.city.odessa.balta"),
          this.translateService.instant(
            "ukraine.city.odessa.belgoroddnestrovskiy",
          ),
          this.translateService.instant("ukraine.city.odessa.belyevka"),
          this.translateService.instant("ukraine.city.odessa.berezovka"),
          this.translateService.instant("ukraine.city.odessa.bolgrad"),
          this.translateService.instant("ukraine.city.odessa.vilkovo"),
          this.translateService.instant("ukraine.city.odessa.izmail"),
          this.translateService.instant("ukraine.city.odessa.kiliy"),
          this.translateService.instant("ukraine.city.odessa.kodima"),
          this.translateService.instant("ukraine.city.odessa.podolsk"),
          this.translateService.instant("ukraine.city.odessa.razdelnay"),
          this.translateService.instant("ukraine.city.odessa.reni"),
          this.translateService.instant("ukraine.city.odessa.tatarburnari"),
          this.translateService.instant("ukraine.city.odessa.teplodar"),
          this.translateService.instant("ukraine.city.odessa.chernomorsk"),
          this.translateService.instant("ukraine.city.odessa.yznoe"),
        ],
        donetsk: [
          this.translateService.instant("ukraine.city.dn.kramatorsk"),
          this.translateService.instant("ukraine.city.denetsk.denetsk"),
          this.translateService.instant("ukraine.city.denetsk.avdeevka"),
          this.translateService.instant("ukraine.city.denetsk.amvrosievka"),
          this.translateService.instant("ukraine.city.denetsk.artemovo"),
          this.translateService.instant("ukraine.city.denetsk.artemovsk"),
          this.translateService.instant("ukraine.city.denetsk.bezlikoe"),
          this.translateService.instant("ukraine.city.denetsk.belozerskoe"),
          this.translateService.instant("ukraine.city.denetsk.volnovaha"),
          this.translateService.instant("ukraine.city.denetsk.gorlovka"),
          this.translateService.instant("ukraine.city.denetsk.gornyk"),
          this.translateService.instant("ukraine.city.denetsk.debalcevo"),
          this.translateService.instant("ukraine.city.denetsk.dzerzinsk"),
          this.translateService.instant("ukraine.city.denetsk.dimitrov"),
          this.translateService.instant("ukraine.city.denetsk.dobropole"),
          this.translateService.instant("ukraine.city.denetsk.dokuchaevsk"),
          this.translateService.instant("ukraine.city.denetsk.druzkovka"),
          this.translateService.instant("ukraine.city.denetsk.enakievo"),
          this.translateService.instant("ukraine.city.denetsk.zdanovka"),
          this.translateService.instant("ukraine.city.denetsk.zugres"),
          this.translateService.instant("ukraine.city.denetsk.ilovaysk"),
          this.translateService.instant("ukraine.city.denetsk.kirovskoe"),
          this.translateService.instant("ukraine.city.denetsk.komsomolskoe"),
          this.translateService.instant("ukraine.city.denetsk.konstantinovka"),
          this.translateService.instant("ukraine.city.denetsk.krasnoarmeysk"),
          this.translateService.instant("ukraine.city.denetsk.krasnogradovka"),
          this.translateService.instant("ukraine.city.denetsk.krasniyliman"),
          this.translateService.instant("ukraine.city.denetsk.makeevka"),
          this.translateService.instant("ukraine.city.denetsk.mariupol"),
          this.translateService.instant("ukraine.city.denetsk.maryinka"),
          this.translateService.instant("ukraine.city.denetsk.mospino"),
          this.translateService.instant("ukraine.city.denetsk.nikolaevka"),
          this.translateService.instant("ukraine.city.denetsk.novoazovsk"),
          this.translateService.instant("ukraine.city.denetsk.novgorodovka"),
          this.translateService.instant("ukraine.city.denetsk.rodinskoe"),
          this.translateService.instant("ukraine.city.denetsk.svetlodarsk"),
          this.translateService.instant("ukraine.city.denetsk.svytogorsk"),
          this.translateService.instant("ukraine.city.denetsk.seversk"),
          this.translateService.instant("ukraine.city.denetsk.selidovo"),
          this.translateService.instant("ukraine.city.denetsk.slavynsk"),
          this.translateService.instant("ukraine.city.denetsk.snegnoe"),
          this.translateService.instant("ukraine.city.denetsk.soledar"),
          this.translateService.instant("ukraine.city.denetsk.torez"),
          this.translateService.instant("ukraine.city.denetsk.uglegorsk"),
          this.translateService.instant("ukraine.city.denetsk.ugledar"),
          this.translateService.instant("ukraine.city.denetsk.ukrainsk"),
          this.translateService.instant("ukraine.city.denetsk.harcizk"),
          this.translateService.instant("ukraine.city.denetsk.chasovyr"),
          this.translateService.instant("ukraine.city.denetsk.shahtersk"),
          this.translateService.instant("ukraine.city.denetsk.ynokommunarovsk"),
          this.translateService.instant("ukraine.city.denetsk.ysinovatay"),
        ],
        lvov: [
          this.translateService.instant("ukraine.city.lvov.lvovs"),
          this.translateService.instant("ukraine.city.lvov.belz"),
          this.translateService.instant("ukraine.city.lvov.bobrka"),
          this.translateService.instant("ukraine.city.lvov.borislav"),
          this.translateService.instant("ukraine.city.lvov.brodi"),
          this.translateService.instant("ukraine.city.lvov.busk"),
          this.translateService.instant("ukraine.city.lvov.velikiemosti"),
          this.translateService.instant("ukraine.city.lvov.vinniki"),
          this.translateService.instant("ukraine.city.lvov.glinyni"),
          this.translateService.instant("ukraine.city.lvov.gorodoks"),
          this.translateService.instant("ukraine.city.lvov.dobromil"),
          this.translateService.instant("ukraine.city.lvov.drogobich"),
          this.translateService.instant("ukraine.city.lvov.dublyni"),
          this.translateService.instant("ukraine.city.lvov.gidachov"),
          this.translateService.instant("ukraine.city.lvov.golkva"),
          this.translateService.instant("ukraine.city.lvov.zolochev"),
          this.translateService.instant("ukraine.city.lvov.kamenkabugskay"),
          this.translateService.instant("ukraine.city.lvov.komarino"),
          this.translateService.instant("ukraine.city.lvov.kulikov"),
          this.translateService.instant("ukraine.city.lvov.moshin"),
          this.translateService.instant("ukraine.city.lvov.mostiska"),
          this.translateService.instant("ukraine.city.lvov.nikolaevs"),
          this.translateService.instant("ukraine.city.lvov.novoyvorovsk"),
          this.translateService.instant("ukraine.city.lvov.noviykalinov"),
          this.translateService.instant("ukraine.city.lvov.noviyrozdol"),
          this.translateService.instant("ukraine.city.lvov.olesko"),
          this.translateService.instant("ukraine.city.lvov.peremislyni"),
          this.translateService.instant("ukraine.city.lvov.pustomiti"),
          this.translateService.instant("ukraine.city.lvov.ravarusskay"),
          this.translateService.instant("ukraine.city.lvov.radehov"),
          this.translateService.instant("ukraine.city.lvov.rudki"),
          this.translateService.instant("ukraine.city.lvov.skole"),
          this.translateService.instant("ukraine.city.lvov.sokal"),
          this.translateService.instant("ukraine.city.lvov.sosnovka"),
          this.translateService.instant("ukraine.city.lvov.stariysambor"),
          this.translateService.instant("ukraine.city.lvov.stebnik"),
          this.translateService.instant("ukraine.city.lvov.striy"),
          this.translateService.instant("ukraine.city.lvov.sudovayvishny"),
          this.translateService.instant("ukraine.city.lvov.truskavec"),
          this.translateService.instant("ukraine.city.lvov.turka"),
          this.translateService.instant("ukraine.city.lvov.ugnev"),
          this.translateService.instant("ukraine.city.lvov.hodorov"),
          this.translateService.instant("ukraine.city.lvov.chervonograd"),
          this.translateService.instant("ukraine.city.lvov.yvorov"),
        ],
        herson: [
          this.translateService.instant("ukraine.city.herson.hersons"),
          this.translateService.instant("ukraine.city.herson.aleski"),
          this.translateService.instant("ukraine.city.herson.berislav"),
          this.translateService.instant("ukraine.city.herson.genichesk"),
          this.translateService.instant("ukraine.city.herson.golaypristan"),
          this.translateService.instant("ukraine.city.herson.kahovka"),
          this.translateService.instant("ukraine.city.herson.novaykahovka"),
          this.translateService.instant("ukraine.city.herson.skadovsk"),
          this.translateService.instant("ukraine.city.herson.tavriysk"),
        ],
        chernigov: [
          this.translateService.instant("ukraine.city.chernigiv.chernihov"),
          this.translateService.instant("ukraine.city.chernigiv.baturin"),
          this.translateService.instant("ukraine.city.chernigiv.bahmach"),
          this.translateService.instant("ukraine.city.chernigiv.bobrovica"),
          this.translateService.instant("ukraine.city.chernigiv.borzna"),
          this.translateService.instant("ukraine.city.chernigiv.gorodny"),
          this.translateService.instant("ukraine.city.chernigiv.ichny"),
          this.translateService.instant("ukraine.city.chernigiv.korykovka"),
          this.translateService.instant("ukraine.city.chernigiv.mena"),
          this.translateService.instant("ukraine.city.chernigiv.negin"),
          this.translateService.instant(
            "ukraine.city.chernigiv.novgorodseverskiy",
          ),
          this.translateService.instant("ukraine.city.chernigiv.nosovka"),
          this.translateService.instant("ukraine.city.chernigiv.oster"),
          this.translateService.instant("ukraine.city.chernigiv.priluki"),
          this.translateService.instant("ukraine.city.chernigiv.semenovka"),
          this.translateService.instant("ukraine.city.chernigiv.snovsk"),
        ],
        kiiv: [
          this.translateService.instant("ukraine.city.kiiv.bilacerkva"),
          this.translateService.instant("ukraine.city.kiiv.kiiv"),
          this.translateService.instant("ukraine.city.kiiv.berezan"),
          this.translateService.instant("ukraine.city.kiiv.boguslav"),
          this.translateService.instant("ukraine.city.kiiv.borislpol"),
          this.translateService.instant("ukraine.city.kiiv.boyrka"),
          this.translateService.instant("ukraine.city.kiiv.brovari"),
          this.translateService.instant("ukraine.city.kiiv.bucha"),
          this.translateService.instant("ukraine.city.kiiv.vasilkov"),
          this.translateService.instant("ukraine.city.kiiv.vishnevoe"),
          this.translateService.instant("ukraine.city.kiiv.vishgorod"),
          this.translateService.instant("ukraine.city.kiiv.irpen"),
          this.translateService.instant("ukraine.city.kiiv.kagarlik"),
          this.translateService.instant("ukraine.city.kiiv.mironovka"),
          this.translateService.instant("ukraine.city.kiiv.obuhov"),
          this.translateService.instant(
            "ukraine.city.kiiv.pereyslovhmelnickiy",
          ),
          this.translateService.instant("ukraine.city.kiiv.rgishev"),
          this.translateService.instant("ukraine.city.kiiv.skvira"),
          this.translateService.instant("ukraine.city.kiiv.slavutich"),
          this.translateService.instant("ukraine.city.kiiv.tarascha"),
          this.translateService.instant("ukraine.city.kiiv.tetiev"),
          this.translateService.instant("ukraine.city.kiiv.uzin"),
          this.translateService.instant("ukraine.city.kiiv.ukrainka"),
          this.translateService.instant("ukraine.city.kiiv.fastov"),
          this.translateService.instant("ukraine.city.kiiv.ygotin"),
        ],
        dnepropetrovskaya: [
          this.translateService.instant(
            "ukraine.city.dnepropetrovskaya.nikopol",
          ),
          this.translateService.instant(
            "ukraine.city.dnepropetrovskaya.dnepropetrovsk",
          ),
          this.translateService.instant(
            "ukraine.city.dnepropetrovskaya.apostolovo",
          ),
          this.translateService.instant(
            "ukraine.city.dnepropetrovskaya.verhnedneprovsk",
          ),
          this.translateService.instant(
            "ukraine.city.dnepropetrovskaya.verhovchevo",
          ),
          this.translateService.instant(
            "ukraine.city.dnepropetrovskaya.volnogorsk",
          ),
          this.translateService.instant(
            "ukraine.city.dnepropetrovskaya.dneprodzerzinsk",
          ),
          this.translateService.instant(
            "ukraine.city.dnepropetrovskaya.zeltievodi",
          ),
          this.translateService.instant(
            "ukraine.city.dnepropetrovskaya.zelenodolsk",
          ),
          this.translateService.instant(
            "ukraine.city.dnepropetrovskaya.krivoyrog",
          ),
          this.translateService.instant(
            "ukraine.city.dnepropetrovskaya.marganec",
          ),
          this.translateService.instant(
            "ukraine.city.dnepropetrovskaya.novomoskovsk",
          ),
          this.translateService.instant(
            "ukraine.city.dnepropetrovskaya.ordgonikidze",
          ),
          this.translateService.instant(
            "ukraine.city.dnepropetrovskaya.pavlograd",
          ),
          this.translateService.instant(
            "ukraine.city.dnepropetrovskaya.pereschepino",
          ),
          this.translateService.instant(
            "ukraine.city.dnepropetrovskaya.pershotravensk",
          ),
          this.translateService.instant(
            "ukraine.city.dnepropetrovskaya.podgorodnoe",
          ),
          this.translateService.instant(
            "ukraine.city.dnepropetrovskaya.pytihatki",
          ),
          this.translateService.instant(
            "ukraine.city.dnepropetrovskaya.sinelnikovo",
          ),
          this.translateService.instant(
            "ukraine.city.dnepropetrovskaya.ternovka",
          ),
        ],
        poltavska: [
          this.translateService.instant("ukraine.city.poltava.poltavas"),
          this.translateService.instant("ukraine.city.poltava.gadych"),
          this.translateService.instant("ukraine.city.poltava.globino"),
          this.translateService.instant("ukraine.city.poltava.gorishnieplavni"),
          this.translateService.instant("ukraine.city.poltava.grebenka"),
          this.translateService.instant("ukraine.city.poltava.zavodskoe"),
          this.translateService.instant("ukraine.city.poltava.zenkov"),
          this.translateService.instant("ukraine.city.poltava.karlovka"),
          this.translateService.instant("ukraine.city.poltava.kobelyki"),
          this.translateService.instant("ukraine.city.poltava.kremenchug"),
          this.translateService.instant("ukraine.city.poltava.pgtsemenovka"),
          this.translateService.instant("ukraine.city.poltava.lohvica"),
          this.translateService.instant("ukraine.city.poltava.lubni"),
          this.translateService.instant("ukraine.city.poltava.mirgorod"),
          this.translateService.instant("ukraine.city.poltava.pirytin"),
          this.translateService.instant("ukraine.city.poltava.horol"),
        ],
        zitomir: [
          this.translateService.instant("ukraine.city.zitomir.zitomir"),
          this.translateService.instant("ukraine.city.zitomir.andrushovka"),
          this.translateService.instant("ukraine.city.zitomir.baranovka"),
          this.translateService.instant("ukraine.city.zitomir.berdichev"),
          this.translateService.instant("ukraine.city.zitomir.korosten"),
          this.translateService.instant("ukraine.city.zitomir.korostishev"),
          this.translateService.instant("ukraine.city.zitomir.malin"),
          this.translateService.instant(
            "ukraine.city.zitomir.novogradvolinskiy",
          ),
          this.translateService.instant("ukraine.city.zitomir.ovruch"),
          this.translateService.instant("ukraine.city.zitomir.olevsk"),
          this.translateService.instant("ukraine.city.zitomir.radomishel"),
          this.translateService.instant("ukraine.city.zitomir.chudnov"),
        ],
        summi: [
          this.translateService.instant("ukraine.city.summi.sumi"),
          this.translateService.instant("ukraine.city.summi.ahtirka"),
          this.translateService.instant("ukraine.city.summi.belopole"),
          this.translateService.instant("ukraine.city.summi.burin"),
          this.translateService.instant("ukraine.city.summi.vorogba"),
          this.translateService.instant("ukraine.city.summi.gluhov"),
          this.translateService.instant("ukraine.city.summi.druzba"),
          this.translateService.instant("ukraine.city.summi.konotop"),
          this.translateService.instant("ukraine.city.summi.krolevec"),
          this.translateService.instant("ukraine.city.summi.lebedin"),
          this.translateService.instant("ukraine.city.summi.putivl"),
          this.translateService.instant("ukraine.city.summi.romni"),
          this.translateService.instant("ukraine.city.summi.seredinabuda"),
          this.translateService.instant("ukraine.city.summi.trostynec"),
          this.translateService.instant("ukraine.city.summi.shostka"),
        ],
        cherkasi: [
          this.translateService.instant("ukraine.city.cherkaska.cherkassi"),
          this.translateService.instant("ukraine.city.cherkaska.vatutino"),
          this.translateService.instant("ukraine.city.cherkaska.gorodishe"),
          this.translateService.instant("ukraine.city.cherkaska.sgahov"),
          this.translateService.instant("ukraine.city.cherkaska.zvenigorodka"),
          this.translateService.instant("ukraine.city.cherkaska.zolotonosha"),
          this.translateService.instant("ukraine.city.cherkaska.kamenka"),
          this.translateService.instant("ukraine.city.cherkaska.kanev"),
          this.translateService.instant(
            "ukraine.city.cherkaska.korsunshevchenkovskiy",
          ),
          this.translateService.instant("ukraine.city.cherkaska.monastirishe"),
          this.translateService.instant("ukraine.city.cherkaska.smela"),
          this.translateService.instant("ukraine.city.cherkaska.talnoe"),
          this.translateService.instant("ukraine.city.cherkaska.uman"),
          this.translateService.instant("ukraine.city.cherkaska.hristinovka"),
          this.translateService.instant("ukraine.city.cherkaska.chigirin"),
          this.translateService.instant("ukraine.city.cherkaska.shpola"),
        ],
        vinnichkay: [
          this.translateService.instant("ukraine.city.vinnichkay.vinnica"),
          this.translateService.instant("ukraine.city.vinnichkay.bar"),
          this.translateService.instant("ukraine.city.vinnichkay.bershad"),
          this.translateService.instant("ukraine.city.vinnichkay.gaysin"),
          this.translateService.instant("ukraine.city.vinnichkay.gnivan"),
          this.translateService.instant("ukraine.city.vinnichkay.gmerinka"),
          this.translateService.instant("ukraine.city.vinnichkay.ilinchi"),
          this.translateService.instant("ukraine.city.vinnichkay.kazatin"),
          this.translateService.instant("ukraine.city.vinnichkay.kalinovka"),
          this.translateService.instant("ukraine.city.vinnichkay.ladizin"),
          this.translateService.instant("ukraine.city.vinnichkay.lipovec"),
          this.translateService.instant(
            "ukraine.city.vinnichkay.mogilevpodolskiy",
          ),
          this.translateService.instant("ukraine.city.vinnichkay.nemirov"),
          this.translateService.instant("ukraine.city.vinnichkay.pogrebische"),
          this.translateService.instant("ukraine.city.vinnichkay.tulchin"),
          this.translateService.instant("ukraine.city.vinnichkay.hmelnik"),
          this.translateService.instant("ukraine.city.vinnichkay.shargorod"),
          this.translateService.instant("ukraine.city.vinnichkay.ympol"),
        ],
        volinskay: [
          this.translateService.instant("ukraine.city.volinskay.lutsk"),
          this.translateService.instant("ukraine.city.volinskay.berestechko"),
          this.translateService.instant(
            "ukraine.city.volinskay.vladimirvolinskiy",
          ),
          this.translateService.instant("ukraine.city.volinskay.gorohov"),
          this.translateService.instant(
            "ukraine.city.volinskay.kamenkashirskiy",
          ),
          this.translateService.instant("ukraine.city.volinskay.kiverci"),
          this.translateService.instant("ukraine.city.volinskay.kovel"),
          this.translateService.instant("ukraine.city.volinskay.lubomol"),
          this.translateService.instant("ukraine.city.volinskay.novovolinsk"),
          this.translateService.instant("ukraine.city.volinskay.rozise"),
          this.translateService.instant("ukraine.city.volinskay.ustilug"),
        ],
        zakarpatskay: [
          this.translateService.instant("ukraine.city.zakarpatskay.uzgorod"),
          this.translateService.instant("ukraine.city.zakarpatskay.beregovo"),
          this.translateService.instant("ukraine.city.zakarpatskay.vinogradov"),
          this.translateService.instant("ukraine.city.zakarpatskay.irshava"),
          this.translateService.instant("ukraine.city.zakarpatskay.mukachevo"),
          this.translateService.instant("ukraine.city.zakarpatskay.perechin"),
          this.translateService.instant("ukraine.city.zakarpatskay.rahov"),
          this.translateService.instant("ukraine.city.zakarpatskay.svalyva"),
          this.translateService.instant("ukraine.city.zakarpatskay.tychev"),
          this.translateService.instant("ukraine.city.zakarpatskay.hust"),
          this.translateService.instant("ukraine.city.zakarpatskay.chop"),
        ],
        zaporogskay: [
          this.translateService.instant("ukraine.city.zaporogskay.zaporozie"),
          this.translateService.instant("ukraine.city.zaporogskay.berdynsk"),
          this.translateService.instant("ukraine.city.zaporogskay.vasilievka"),
          this.translateService.instant("ukraine.city.zaporogskay.volnynsk"),
          this.translateService.instant("ukraine.city.zaporogskay.gulyipole"),
          this.translateService.instant(
            "ukraine.city.zaporogskay.dneproprudnoe",
          ),
          this.translateService.instant(
            "ukraine.city.zaporogskay.kamenkadneprovskay",
          ),
          this.translateService.instant("ukraine.city.zaporogskay.melitopol"),
          this.translateService.instant("ukraine.city.zaporogskay.molochansk"),
          this.translateService.instant("ukraine.city.zaporogskay.orehov"),
          this.translateService.instant("ukraine.city.zaporogskay.pologi"),
          this.translateService.instant("ukraine.city.zaporogskay.primorsk"),
          this.translateService.instant("ukraine.city.zaporogskay.tokmak"),
          this.translateService.instant("ukraine.city.zaporogskay.energodar"),
        ],
        ivanofrankovskay: [
          this.translateService.instant(
            "ukraine.city.ivanofrankovskay.ivanofrankovsk",
          ),
          this.translateService.instant(
            "ukraine.city.ivanofrankovskay.bolehov",
          ),
          this.translateService.instant(
            "ukraine.city.ivanofrankovskay.burstin",
          ),
          this.translateService.instant("ukraine.city.ivanofrankovskay.galich"),
          this.translateService.instant(
            "ukraine.city.ivanofrankovskay.gorodenka",
          ),
          this.translateService.instant("ukraine.city.ivanofrankovskay.kalush"),
          this.translateService.instant(
            "ukraine.city.ivanofrankovskay.kolomiy",
          ),
          this.translateService.instant("ukraine.city.ivanofrankovskay.kosov"),
          this.translateService.instant(
            "ukraine.city.ivanofrankovskay.nadvornay",
          ),
          this.translateService.instant(
            "ukraine.city.ivanofrankovskay.rogatin",
          ),
          this.translateService.instant("ukraine.city.ivanofrankovskay.snytin"),
          this.translateService.instant(
            "ukraine.city.ivanofrankovskay.stratin",
          ),
          this.translateService.instant(
            "ukraine.city.ivanofrankovskay.tlumach",
          ),
          this.translateService.instant(
            "ukraine.city.ivanofrankovskay.tismenica",
          ),
          this.translateService.instant(
            "ukraine.city.ivanofrankovskay.yremche",
          ),
        ],
        kirovogradskay: [
          this.translateService.instant(
            "ukraine.city.kirovogradskay.kirovograd",
          ),
          this.translateService.instant(
            "ukraine.city.kirovogradskay.aleksandriy",
          ),
          this.translateService.instant(
            "ukraine.city.kirovogradskay.blagoveshenskoe",
          ),
          this.translateService.instant("ukraine.city.kirovogradskay.bobrinec"),
          this.translateService.instant("ukraine.city.kirovogradskay.gayvoron"),
          this.translateService.instant(
            "ukraine.city.kirovogradskay.dolinskay",
          ),
          this.translateService.instant("ukraine.city.kirovogradskay.znamenka"),
          this.translateService.instant(
            "ukraine.city.kirovogradskay.malayviska",
          ),
          this.translateService.instant(
            "ukraine.city.kirovogradskay.novomirgorod",
          ),
          this.translateService.instant(
            "ukraine.city.kirovogradskay.novoukrainka",
          ),
          this.translateService.instant(
            "ukraine.city.kirovogradskay.pomoshnay",
          ),
          this.translateService.instant(
            "ukraine.city.kirovogradskay.svetlovodsk",
          ),
        ],
        luganskay: [
          this.translateService.instant("ukraine.city.luganskay.lugansk"),
          this.translateService.instant("ukraine.city.luganskay.aleksandrovsk"),
          this.translateService.instant("ukraine.city.luganskay.almaznay"),
          this.translateService.instant("ukraine.city.luganskay.alchevsk"),
          this.translateService.instant("ukraine.city.luganskay.antracit"),
          this.translateService.instant("ukraine.city.luganskay.artemovsks"),
          this.translateService.instant("ukraine.city.luganskay.birykovo"),
          this.translateService.instant("ukraine.city.luganskay.brynka"),
          this.translateService.instant("ukraine.city.luganskay.vahrushevo"),
          this.translateService.instant("ukraine.city.luganskay.gorskoe"),
          this.translateService.instant("ukraine.city.luganskay.zimogorie"),
          this.translateService.instant("ukraine.city.luganskay.zolotoe"),
          this.translateService.instant("ukraine.city.luganskay.zorinsk"),
          this.translateService.instant("ukraine.city.luganskay.irmino"),
          this.translateService.instant("ukraine.city.luganskay.kirovsk"),
          this.translateService.instant("ukraine.city.luganskay.krasnodon"),
          this.translateService.instant("ukraine.city.luganskay.krasniyluch"),
          this.translateService.instant("ukraine.city.luganskay.kremennay"),
          this.translateService.instant("ukraine.city.luganskay.lisichansk"),
          this.translateService.instant("ukraine.city.luganskay.lugutino"),
          this.translateService.instant("ukraine.city.luganskay.miusinsk"),
          this.translateService.instant(
            "ukraine.city.luganskay.molodogvardeysk",
          ),
          this.translateService.instant("ukraine.city.luganskay.novoaydar"),
          this.translateService.instant("ukraine.city.luganskay.novodrugesk"),
          this.translateService.instant("ukraine.city.luganskay.novopskov"),
          this.translateService.instant("ukraine.city.luganskay.pervomaysk"),
          this.translateService.instant("ukraine.city.luganskay.perevalsk"),
          this.translateService.instant("ukraine.city.luganskay.petrovskoe"),
          this.translateService.instant("ukraine.city.luganskay.popasnay"),
          this.translateService.instant("ukraine.city.luganskay.privolie"),
          this.translateService.instant("ukraine.city.luganskay.rovenki"),
          this.translateService.instant("ukraine.city.luganskay.rubeznoe"),
          this.translateService.instant("ukraine.city.luganskay.svatovo"),
          this.translateService.instant("ukraine.city.luganskay.sverdlovsk"),
          this.translateService.instant("ukraine.city.luganskay.severodoneck"),
          this.translateService.instant("ukraine.city.luganskay.starobelsk"),
          this.translateService.instant("ukraine.city.luganskay.stahanov"),
          this.translateService.instant("ukraine.city.luganskay.suhodolsk"),
          this.translateService.instant("ukraine.city.luganskay.schastie"),
          this.translateService.instant(
            "ukraine.city.luganskay.chervonopartizansk",
          ),
        ],
        nikolaevskay: [
          this.translateService.instant("ukraine.city.nikolaevskay.nikolaev"),
          this.translateService.instant("ukraine.city.nikolaevskay.bashtanka"),
          this.translateService.instant("ukraine.city.nikolaevskay.voznesenk"),
          this.translateService.instant("ukraine.city.nikolaevskay.novayodesa"),
          this.translateService.instant("ukraine.city.nikolaevskay.noviybug"),
          this.translateService.instant("ukraine.city.nikolaevskay.ochakov"),
          this.translateService.instant(
            "ukraine.city.nikolaevskay.pervomaisks",
          ),
          this.translateService.instant("ukraine.city.nikolaevskay.cnigirevka"),
          this.translateService.instant(
            "ukraine.city.nikolaevskay.uznoukrainsk",
          ),
        ],
        rovnenskay: [
          this.translateService.instant("ukraine.city.rovnenskay.rovno"),
          this.translateService.instant("ukraine.city.rovnenskay.berezno"),
          this.translateService.instant("ukraine.city.rovnenskay.varash"),
          this.translateService.instant("ukraine.city.rovnenskay.dubno"),
          this.translateService.instant("ukraine.city.rovnenskay.dubrovica"),
          this.translateService.instant("ukraine.city.rovnenskay.zdolbunov"),
          this.translateService.instant("ukraine.city.rovnenskay.korec"),
          this.translateService.instant("ukraine.city.rovnenskay.kostopol"),
          this.translateService.instant("ukraine.city.rovnenskay.ostrog"),
          this.translateService.instant("ukraine.city.rovnenskay.radivilov"),
          this.translateService.instant("ukraine.city.rovnenskay.sarni"),
        ],
        ternopolskay: [
          this.translateService.instant("ukraine.city.ternopolskay.ternopol"),
          this.translateService.instant("ukraine.city.ternopolskay.beregani"),
          this.translateService.instant("ukraine.city.ternopolskay.borshev"),
          this.translateService.instant("ukraine.city.ternopolskay.buchach"),
          this.translateService.instant("ukraine.city.ternopolskay.zaleshiki"),
          this.translateService.instant("ukraine.city.ternopolskay.zbarag"),
          this.translateService.instant("ukraine.city.ternopolskay.zborov"),
          this.translateService.instant("ukraine.city.ternopolskay.kopichinci"),
          this.translateService.instant("ukraine.city.ternopolskay.kremenec"),
          this.translateService.instant("ukraine.city.ternopolskay.lanovci"),
          this.translateService.instant(
            "ukraine.city.ternopolskay.monastiriska",
          ),
          this.translateService.instant("ukraine.city.ternopolskay.podgayci"),
          this.translateService.instant("ukraine.city.ternopolskay.pochaev"),
          this.translateService.instant("ukraine.city.ternopolskay.skalat"),
          this.translateService.instant("ukraine.city.ternopolskay.terebovly"),
          this.translateService.instant("ukraine.city.ternopolskay.horostkov"),
          this.translateService.instant("ukraine.city.ternopolskay.chortkov"),
          this.translateService.instant("ukraine.city.ternopolskay.shumsk"),
        ],
        hmelniskay: [
          this.translateService.instant("ukraine.city.hmelniskay.hmelnickiy"),
          this.translateService.instant("ukraine.city.hmelniskay.volochisk"),
          this.translateService.instant("ukraine.city.hmelniskay.gorodok"),
          this.translateService.instant("ukraine.city.hmelniskay.deragnay"),
          this.translateService.instant("ukraine.city.hmelniskay.dunaevci"),
          this.translateService.instant("ukraine.city.hmelniskay.izyslav"),
          this.translateService.instant(
            "ukraine.city.hmelniskay.kamenecpodolskiy",
          ),
          this.translateService.instant("ukraine.city.hmelniskay.krasilov"),
          this.translateService.instant("ukraine.city.hmelniskay.neteshin"),
          this.translateService.instant("ukraine.city.hmelniskay.polonnoe"),
          this.translateService.instant("ukraine.city.hmelniskay.slavuta"),
          this.translateService.instant(
            "ukraine.city.hmelniskay.staroconstantinnov",
          ),
          this.translateService.instant("ukraine.city.hmelniskay.shepetovka"),
        ],
        chernovickay: [
          this.translateService.instant("ukraine.city.chernovickay.chernovci"),
          this.translateService.instant("ukraine.city.chernovickay.vashkovci"),
          this.translateService.instant("ukraine.city.chernovickay.vignica"),
          this.translateService.instant("ukraine.city.chernovickay.gerca"),
          this.translateService.instant("ukraine.city.chernovickay.zastavna"),
          this.translateService.instant("ukraine.city.chernovickay.kickman"),
          this.translateService.instant(
            "ukraine.city.chernovickay.novodnestrovsk",
          ),
          this.translateService.instant("ukraine.city.chernovickay.novoselica"),
          this.translateService.instant("ukraine.city.chernovickay.sokiryni"),
          this.translateService.instant("ukraine.city.chernovickay.storoginec"),
          this.translateService.instant("ukraine.city.chernovickay.hotin"),
        ],
      }),
    );

    this.store.dispatch(
      new SetRegionAction([
        this.translateService.instant("ukraine.region.kiiv"),
        this.translateService.instant("ukraine.region.dnepropetrovskaya"),
        this.translateService.instant("ukraine.region.kharkov"),
        this.translateService.instant("ukraine.region.odessa"),
        this.translateService.instant("ukraine.region.lvov"),
        this.translateService.instant("ukraine.region.denetsk"),
        this.translateService.instant("ukraine.region.herson"),
        this.translateService.instant("ukraine.region.chernigiv"),
        this.translateService.instant("ukraine.region.poltava"),
        this.translateService.instant("ukraine.region.zitomir"),
        this.translateService.instant("ukraine.region.summi"),
        this.translateService.instant("ukraine.region.cherkaska"),
        this.translateService.instant("ukraine.region.vinnichkay"),
        this.translateService.instant("ukraine.region.volinskay"),
        this.translateService.instant("ukraine.region.zakarpatskay"),
        this.translateService.instant("ukraine.region.zaporogskay"),
        this.translateService.instant("ukraine.region.ivanofrankovskay"),
        this.translateService.instant("ukraine.region.kirovogradskay"),
        this.translateService.instant("ukraine.region.luganskay"),
        this.translateService.instant("ukraine.region.nikolaevskay"),
        this.translateService.instant("ukraine.region.rovnenskay"),
        this.translateService.instant("ukraine.region.ternopolskay"),
        this.translateService.instant("ukraine.region.hmelniskay"),
        this.translateService.instant("ukraine.region.chernovickay"),
      ]),
    );

    this.store.dispatch(
      new SetDistrictAction({
        kharkov: [
          this.translateService.instant("ukraine.district.kharkiv..kiev"),
          this.translateService.instant("ukraine.district.moskuov"),
          this.translateService.instant("ukraine.district.nemishla"),
          this.translateService.instant("ukraine.district.novobavarsk"),
          this.translateService.instant("ukraine.district.industrial"),
          this.translateService.instant("ukraine.district.osnovansk"),
          this.translateService.instant("ukraine.district.sobodsk"),
          this.translateService.instant("ukraine.district.kholodnogorsk"),
          this.translateService.instant("ukraine.district.shevchenkovsk"),
        ],
        izum: [
          this.translateService.instant("ukraine.district.kh.izum.veprhut"),
          this.translateService.instant("ukraine.district.kh.izum.nizgor"),
          this.translateService.instant("ukraine.district.kh.izum.1mrizn"),
          this.translateService.instant("ukraine.district.kh.izum.2mrzelt"),
          this.translateService.instant("ukraine.district.kh.izum.centr"),
          this.translateService.instant("ukraine.district.kh.izum.goncharovka"),
          this.translateService.instant("ukraine.district.kh.izum.neft"),
          this.translateService.instant("ukraine.district.kh.izum.povorotka"),
          this.translateService.instant("ukraine.district.kh.izum.peski"),
          this.translateService.instant("ukraine.district.kh.izum.plrevol"),
          this.translateService.instant("ukraine.district.kh.izum.ostrlokom"),
          this.translateService.instant("ukraine.district.kh.izum.zd"),
          this.translateService.instant("ukraine.district.kh.izum.iom3"),
          this.translateService.instant("ukraine.district.kh.izum.solnech"),
          this.translateService.instant("ukraine.district.kh.izum.verhpos"),
          this.translateService.instant("ukraine.district.kh.izum.gnedovka"),
        ],
        balakley: [this.translateService.instant("ukraine.district.all")],
        borvenkovo: [this.translateService.instant("ukraine.district.all")],
        bohoduhov: [this.translateService.instant("ukraine.district.all")],
        valki: [this.translateService.instant("ukraine.district.all")],
        volchansk: [this.translateService.instant("ukraine.district.all")],
        dergachi: [this.translateService.instant("ukraine.district.all")],
        zmiev: [this.translateService.instant("ukraine.district.all")],
        krasnograd: [this.translateService.instant("ukraine.district.all")],
        lubotin: [this.translateService.instant("ukraine.district.all")],
        posbelyevka: [this.translateService.instant("ukraine.district.all")],
        merefa: [this.translateService.instant("ukraine.district.all")],
        pervomaisk: [this.translateService.instant("ukraine.district.all")],
        pivdennoe: [this.translateService.instant("ukraine.district.all")],
        chuhuev: [this.translateService.instant("ukraine.district.all")],
        berezan: [this.translateService.instant("ukraine.district.all")],
        boguslav: [this.translateService.instant("ukraine.district.all")],
        borislpol: [this.translateService.instant("ukraine.district.all")],
        boyrka: [this.translateService.instant("ukraine.district.all")],
        brovari: [this.translateService.instant("ukraine.district.all")],
        bucha: [this.translateService.instant("ukraine.district.all")],
        vasilkov: [this.translateService.instant("ukraine.district.all")],
        vishnevoe: [this.translateService.instant("ukraine.district.all")],
        vishgorod: [this.translateService.instant("ukraine.district.all")],
        irpen: [this.translateService.instant("ukraine.district.all")],
        kagarlik: [this.translateService.instant("ukraine.district.all")],
        mironovka: [this.translateService.instant("ukraine.district.all")],
        obuhov: [this.translateService.instant("ukraine.district.all")],
        pereyslovhmelnickiy: [
          this.translateService.instant("ukraine.district.all"),
        ],
        rgishev: [this.translateService.instant("ukraine.district.all")],
        skvira: [this.translateService.instant("ukraine.district.all")],
        slavutich: [this.translateService.instant("ukraine.district.all")],
        tarascha: [this.translateService.instant("ukraine.district.all")],
        tetiev: [this.translateService.instant("ukraine.district.all")],
        uzin: [this.translateService.instant("ukraine.district.all")],
        ukrainka: [this.translateService.instant("ukraine.district.all")],
        fastov: [this.translateService.instant("ukraine.district.all")],
        ygotin: [this.translateService.instant("ukraine.district.all")],
        uzgorod: [this.translateService.instant("ukraine.district.all")],
        beregovo: [this.translateService.instant("ukraine.district.all")],
        vinogradov: [this.translateService.instant("ukraine.district.all")],
        irshava: [this.translateService.instant("ukraine.district.all")],
        mukachevo: [this.translateService.instant("ukraine.district.all")],
        perechin: [this.translateService.instant("ukraine.district.all")],
        rahov: [this.translateService.instant("ukraine.district.all")],
        svalyva: [this.translateService.instant("ukraine.district.all")],
        tychev: [this.translateService.instant("ukraine.district.all")],
        hust: [this.translateService.instant("ukraine.district.all")],
        chop: [this.translateService.instant("ukraine.district.all")],

        chernovci: [this.translateService.instant("ukraine.district.all")],
        vashkovci: [this.translateService.instant("ukraine.district.all")],
        vignica: [this.translateService.instant("ukraine.district.all")],
        gerca: [this.translateService.instant("ukraine.district.all")],
        zastavna: [this.translateService.instant("ukraine.district.all")],
        kickman: [this.translateService.instant("ukraine.district.all")],
        novodnestrovsk: [this.translateService.instant("ukraine.district.all")],
        novoselica: [this.translateService.instant("ukraine.district.all")],
        sokiryni: [this.translateService.instant("ukraine.district.all")],
        storoginec: [this.translateService.instant("ukraine.district.all")],
        hotin: [this.translateService.instant("ukraine.district.all")],

        zaporozie: [this.translateService.instant("ukraine.district.all")],
        berdynsk: [this.translateService.instant("ukraine.district.all")],
        vasilievka: [this.translateService.instant("ukraine.district.all")],
        volnynsk: [this.translateService.instant("ukraine.district.all")],
        gulyipole: [this.translateService.instant("ukraine.district.all")],
        dneproprudnoe: [this.translateService.instant("ukraine.district.all")],
        kamenkadneprovskay: [
          this.translateService.instant("ukraine.district.all"),
        ],
        melitopol: [this.translateService.instant("ukraine.district.all")],
        molochansk: [this.translateService.instant("ukraine.district.all")],
        orehov: [this.translateService.instant("ukraine.district.all")],
        pologi: [this.translateService.instant("ukraine.district.all")],
        primorsk: [this.translateService.instant("ukraine.district.all")],
        tokmak: [this.translateService.instant("ukraine.district.all")],
        energodar: [this.translateService.instant("ukraine.district.all")],

        ivanofrankovsk: [this.translateService.instant("ukraine.district.all")],
        bolehov: [this.translateService.instant("ukraine.district.all")],
        burstin: [this.translateService.instant("ukraine.district.all")],
        galich: [this.translateService.instant("ukraine.district.all")],
        gorodenka: [this.translateService.instant("ukraine.district.all")],
        kalush: [this.translateService.instant("ukraine.district.all")],
        kolomiy: [this.translateService.instant("ukraine.district.all")],
        kosov: [this.translateService.instant("ukraine.district.all")],
        nadvornay: [this.translateService.instant("ukraine.district.all")],
        rogatin: [this.translateService.instant("ukraine.district.all")],
        snytin: [this.translateService.instant("ukraine.district.all")],
        stratin: [this.translateService.instant("ukraine.district.all")],
        tlumach: [this.translateService.instant("ukraine.district.all")],
        tismenica: [this.translateService.instant("ukraine.district.all")],
        yremche: [this.translateService.instant("ukraine.district.all")],

        kirovograd: [this.translateService.instant("ukraine.district.all")],
        aleksandriy: [this.translateService.instant("ukraine.district.all")],
        blagoveshenskoe: [
          this.translateService.instant("ukraine.district.all"),
        ],
        bobrinec: [this.translateService.instant("ukraine.district.all")],
        gayvoron: [this.translateService.instant("ukraine.district.all")],
        dolinskay: [this.translateService.instant("ukraine.district.all")],
        znamenka: [this.translateService.instant("ukraine.district.all")],
        malayviska: [this.translateService.instant("ukraine.district.all")],
        novomirgorod: [this.translateService.instant("ukraine.district.all")],
        novoukrainka: [this.translateService.instant("ukraine.district.all")],
        pomoshnay: [this.translateService.instant("ukraine.district.all")],
        svetlovodsk: [this.translateService.instant("ukraine.district.all")],

        hersons: [this.translateService.instant("ukraine.district.all")],
        aleski: [this.translateService.instant("ukraine.district.all")],
        berislav: [this.translateService.instant("ukraine.district.all")],
        genichesk: [this.translateService.instant("ukraine.district.all")],
        golaypristan: [this.translateService.instant("ukraine.district.all")],
        kahovka: [this.translateService.instant("ukraine.district.all")],
        novaykahovka: [this.translateService.instant("ukraine.district.all")],
        skadovsk: [this.translateService.instant("ukraine.district.all")],
        tavriysk: [this.translateService.instant("ukraine.district.all")],

        nikolaev: [this.translateService.instant("ukraine.district.all")],
        bashtanka: [this.translateService.instant("ukraine.district.all")],
        voznesenk: [this.translateService.instant("ukraine.district.all")],
        novayodesa: [this.translateService.instant("ukraine.district.all")],
        noviybug: [this.translateService.instant("ukraine.district.all")],
        ochakov: [this.translateService.instant("ukraine.district.all")],
        pervomaisks: [this.translateService.instant("ukraine.district.all")],
        cnigirevka: [this.translateService.instant("ukraine.district.all")],
        uznoukrainsk: [this.translateService.instant("ukraine.district.all")],

        rovno: [this.translateService.instant("ukraine.district.all")],
        berezno: [this.translateService.instant("ukraine.district.all")],
        varash: [this.translateService.instant("ukraine.district.all")],
        dubno: [this.translateService.instant("ukraine.district.all")],
        dubrovica: [this.translateService.instant("ukraine.district.all")],
        zdolbunov: [this.translateService.instant("ukraine.district.all")],
        korec: [this.translateService.instant("ukraine.district.all")],
        kostopol: [this.translateService.instant("ukraine.district.all")],
        ostrog: [this.translateService.instant("ukraine.district.all")],
        radivilov: [this.translateService.instant("ukraine.district.all")],
        sarni: [this.translateService.instant("ukraine.district.all")],

        hmelnickiy: [this.translateService.instant("ukraine.district.all")],
        volochisk: [this.translateService.instant("ukraine.district.all")],
        gorodok: [this.translateService.instant("ukraine.district.all")],
        deragnay: [this.translateService.instant("ukraine.district.all")],
        dunaevci: [this.translateService.instant("ukraine.district.all")],
        izyslav: [this.translateService.instant("ukraine.district.all")],
        kamenecpodolskiy: [
          this.translateService.instant("ukraine.district.all"),
        ],
        krasilov: [this.translateService.instant("ukraine.district.all")],
        neteshin: [this.translateService.instant("ukraine.district.all")],
        polonnoe: [this.translateService.instant("ukraine.district.all")],
        slavuta: [this.translateService.instant("ukraine.district.all")],
        staroconstantinnov: [
          this.translateService.instant("ukraine.district.all"),
        ],
        shepetovka: [this.translateService.instant("ukraine.district.all")],

        cherkassi: [this.translateService.instant("ukraine.district.all")],
        vatutino: [this.translateService.instant("ukraine.district.all")],
        gorodishe: [this.translateService.instant("ukraine.district.all")],
        sgahov: [this.translateService.instant("ukraine.district.all")],
        zvenigorodka: [this.translateService.instant("ukraine.district.all")],
        zolotonosha: [this.translateService.instant("ukraine.district.all")],
        kamenka: [this.translateService.instant("ukraine.district.all")],
        kanev: [this.translateService.instant("ukraine.district.all")],
        korsunshevchenkovskiy: [
          this.translateService.instant("ukraine.district.all"),
        ],
        monastirishe: [this.translateService.instant("ukraine.district.all")],
        smela: [this.translateService.instant("ukraine.district.all")],
        talnoe: [this.translateService.instant("ukraine.district.all")],
        uman: [this.translateService.instant("ukraine.district.all")],
        hristinovka: [this.translateService.instant("ukraine.district.all")],
        chigirin: [this.translateService.instant("ukraine.district.all")],
        shpola: [this.translateService.instant("ukraine.district.all")],

        chernihov: [this.translateService.instant("ukraine.district.all")],
        baturin: [this.translateService.instant("ukraine.district.all")],
        bahmach: [this.translateService.instant("ukraine.district.all")],
        bobrovica: [this.translateService.instant("ukraine.district.all")],
        borzna: [this.translateService.instant("ukraine.district.all")],
        gorodny: [this.translateService.instant("ukraine.district.all")],
        ichny: [this.translateService.instant("ukraine.district.all")],
        korykovka: [this.translateService.instant("ukraine.district.all")],
        mena: [this.translateService.instant("ukraine.district.all")],
        negin: [this.translateService.instant("ukraine.district.all")],
        novgorodseverskiy: [
          this.translateService.instant("ukraine.district.all"),
        ],
        nosovka: [this.translateService.instant("ukraine.district.all")],
        oster: [this.translateService.instant("ukraine.district.all")],
        priluki: [this.translateService.instant("ukraine.district.all")],
        semenovka: [this.translateService.instant("ukraine.district.all")],
        snovsk: [this.translateService.instant("ukraine.district.all")],

        sumi: [this.translateService.instant("ukraine.district.all")],
        ahtirka: [this.translateService.instant("ukraine.district.all")],
        belopole: [this.translateService.instant("ukraine.district.all")],
        burin: [this.translateService.instant("ukraine.district.all")],
        vorogba: [this.translateService.instant("ukraine.district.all")],
        gluhov: [this.translateService.instant("ukraine.district.all")],
        druzba: [this.translateService.instant("ukraine.district.all")],
        konotop: [this.translateService.instant("ukraine.district.all")],
        krolevec: [this.translateService.instant("ukraine.district.all")],
        lebedin: [this.translateService.instant("ukraine.district.all")],
        putivl: [this.translateService.instant("ukraine.district.all")],
        romni: [this.translateService.instant("ukraine.district.all")],
        seredinabuda: [this.translateService.instant("ukraine.district.all")],
        trostynec: [this.translateService.instant("ukraine.district.all")],
        shostka: [this.translateService.instant("ukraine.district.all")],

        poltavas: [this.translateService.instant("ukraine.district.all")],
        gadych: [this.translateService.instant("ukraine.district.all")],
        globino: [this.translateService.instant("ukraine.district.all")],
        gorishnieplavni: [
          this.translateService.instant("ukraine.district.all"),
        ],
        grebenka: [this.translateService.instant("ukraine.district.all")],
        zavodskoe: [this.translateService.instant("ukraine.district.all")],
        zenkov: [this.translateService.instant("ukraine.district.all")],
        karlovka: [this.translateService.instant("ukraine.district.all")],
        kobelyki: [this.translateService.instant("ukraine.district.all")],
        kremenchug: [this.translateService.instant("ukraine.district.all")],
        lohvica: [this.translateService.instant("ukraine.district.all")],
        pgtsemenovka: [this.translateService.instant("ukraine.district.all")],
        lubni: [this.translateService.instant("ukraine.district.all")],
        mirgorod: [this.translateService.instant("ukraine.district.all")],
        pirytin: [this.translateService.instant("ukraine.district.all")],
        horol: [this.translateService.instant("ukraine.district.all")],

        ternopol: [this.translateService.instant("ukraine.district.all")],
        beregani: [this.translateService.instant("ukraine.district.all")],
        borshev: [this.translateService.instant("ukraine.district.all")],
        buchach: [this.translateService.instant("ukraine.district.all")],
        zaleshiki: [this.translateService.instant("ukraine.district.all")],
        zbarag: [this.translateService.instant("ukraine.district.all")],
        zborov: [this.translateService.instant("ukraine.district.all")],
        kopichinci: [this.translateService.instant("ukraine.district.all")],
        kremenec: [this.translateService.instant("ukraine.district.all")],
        lanovci: [this.translateService.instant("ukraine.district.all")],
        monastiriska: [this.translateService.instant("ukraine.district.all")],
        podgayci: [this.translateService.instant("ukraine.district.all")],
        pochaev: [this.translateService.instant("ukraine.district.all")],
        skalat: [this.translateService.instant("ukraine.district.all")],
        terebovly: [this.translateService.instant("ukraine.district.all")],
        horostkov: [this.translateService.instant("ukraine.district.all")],
        chortkov: [this.translateService.instant("ukraine.district.all")],
        shumsk: [this.translateService.instant("ukraine.district.all")],

        odesa: [
          this.translateService.instant("ukraine.district.odessa..kiev"),
          this.translateService.instant("ukraine.district.primorskiy"),
          this.translateService.instant("ukraine.district.suvorovskiy"),
          this.translateService.instant("ukraine.district.odessa.malinovskiy"),
        ],
        ananev: [this.translateService.instant("ukraine.district.all")],
        arciz: [this.translateService.instant("ukraine.district.all")],
        balta: [this.translateService.instant("ukraine.district.all")],
        belgoroddnestrovskiy: [
          this.translateService.instant("ukraine.district.all"),
        ],
        belyevka: [this.translateService.instant("ukraine.district.all")],
        berezovka: [this.translateService.instant("ukraine.district.all")],
        bolgrad: [this.translateService.instant("ukraine.district.all")],
        vilkovo: [this.translateService.instant("ukraine.district.all")],
        izmail: [this.translateService.instant("ukraine.district.all")],
        kiliy: [this.translateService.instant("ukraine.district.all")],
        kodima: [this.translateService.instant("ukraine.district.all")],
        podolsk: [this.translateService.instant("ukraine.district.all")],
        razdelnay: [this.translateService.instant("ukraine.district.all")],
        reni: [this.translateService.instant("ukraine.district.all")],
        tatarburnari: [this.translateService.instant("ukraine.district.all")],
        teplodar: [this.translateService.instant("ukraine.district.all")],
        chernomorsk: [this.translateService.instant("ukraine.district.all")],
        yznoe: [this.translateService.instant("ukraine.district.all")],

        lugansk: [this.translateService.instant("ukraine.district.all")],
        aleksandrovsk: [this.translateService.instant("ukraine.district.all")],
        almaznay: [this.translateService.instant("ukraine.district.all")],
        alchevsk: [this.translateService.instant("ukraine.district.all")],
        antracit: [this.translateService.instant("ukraine.district.all")],
        artemovsks: [this.translateService.instant("ukraine.district.all")],
        birykovo: [this.translateService.instant("ukraine.district.all")],
        brynka: [this.translateService.instant("ukraine.district.all")],
        vahrushevo: [this.translateService.instant("ukraine.district.all")],
        gorskoe: [this.translateService.instant("ukraine.district.all")],
        zimogorie: [this.translateService.instant("ukraine.district.all")],
        zolotoe: [this.translateService.instant("ukraine.district.all")],
        zorinsk: [this.translateService.instant("ukraine.district.all")],
        irmino: [this.translateService.instant("ukraine.district.all")],
        kirovsk: [this.translateService.instant("ukraine.district.all")],
        krasnodon: [this.translateService.instant("ukraine.district.all")],
        krasniyluch: [this.translateService.instant("ukraine.district.all")],
        kremennay: [this.translateService.instant("ukraine.district.all")],
        lisichansk: [this.translateService.instant("ukraine.district.all")],
        lugutino: [this.translateService.instant("ukraine.district.all")],
        miusinsk: [this.translateService.instant("ukraine.district.all")],
        molodogvardeysk: [
          this.translateService.instant("ukraine.district.all"),
        ],
        novoaydar: [this.translateService.instant("ukraine.district.all")],
        novodrugesk: [this.translateService.instant("ukraine.district.all")],
        novopskov: [this.translateService.instant("ukraine.district.all")],
        pervomaysk: [this.translateService.instant("ukraine.district.all")],
        perevalsk: [this.translateService.instant("ukraine.district.all")],
        petrovskoe: [this.translateService.instant("ukraine.district.all")],
        popasnay: [this.translateService.instant("ukraine.district.all")],
        privolie: [this.translateService.instant("ukraine.district.all")],
        rovenki: [this.translateService.instant("ukraine.district.all")],
        rubeznoe: [this.translateService.instant("ukraine.district.all")],
        svatovo: [this.translateService.instant("ukraine.district.all")],
        sverdlovsk: [this.translateService.instant("ukraine.district.all")],
        severodoneck: [this.translateService.instant("ukraine.district.all")],
        starobelsk: [this.translateService.instant("ukraine.district.all")],
        stahanov: [this.translateService.instant("ukraine.district.all")],
        suhodolsk: [this.translateService.instant("ukraine.district.all")],
        schastie: [this.translateService.instant("ukraine.district.all")],
        chervonopartizansk: [
          this.translateService.instant("ukraine.district.all"),
        ],

        lvovs: [this.translateService.instant("ukraine.district.all")],
        belz: [this.translateService.instant("ukraine.district.all")],
        bobrka: [this.translateService.instant("ukraine.district.all")],
        borislav: [this.translateService.instant("ukraine.district.all")],
        brodi: [this.translateService.instant("ukraine.district.all")],
        busk: [this.translateService.instant("ukraine.district.all")],
        velikiemosti: [this.translateService.instant("ukraine.district.all")],
        vinniki: [this.translateService.instant("ukraine.district.all")],
        glinyni: [this.translateService.instant("ukraine.district.all")],
        gorodoks: [this.translateService.instant("ukraine.district.all")],
        dobromil: [this.translateService.instant("ukraine.district.all")],
        drogobich: [this.translateService.instant("ukraine.district.all")],
        dublyni: [this.translateService.instant("ukraine.district.all")],
        gidachov: [this.translateService.instant("ukraine.district.all")],
        golkva: [this.translateService.instant("ukraine.district.all")],
        zolochev: [this.translateService.instant("ukraine.district.all")],
        kamenkabugskay: [this.translateService.instant("ukraine.district.all")],
        komarino: [this.translateService.instant("ukraine.district.all")],
        kulikov: [this.translateService.instant("ukraine.district.all")],
        moshin: [this.translateService.instant("ukraine.district.all")],
        mostiska: [this.translateService.instant("ukraine.district.all")],
        nikolaevs: [this.translateService.instant("ukraine.district.all")],
        novoyvorovsk: [this.translateService.instant("ukraine.district.all")],
        noviykalinov: [this.translateService.instant("ukraine.district.all")],
        noviyrozdol: [this.translateService.instant("ukraine.district.all")],
        olesko: [this.translateService.instant("ukraine.district.all")],
        peremislyni: [this.translateService.instant("ukraine.district.all")],
        pustomiti: [this.translateService.instant("ukraine.district.all")],
        ravarusskay: [this.translateService.instant("ukraine.district.all")],
        radehov: [this.translateService.instant("ukraine.district.all")],
        rudki: [this.translateService.instant("ukraine.district.all")],
        skole: [this.translateService.instant("ukraine.district.all")],
        sokal: [this.translateService.instant("ukraine.district.all")],
        sosnovka: [this.translateService.instant("ukraine.district.all")],
        stariysambor: [this.translateService.instant("ukraine.district.all")],
        stebnik: [this.translateService.instant("ukraine.district.all")],
        striy: [this.translateService.instant("ukraine.district.all")],
        sudovayvishny: [this.translateService.instant("ukraine.district.all")],
        truskavec: [this.translateService.instant("ukraine.district.all")],
        turka: [this.translateService.instant("ukraine.district.all")],
        ugnev: [this.translateService.instant("ukraine.district.all")],
        hodorov: [this.translateService.instant("ukraine.district.all")],
        chervonograd: [this.translateService.instant("ukraine.district.all")],
        yvorov: [this.translateService.instant("ukraine.district.all")],

        kupyansk: [
          this.translateService.instant("ukraine.district.kh.kupyansk.center"),
          this.translateService.instant(
            "ukraine.district.kh.kupyansk.mrubiley",
          ),
          this.translateService.instant(
            "ukraine.district.kh.kupyansk.zaoskole",
          ),
          this.translateService.instant("ukraine.district.kh.kupyansk.pos"),
          this.translateService.instant(
            "ukraine.district.kh.kupyansk.rnpromstroya3",
          ),
          this.translateService.instant(
            "ukraine.district.kh.kupyansk.dovgolevka",
          ),
          this.translateService.instant(
            "ukraine.district.kh.kupyansk.zapadnaya",
          ),
          this.translateService.instant(
            "ukraine.district.kh.kupyansk.kovsharovka1",
          ),
          this.translateService.instant("ukraine.district.kh.kupyansk.2mr"),
          this.translateService.instant("ukraine.district.kh.kupyansk.kupsort"),
          this.translateService.instant("ukraine.district.kh.kupyansk.kupuzl"),
        ],
        lozovaya: [
          this.translateService.instant(
            "ukraine.district.kh.lozovaya.city.mid",
          ),
          this.translateService.instant(
            "ukraine.district.kh.lozovaya.house.domaha",
          ),
          this.translateService.instant(
            "ukraine.district.kh.lozovaya.mikr.ugzap",
          ),
          this.translateService.instant(
            "ukraine.district.kh.lozovaya.avilovka",
          ),
          this.translateService.instant("ukraine.district.kh.lozovaya.sahalin"),
          this.translateService.instant("ukraine.district.shevchenkovsk"),
        ],
        odessa: [
          this.translateService.instant("ukraine.district.primorskiy"),
          this.translateService.instant("ukraine.district.suvorovskiy"),
          this.translateService.instant("ukraine.district.odessa..kiev"),
          this.translateService.instant("ukraine.district.odessa.malinovskiy"),
          this.translateService.instant("ukraine.district.odessa.limanskiy"),
          this.translateService.instant(
            "ukraine.district.odessa.ovidiopolskiy",
          ),
          this.translateService.instant("ukraine.district.odessa.bilaivskiy"),
        ],
        lvov: [
          this.translateService.instant("ukraine.district.lvov.galitsk"),
          this.translateService.instant(
            "ukraine.district.lvov.zeleznodorothniya",
          ),
          this.translateService.instant("ukraine.district.lvov.lichakovskiya"),
          this.translateService.instant("ukraine.district.lvov.sihanovskiya"),
          this.translateService.instant("ukraine.district.lvov.frankovskiya"),
          this.translateService.instant(
            "ukraine.district.lvov.shevchenkovskiya",
          ),
        ],
        kramatorsk: [
          this.translateService.instant(
            "ukraine.district.dn.kramatorsk.novos.vasilevka",
          ),
          this.translateService.instant(
            "ukraine.district.dn.kramatorsk.alexandrovka",
          ),
          this.translateService.instant(
            "ukraine.district.dn.kramatorsk.artemovsa",
          ),
          this.translateService.instant(
            "ukraine.district.dn.kramatorsk.belenkaya",
          ),
          this.translateService.instant(
            "ukraine.district.dn.kramatorsk.vasipust",
          ),
          this.translateService.instant("ukraine.district.dn.kramatorsk.vesel"),
          this.translateService.instant(
            "ukraine.district.dn.kramatorsk.gorodesheno",
          ),
          this.translateService.instant(
            "ukraine.district.dn.kramatorsk.damansk",
          ),
          this.translateService.instant(
            "ukraine.district.dn.kramatorsk.domenn",
          ),
          this.translateService.instant(
            "ukraine.district.dn.kramatorsk.ivanovka",
          ),
          this.translateService.instant(
            "ukraine.district.dn.kramatorsk.kamish",
          ),
          this.translateService.instant(
            "ukraine.district.dn.kramatorsk.krasnogorka",
          ),
          this.translateService.instant(
            "ukraine.district.dn.kramatorsk.malotaranovka",
          ),
          this.translateService.instant(
            "ukraine.district.dn.kramatorsk.marevka",
          ),
          this.translateService.instant(
            "ukraine.district.dn.kramatorsk.mnkondic",
          ),
          this.translateService.instant(
            "ukraine.district.dn.kramatorsk.newivanovka",
          ),
          this.translateService.instant(
            "ukraine.district.dn.kramatorsk.nowkrasnogorka",
          ),
          this.translateService.instant(
            "ukraine.district.dn.kramatorsk.novopocht",
          ),
          this.translateService.instant(
            "ukraine.district.dn.kramatorsk.nowsvet",
          ),
          this.translateService.instant(
            "ukraine.district.dn.kramatorsk.nowsvet",
          ),
          this.translateService.instant(
            "ukraine.district.dn.kramatorsk.oktabrskiya",
          ),
          this.translateService.instant(
            "ukraine.district.dn.kramatorsk.partizansk",
          ),
          this.translateService.instant(
            "ukraine.district.dn.kramatorsk.petrovka",
          ),
          this.translateService.instant(
            "ukraine.district.dn.kramatorsk.privole",
          ),
          this.translateService.instant(
            "ukraine.district.dn.kramatorsk.prokatch",
          ),
          this.translateService.instant(
            "ukraine.district.dn.kramatorsk.semenovka",
          ),
          this.translateService.instant(
            "ukraine.district.dn.kramatorsk.sofevka",
          ),
          this.translateService.instant(
            "ukraine.district.dn.kramatorsk.socgorod",
          ),
          this.translateService.instant(
            "ukraine.district.dn.kramatorsk.starivanovka",
          ),
          this.translateService.instant(
            "ukraine.district.dn.kramatorsk.starkrasnogorka",
          ),
          this.translateService.instant(
            "ukraine.district.dn.kramatorsk.staropocht",
          ),
          this.translateService.instant(
            "ukraine.district.dn.kramatorsk.stargorod",
          ),
          this.translateService.instant(
            "ukraine.district.dn.kramatorsk.shabelovka",
          ),
          this.translateService.instant(
            "ukraine.district.dn.kramatorsk.yasnapolana",
          ),
          this.translateService.instant(
            "ukraine.district.dn.kramatorsk.yasnogorka",
          ),
        ],
        herson: [
          this.translateService.instant("ukraine.district.herson.suvorovsk"),
          this.translateService.instant("ukraine.district.herson.korabelniya"),
          this.translateService.instant("ukraine.district.herson.dneprovskiy"),
        ],
        chernigov: [
          this.translateService.instant(
            "ukraine.district.chernigiv.desnyanskiy",
          ),
          this.translateService.instant(
            "ukraine.district.chernigiv.novozavodskiyi",
          ),
        ],
        kiivBilaCerkva: [
          this.translateService.instant(
            "ukraine.district.kiiv.bilacerkva.zmlevanevskogo",
          ),
          this.translateService.instant(
            "ukraine.district.kiiv.bilacerkva.zmpeschaniy",
          ),
          this.translateService.instant(
            "ukraine.district.kiiv.bilacerkva.zmtaraschanskaya",
            "",
          ),
          this.translateService.instant(
            "ukraine.district.kiiv.bilacerkva.mrpioner",
          ),
          this.translateService.instant(
            "ukraine.district.kiiv.bilacerkva.mrdns",
          ),
          this.translateService.instant(
            "ukraine.district.kiiv.bilacerkva.zareche",
          ),
          this.translateService.instant(
            "ukraine.district.kiiv.bilacerkva.zdposelok",
          ),
          this.translateService.instant(
            "ukraine.district.kiiv.bilacerkva.rotok",
          ),
          this.translateService.instant(
            "ukraine.district.kiiv.bilacerkva.vokzalniy",
          ),
          this.translateService.instant(
            "ukraine.district.kiiv.bilacerkva.center",
          ),
          this.translateService.instant(
            "ukraine.district.kiiv.bilacerkva.zmpavlichenkoshtab",
          ),
          this.translateService.instant(
            "ukraine.district.kiiv.bilacerkva.gaek",
          ),
        ],

        poltavakremenchuk: [
          this.translateService.instant(
            "ukraine.district.poltavska.kremenchuk.autozavodskoy",
          ),
          this.translateService.instant(
            "ukraine.district.poltavska.kremenchuk.krukovskiy",
          ),
        ],
        poltavagorishniPlavni: [
          this.translateService.instant(
            "ukraine.district.poltavska.gorishniPlavni.bazaluki",
          ),
          this.translateService.instant(
            "ukraine.district.poltavska.gorishniPlavni.voloshine",
          ),
          this.translateService.instant(
            "ukraine.district.poltavska.gorishniPlavni.gora",
          ),
          this.translateService.instant(
            "ukraine.district.poltavska.gorishniPlavni.dmitriivka",
          ),
          this.translateService.instant(
            "ukraine.district.poltavska.gorishniPlavni.kiyashi",
          ),
          this.translateService.instant(
            "ukraine.district.poltavska.gorishniPlavni.solonici",
          ),
          this.translateService.instant(
            "ukraine.district.poltavska.gorishniPlavni.kuzmenki",
          ),
        ],
        dnepropetrovskayaNikopol: [
          this.translateService.instant("ukraine.district.all"),
        ],
        dnepropetrovsk: [this.translateService.instant("ukraine.district.all")],
        apostolovo: [this.translateService.instant("ukraine.district.all")],
        verhnedneprovsk: [
          this.translateService.instant("ukraine.district.all"),
        ],
        verhovchevo: [this.translateService.instant("ukraine.district.all")],
        volnogorsk: [this.translateService.instant("ukraine.district.all")],
        dneprodzerzinsk: [
          this.translateService.instant("ukraine.district.all"),
        ],
        zeltievodi: [this.translateService.instant("ukraine.district.all")],
        zelenodolsk: [this.translateService.instant("ukraine.district.all")],
        krivoyrog: [this.translateService.instant("ukraine.district.all")],
        marganec: [this.translateService.instant("ukraine.district.all")],
        novomoskovsk: [this.translateService.instant("ukraine.district.all")],
        ordgonikidze: [this.translateService.instant("ukraine.district.all")],
        pavlograd: [this.translateService.instant("ukraine.district.all")],
        pereschepino: [this.translateService.instant("ukraine.district.all")],
        pershotravensk: [this.translateService.instant("ukraine.district.all")],
        podgorodnoe: [this.translateService.instant("ukraine.district.all")],
        pytihatki: [this.translateService.instant("ukraine.district.all")],
        sinelnikovo: [this.translateService.instant("ukraine.district.all")],
        ternovka: [this.translateService.instant("ukraine.district.all")],

        zitomirZitomir: [
          this.translateService.instant(
            "ukraine.district.zitomir.zitomir.bogunskiy",
          ),
          this.translateService.instant(
            "ukraine.district.zitomir.zitomir.king",
          ),
        ],
        kiivKiiv: [
          this.translateService.instant(
            "ukraine.district.kiiv.kiiv.goloseevskiy",
          ),
          this.translateService.instant(
            "ukraine.district.kiiv.kiiv.obolonskiy",
          ),
          this.translateService.instant(
            "ukraine.district.kiiv.kiiv.pecherskiy",
          ),
          this.translateService.instant("ukraine.district.kiiv.kiiv.podolskiy"),
          this.translateService.instant(
            "ukraine.district.kiiv.kiiv.svyatoshinskiy",
          ),
          this.translateService.instant(
            "ukraine.district.kiiv.kiiv.solomenskiy",
          ),
          this.translateService.instant(
            "ukraine.district.kiiv.kiiv.shevchenkovskiy",
          ),
        ],
        summiSummi: [
          this.translateService.instant(
            "ukraine.district.summi.summi.zorichenskiy",
          ),
          this.translateService.instant(
            "ukraine.district.summi.summi.kovpakivskii",
          ),
        ],
        cherkasiCherkasi: [
          this.translateService.instant(
            "ukraine.district.cherkaska.cherkasi.sosnivka",
          ),
          this.translateService.instant(
            "ukraine.district.cherkaska.cherkasi.lunacharka",
          ),
          this.translateService.instant(
            "ukraine.district.cherkaska.cherkasi.pivden",
          ),
          this.translateService.instant(
            "ukraine.district.cherkaska.cherkasi.porti",
          ),
          this.translateService.instant(
            "ukraine.district.cherkaska.cherkasi.vokzal",
          ),
          this.translateService.instant(
            "ukraine.district.cherkaska.cherkasi.center",
          ),
          this.translateService.instant(
            "ukraine.district.cherkaska.cherkasi.gromova",
          ),
          this.translateService.instant(
            "ukraine.district.cherkaska.cherkasi.raiond",
          ),
          this.translateService.instant(
            "ukraine.district.cherkaska.cherkasi.sedova",
          ),
          this.translateService.instant(
            "ukraine.district.cherkaska.cherkasi.700rich",
          ),
          this.translateService.instant(
            "ukraine.district.cherkaska.cherkasi.kazbet",
          ),
        ],
        summiAhtirka: [
          this.translateService.instant("ukraine.city.summi.ahtirka.all"),
        ],
        chernigovPriluki: [
          this.translateService.instant("ukraine.district.all"),
        ],
      }),
    );
  }

  translateAddressForTeplocom() {
    this.store.dispatch(
      new SetCityAction({
        spb: [this.translateService.instant("russian.city.spb")],
      }),
    );

    this.store.dispatch(
      new SetRegionAction([
        this.translateService.instant("russian.region.spb"),
      ]),
    );

    this.store.dispatch(
      new SetDistrictAction({
        spb: [
          this.translateService.instant("russian.district.spb.admiral"),
          this.translateService.instant("russian.district.spb.vasilevostrov"),
          this.translateService.instant("russian.district.spb.viborg"),
          this.translateService.instant("russian.district.spb.kalinino"),
          this.translateService.instant("russian.district.spb.kirov"),
          this.translateService.instant("russian.district.spb.kolpino"),
          this.translateService.instant("russian.district.spb.krasnoGvard"),
          this.translateService.instant("russian.district.spb.krasnoSelsk"),
          this.translateService.instant("russian.district.spb.krondshtad"),
          this.translateService.instant("russian.district.spb.kurort"),
          this.translateService.instant("russian.district.spb.moskovsk"),
          this.translateService.instant("russian.district.spb.nevsk"),
          this.translateService.instant("russian.district.spb.petrograd"),
          this.translateService.instant("russian.district.spb.petroDvorcov"),
          this.translateService.instant("russian.district.spb.primorsk"),
          this.translateService.instant("russian.district.spb.pushkino"),
          this.translateService.instant("russian.district.spb.frunzevsk"),
          this.translateService.instant("russian.district.spb.central"),
        ],
      }),
    );
  }
}

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LoadingComponent } from "./loading.component";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzSpinModule } from "ng-zorro-antd/spin";

@NgModule({
  declarations: [LoadingComponent],
  imports: [CommonModule, NzSpinModule, NzIconModule],
  exports: [LoadingComponent],
})
export class LoadingModule {}

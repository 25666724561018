import {Action} from '@ngrx/store';
import {Accidents} from '@interfaces';

export enum AccidentsActionTypes {
  SetAccidents = '[SetAccidents] Set Accidents',
  DellAccidents = '[DellAccidents] Dell Accidents',
  SetAccidentsType = '[SetAccidentsType] Set Accidents Type'
}

export class SetAccidentsActions implements Action {
  readonly type = AccidentsActionTypes.SetAccidents;
  constructor(public payload: Accidents) {}
}
export class SetAccidentsTypeActions implements Action {
  readonly type = AccidentsActionTypes.SetAccidentsType;
  constructor(public payload: string) {}
}
export class DellAccidentsActions implements Action {
  readonly type = AccidentsActionTypes.DellAccidents;
  constructor(public payload: Accidents) {}
}

export type AccidentsAction = SetAccidentsActions | DellAccidentsActions | SetAccidentsTypeActions ;

import { NavigationPoints } from "@enum";
const { version } = require("../../package.json");

export const environment = {
  version: version.replace(/\./g, ""),
  production: true,
  isTtitle: true,
  titleLeft: "Smart",
  titleRight: "Pressure",
  isSubTtitle: true,
  subTitle: "Innovative Metering Gas Systems",
  icon: "water.svg",
  baseUrl: "rest.voda.hasgas.com.ua",
  baseAuthUrl: "auth.voda.hasgas.com.ua",
  menu: [
    {
      icon: "",
      path: NavigationPoints.devices,
      label: "widget.water.devices",
    },
    {
      icon: "",
      path: NavigationPoints.accidents,
      label: "accidents",
    },
    {
      icon: "",
      path: NavigationPoints.modems,
      label: "volumes-telem",
      children: [NavigationPoints.oneModem],
    },
    {
      icon: "",
      path: NavigationPoints.sensors,
      label: "sensors",
      children: [NavigationPoints.oneSensor],
    },
    {
      icon: "",
      path: NavigationPoints.routes,
      label: "statement.user1",
      children: [NavigationPoints.oneRoute],
    },
    {
      icon: "",
      path: NavigationPoints.logs,
      label: "logs.name.menu.page",
      children: [
        NavigationPoints.logsDevices,
        NavigationPoints.logsModems,
        NavigationPoints.logsSensors,
      ],
    },
  ],
};

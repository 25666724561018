import { Injectable } from "@angular/core";
import { environment } from "@env";

@Injectable({
  providedIn: "root",
})
export class DetectedHostService {
  private host: string = window.location.hostname;
  constructor() {}

  public setHost(): void {
    localStorage.setItem("host", this.host);
  }

  public getHost(): string {
    if (environment.production)
      return "rest." + localStorage.getItem("host") + this.getPort();
    else return environment.baseUrl;
    // return 'rest.smart-spring.teplocom-sale.ru'
  }

  public getAuthHost(): string {
    if (environment.production)
      return "auth." + localStorage.getItem("host") + this.getPort();
    else return environment.baseAuthUrl;
    // return 'auth.smart-spring.teplocom-sale.ru'
  }

  public getPort(): string {
    if (environment.production) {
      switch (window.location.port) {
        case "":
          return "";
        default:
          return ":" + window.location.port;
      }
    }
    return "";
  }

  public getWSProtocol(): string {
    if (environment.production) return "wss:";
    else return "wss:";
  }
}

import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { AppState } from "@state";
import { LocaleListEnum } from "app/shared/enums/locale.list.enum";
import { TranslateDeviceExeleService } from "./translate-device-exele.service";
import { TranslateService } from "@ngx-translate/core";
import { NzI18nService, en_US, ru_RU, uk_UA } from "ng-zorro-antd/i18n";
import { environment } from "@env";
import { DateAdapter } from "@angular/material/core";
import { SetLangAction } from "../../redux/actions/global.actions";

@Injectable({
  providedIn: "root",
})
export class LocalizationService {
  public env = environment;

  constructor(
    private store: Store<AppState>,
    private translate: TranslateDeviceExeleService,
    public translateService: TranslateService,
    private dateAdapter: DateAdapter<any>,
    private i18n: NzI18nService,
  ) {
    translateService.addLangs(["en-US", "ru-RU", "ua-UK"]);
    translateService.setDefaultLang("ua-UK");
    this.dateAdapter.setLocale("ua");
  }

  public setLocalize(local: string): void {
    switch (local) {
      case LocaleListEnum.ua:
        {
          this.store.dispatch(new SetLangAction("UA"));
          localStorage.setItem("water_lang", "ua");
          this.translateService.use("ua-UK");
          this.i18n.setLocale(uk_UA);
          this.dateAdapter.setLocale("uk");
        }
        break;
      case LocaleListEnum.en:
        {
          this.store.dispatch(new SetLangAction("EN"));
          localStorage.setItem("water_lang", "en");
          this.translateService.use("en-US");
          this.i18n.setLocale(en_US);
          this.dateAdapter.setLocale("en");
        }
        break;
      case LocaleListEnum.ru:
        {
          this.store.dispatch(new SetLangAction("RU"));
          localStorage.setItem("water_lang", "ru");
          this.translateService.use("ru-RU");
          this.i18n.setLocale(ru_RU);
          this.dateAdapter.setLocale("ru");
        }
        break;
    }

    this.translate.translateAddress();
  }

  public autoLang(): void {
    if (localStorage.getItem("water_lang") !== null) {
      switch (localStorage.getItem("water_lang")) {
        case "ua":
          this.setLocalize(LocaleListEnum.ua);
          break;
        case "ru":
          this.setLocalize(LocaleListEnum.ru);
          break;
      }
    } else {
      const broLang: string = window.navigator.language;
      if (broLang === "ua-UK") this.setLocalize(LocaleListEnum.ua);
      if (broLang === "ru-RU") this.setLocalize(LocaleListEnum.ru);
      else this.setLocalize(LocaleListEnum.ua);
    }
  }
}

import { SharedModule } from "@shared";
import { BrowserModule, Title } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { registerLocaleData } from "@angular/common";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { CoreModule } from "./core/core.module";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { HttpClient } from "@angular/common/http";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import ru from "@angular/common/locales/ru";
import ua from "@angular/common/locales/uk";
import en from "@angular/common/locales/en";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "@env";
import { EffectsModule } from "@ngrx/effects";
import { StoreRouterConnectingModule } from "@ngrx/router-store";
import { RouterEffects } from "@pressure-state/router";

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  const version: string = environment.version;
  return new TranslateHttpLoader(
    http,
    "../../../../assets/i18n/",
    `.json?v=${version}`,
  );
}
registerLocaleData(en);
registerLocaleData(ru);
registerLocaleData(ua);
@NgModule({
  declarations: [AppComponent],
  imports: [
    CoreModule,
    SharedModule,
    BrowserModule,
    AppRoutingModule,
    StoreRouterConnectingModule.forRoot(),
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
      useDefaultLang: false,
    }),
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
    }),
    EffectsModule.forRoot([RouterEffects]),
  ],
  providers: [Title],
  bootstrap: [AppComponent],
})
export class AppModule {}

import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class DateAppService {
  constructor() {}

  public static getFirstDayMonth(): Date {
    return new Date(new Date().getFullYear(), new Date().getMonth(), 1);
  }

  public static dateFirstDayMonth(date: Date): Date {
    return new Date(date.getFullYear(), date.getMonth(), 1);
  }

  public static dateToLocalStringFromString(dateString: string): string {
    const date = new Date(dateString);
    return date.toLocaleString("uk-UK", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    });
  }

  public dateToLocalString(date: any): string {
    return date
      .toLocaleString("uk-UK", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      })
      .split(".")
      .reverse()
      .join("-");
  }

  public dateFormatToLocalString(date: Date): string {
    return date.toLocaleString("uk-UK", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    });
  }

  public getTimeForReqest(date: Date) {
    return (
      this.dateToLocalString(date) +
      "T" +
      date.toLocaleString("uk-UK", {
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      })
    );
  }

  public dateLastDay(date): string {
    return this.dateToLocalString(
      new Date(date.getFullYear(), date.getMonth() + 1, 0),
    );
  }

  public dateFirstDay(date: Date): string {
    return this.dateToLocalString(
      new Date(date.getFullYear(), date.getMonth(), 1),
    );
  }

  public getTimeApp(date: Date | string, ww: "wrap" | "nowrap"): string {
    date = new Date(date);
    const wordWrap = ww === "wrap" ? " \n" : " ";
    return (
      date.toLocaleString("uk-UK", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        timeZone: this.getTimeZoneForPlatform(),
      }) +
      wordWrap +
      date.toLocaleString("uk-UK", {
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        timeZone: this.getTimeZoneForPlatform(),
      })
    );
  }

  private getTimeZoneForPlatform() {
    if (navigator.userAgent.indexOf("Windows NT 5.1") !== -1) return "UTC";
    else return "Europe/Kiev";
  }

  getDateStringToFormatSecond(dateString) {
    if (dateString === null || dateString === undefined) return "";
    if (!isNaN(dateString)) return dateString;
    let datetmp = new Date(dateString);
    const tmpDateIsTimeProb = dateString.split(" ");
    if (isNaN(datetmp.getTime())) {
      if (tmpDateIsTimeProb.length === 2) {
        datetmp = new Date(tmpDateIsTimeProb[0] + "T" + tmpDateIsTimeProb[1]);

        return datetmp.toLocaleString("uk-UK", {
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        });
      } else {
        return dateString;
      }
    }

    const tmpDateIsTime = dateString.split("T");

    if (tmpDateIsTime.length === 2 || tmpDateIsTimeProb.length === 2) {
      return datetmp.toLocaleString("uk-UK", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      });
    }

    return datetmp.toLocaleString("uk-UK", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    });
  }
}

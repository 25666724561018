import { createFeatureSelector, createSelector } from "@ngrx/store";
import { AppRootState } from "./root.reducer";

export const selectRootState = createFeatureSelector<AppRootState>("root");

export const selectMenu = createSelector(
  selectRootState,
  (state: AppRootState) => state?.menu,
);

export const selectActivePage = createSelector(
  selectRootState,
  (state: AppRootState) => state?.activePage,
);

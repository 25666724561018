import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HttpSentEvent,
  HttpHeaderResponse,
  HttpProgressEvent,
  HttpResponse,
  HttpUserEvent,
} from "@angular/common/http";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { catchError, filter, finalize, switchMap, take } from "rxjs/operators";
import { AuthorizationService } from "../authorization.service";
import { ReqestCloneJwt } from "./reqest.clone.jwt";
import { AppState } from "@state";
import { Store } from "@ngrx/store";
import { MessageService } from "../message.service";
import { WebsocketService } from "../websocket/websocket.service";
import { TranslateService } from "@ngx-translate/core";
import { ToasterService } from "../toaster.service";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  public isRefreshingToken = false;
  public tokenSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    null,
  );

  constructor(
    private auth: AuthorizationService,
    private clone: ReqestCloneJwt,
    private store: Store<AppState>,
    private translateService: TranslateService,
    private toaster: ToasterService,
    private websocketService: WebsocketService,
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<
    | HttpSentEvent
    | HttpHeaderResponse
    | HttpProgressEvent
    | HttpResponse<any>
    | HttpUserEvent<any>
    | any
  > {
    return next.handle(request).pipe(
      catchError((err) => {
        if (err.status === 401 && err.error.message !== "token.is.expired") {
          this.toaster.show(
            "error",
            "",
            this.translateService.instant("err.loginpass"),
          );
          return throwError(err.error.message || err.statusText || err);
        } else if (err.status === 0 || err.status === 500) {
          this.toaster.show(
            "error",
            "",
            this.translateService.instant("err.server.timeout"),
          );
          return throwError(err.error.message || err.statusText || err);
        } else if (
          err.status === 401 &&
          err.error.message === "token.is.expired"
        ) {
          // this.message.newError('403 Доступ забороненно');
          return this.handle403Error(request, next);
        } else if (err.status === 403) {
          this.toaster.show(
            "error",
            "",
            this.translateService.instant("err.security"),
          );
          return throwError(err.error.message || err.statusText || err);
        } else if (err.status === 404) {
          if (err.error.message === "Customer not found") {
            this.toaster.show(
              "error",
              "",
              this.translateService.instant("err.not.code.loginpass"),
              90000,
            );
          } else {
            this.toaster.show(
              "error",
              "",
              this.translateService.instant("err.url.not") +
                " " +
                err.url +
                " " +
                this.translateService.instant("err.url.not2"),
            );
          }

          return throwError(err.error.message || err.statusText || err);
        } else {
          // this.toaster.show('error', '', this.translateService.instant('err.undefined') );
          return throwError(err.error.message || err.statusText || err);
        }
      }),
    );
  }

  private handle403Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;

      this.tokenSubject.next(null);

      return this.auth.refresh().pipe(
        switchMap((tokens: boolean) => {
          if (tokens) {
            this.tokenSubject.next(tokens);
            this.websocketService.reloadToken();
            return next.handle(this.clone.addTokenToRequest(request));
          }
          this.toaster.show(
            "error",
            "",
            this.translateService.instant("err.auth"),
          );
          return <any>this.auth.logout();
        }),
        catchError((err) => {
          this.toaster.show(
            "error",
            "",
            this.translateService.instant("err.auth"),
          );
          return <any>this.auth.logout();
        }),
        finalize(() => {
          this.isRefreshingToken = false;
        }),
      );
    } else {
      this.isRefreshingToken = false;
      return this.tokenSubject.pipe(
        filter((token) => token),
        take(1),
        switchMap(() => {
          return next.handle(this.clone.addTokenToRequest(request));
        }),
      );
    }
  }
}

import { Injectable } from "@angular/core";
import { Actions, concatLatestFrom, createEffect, ofType } from "@ngrx/effects";
import { ROUTER_NAVIGATED } from "@ngrx/router-store";
import { Store } from "@ngrx/store";
import { selectUrl } from "@pressure-state/router/router.selectors";
import { map } from "rxjs/operators";
import { selectMenu } from "@pressure-state/root/root.selectors";
import { PressureMenuNavigate } from "@interfaces";
import { NavigationPoints } from "@enum";
import { RootActions } from "@pressure-state/root/root.action";

@Injectable()
export class RouterEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
  ) {}

  loadAnyPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_NAVIGATED),
      concatLatestFrom(() => [
        this.store.select(selectMenu),
        this.store.select(selectUrl),
      ]),
      map(([, menu, rawUrl]: [unknown, PressureMenuNavigate[], string]) => {
        const url: string = rawUrl
          ? rawUrl?.replace(/\?.*/, "")?.substring(1)
          : "";
        const active: PressureMenuNavigate = menu.find(
          (item: PressureMenuNavigate) =>
            item.path === url ||
            (item?.children && item.children.includes(url as NavigationPoints)),
        );
        return RootActions.navigateToPage({
          page: active ? active.path : NavigationPoints.devices,
        });
      }),
    ),
  );
}

import { Injectable } from "@angular/core";
import { Tokens } from "@interfaces";
import { WebsocketService } from "./websocket/websocket.service";

@Injectable({
  providedIn: "root",
})
export class TokensService {
  constructor() {}
  public setTokens(tokens: Tokens): void {
    sessionStorage.setItem("access", tokens.access);
    sessionStorage.setItem("refresh", tokens.refresh);

    localStorage.setItem("access", tokens.access);
    localStorage.setItem("refresh", tokens.refresh);
  }

  public getTokenAccess(): string {
    return sessionStorage.getItem("access");
  }

  public getTokenRefresh(): string {
    return sessionStorage.getItem("refresh");
  }

  public checkTokens(): boolean {
    if (sessionStorage.getItem("access")) return true;
    else {
      if (localStorage.getItem("access")) {
        sessionStorage.setItem("access", localStorage.getItem("access"));
        sessionStorage.setItem("refresh", localStorage.getItem("refresh"));
      }
      return false;
    }
  }

  public clearTokens(): void {
    sessionStorage.clear();
    localStorage.removeItem("refresh");
    localStorage.removeItem("access");
  }

  public parseToken(): any {
    const base64Url = this.getTokenAccess().split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map((c) => {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(""),
    );

    return JSON.parse(jsonPayload);
  }
}

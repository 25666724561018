import { GlobalApp } from "@interfaces";
import { GlobalActions, GlobalActionTypes } from "../actions/global.actions";

const initialState: GlobalApp = {
  isVisibleChildMainPanel: true,
  templateVisibleTelemetryPage: "table",
  templateVisibleVolumesPage: "table",
  bufferTemplateVisibleTelemetryPage: null,
  tablePageSize: 10,
  date: {
    from: new Date(),
    to: new Date(new Date().getDate() - 1),
    opeat: new Date(),
  },
  activePage: "main",
  lang: "UK",
  books: [],
  region: ["Харьківська", "Одеська"],
  city: null,
  district: null,
  user: {
    isLoaded: false,
    id: 1,
    name: "Test User",
    menu: [],
    permissions: null,
    edit: true,
  },
  tableSizePages: null,
};

export function reducerGlobalApp(
  state = initialState,
  action: GlobalActions,
): GlobalApp {
  switch (action.type) {
    case GlobalActionTypes.SetVisibleChildMainPanel:
      return {
        ...state,
        isVisibleChildMainPanel: action.payload,
      };
    case GlobalActionTypes.SetActivePage:
      return {
        ...state,
        activePage: action.payload,
      };
    case GlobalActionTypes.SetTablePagesSize:
      state.tableSizePages = action.payload;
      return state;
    case GlobalActionTypes.SetCity:
      return {
        ...state,
        city: action.payload,
      };
    case GlobalActionTypes.SetRegion:
      return {
        ...state,
        region: action.payload,
      };
    case GlobalActionTypes.SetEditUser:
      return {
        ...state,
        user: {
          ...state.user,
          edit: action.payload,
        },
      };
    case GlobalActionTypes.GetPermissionUser:
      return {
        ...state,
        user: {
          ...state.user,
          permissions: action.payload,
        },
      };
    case GlobalActionTypes.GetPermissionUserMenu:
      return {
        ...state,
        user: {
          ...state.user,
          menu: action.payload,
        },
      };
    case GlobalActionTypes.SetDistrict:
      return {
        ...state,
        district: action.payload,
      };
    case GlobalActionTypes.SetFromDate:
      state.date.from = action.payload;
      return state;
    case GlobalActionTypes.SetToDate:
      state.date.to = action.payload;
      return state;
    case GlobalActionTypes.SetTooDate:
      state.date.opeat = action.payload;
      return state;
    case GlobalActionTypes.SELECTED_TEMPLATE_TELEMETRY_PAGE_VISIBLE:
      state.bufferTemplateVisibleTelemetryPage =
        state.templateVisibleTelemetryPage;
      state.templateVisibleTelemetryPage = action.payload;
      return state;
    case GlobalActionTypes.SELECTED_TEMPLATE_VOLUMES_PAGE_VISIBLE:
      state.templateVisibleVolumesPage = action.payload;
      return state;
    case GlobalActionTypes.DEFAULT_TEMPLATE_TELEMETRY_PAGE_VISIBLE:
      state.bufferTemplateVisibleTelemetryPage = null;
      // state.templateVisibleTelemetryPage = 'table';
      return state;
    case GlobalActionTypes.SetUser:
      state.user.name = action.payload;
      return state;
    case GlobalActionTypes.LoadedUserInfo:
      state.user.isLoaded = action.payload;
      return state;
    case GlobalActionTypes.SetLang:
      return {
        ...state,
        lang: action.payload,
      };
    default:
      return state;
  }
}

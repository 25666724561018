import { Injectable } from "@angular/core";
import { WebSocketMessage } from "./web-socket-message.class";
import { IMessage, Message } from "@stomp/stompjs";
import { DetectedHostService } from "../detected.host.service";
import { TokensService } from "../tokens.service";
import { Observable, Subscription } from "rxjs";
import { Store } from "@ngrx/store";
import { AppState } from "@state";

@Injectable({
  providedIn: "root",
})
export class WebsocketService {
  public wsTelemStream: Observable<Message>;

  private webSocketMessage: WebSocketMessage;
  private _wsTelemStream: Subscription;

  constructor(
    private host: DetectedHostService,
    private tokenService: TokensService,
    private store: Store<AppState>,
  ) {}

  public init(): void {
    this.startWebsocket();
  }

  public startWebsocket(): void {
    this.webSocketMessage = new WebSocketMessage(
      this.host.getWSProtocol() +
        "//" +
        this.host.getHost() +
        "/greeting?access_token=" +
        this.tokenService.getTokenAccess(),
      this.tokenService.parseToken().sub.toString(),
    );
  }

  public stopPublishMessagingIntoTelem(): void {
    if (this._wsTelemStream) {
      this._wsTelemStream.unsubscribe();
    }
    this.webSocketMessage.stopPublishMessagingIntoTelem();
  }

  public startPublishMessagingIntoTelem(): void {
    this.webSocketMessage.startPublishMessagingIntoTelem();
    this.subscribeTelem();
  }

  public streamTelem(): Observable<Message> {
    return this.webSocketMessage.streamTelem();
  }

  public closeConnection(): void {
    this.webSocketMessage.closeConnection();
    if (this._wsTelemStream) {
      this._wsTelemStream.unsubscribe();
    }
  }

  public subscribeTelem(): void {
    this.wsTelemStream = this.streamTelem();
    this._wsTelemStream = this.wsTelemStream.subscribe((e: IMessage) => {
      JSON.parse(e.body.toString()).forEach((eo) => {
        console.log(e);
        //   TODO I think that we can make dispatch to store or
      });
    });
  }

  public reloadToken(): void {
    this.closeConnection();
    if (this._wsTelemStream) {
      this.webSocketMessage = new WebSocketMessage(
        this.host.getWSProtocol() +
          "//" +
          this.host.getHost() +
          this.host.getPort() +
          "/greeting?access_token=" +
          this.tokenService.getTokenAccess(),
        this.tokenService.parseToken().sub.toString(),
      );

      this._wsTelemStream.unsubscribe();
      this.startPublishMessagingIntoTelem();
    }
  }
}

import { MessagesState } from "@interfaces";
import { MESSAGE_ACTION, MessageActions } from "../actions/message.actions";

const defaultState: MessagesState = {
  message: [],
};

export function messageReducer(
  state: MessagesState = defaultState,
  action: MessageActions,
) {
  switch (action.type) {
    case MESSAGE_ACTION.IS_VISIBLE_MESSAGE:
      return {
        ...state,
        message: [...state.message, action.payload],
      };
    case MESSAGE_ACTION.IS_HIDDEN_MESSAGE:
      return {
        ...state,
        message: [
          ...state.message.filter((item) => item.id !== action.payload),
        ],
      };
    default:
      return {
        ...state,
      };
  }
}

import {Action} from "@ngrx/store";
import { Loggers} from "@interfaces";

export enum LogDevicesActionTypes {
  SetLogDevices = '[SetLogDevices] Set LogDevices',
}

export class SetLogDevicesActions implements Action {
  readonly type = LogDevicesActionTypes.SetLogDevices;
  constructor(public payload: Loggers) {}
}

export type LogDevicesAction = SetLogDevicesActions ;

import { environment } from "@env";
import { createReducer, on } from "@ngrx/store";
import { NavigationPoints } from "@enum";
import { getInitialActivePageUtil } from "@utils";
import { RootActions } from "./root.action";
import { routerReducer } from "@ngrx/router-store";
import { PressureMenuNavigate } from "@interfaces";

export interface AppRootState {
  menu: PressureMenuNavigate[];
  activePage: NavigationPoints;
}

const initialRootState: AppRootState = {
  menu: environment.menu,
  activePage: getInitialActivePageUtil(),
};

const reducerRootApp = createReducer(
  initialRootState,
  on(RootActions.navigateToPage, (state: AppRootState, { page }) => ({
    ...state,
    activePage: page,
  })),
);

export const rootReducer = {
  root: reducerRootApp,
  router: routerReducer,
};

import { Action } from '@ngrx/store';
import {CurrentIperlReceipt, ICurrentDevInfo, TableAllDevice, CurrentDeviceStatus, DeviceForStatus, Widget} from '@interfaces';

export enum DevicesActionTypes {
  Set_Filter_Table_Devices = '[Devices] Set_Filter_Table_devices',
  LoadDevicess = '[Devices] Load Devicess',
  LoadWidgetsDevicess = '[Devices] Load Widgets Devices',
  SetTitleForTable = '[Devices] Set Title For Table',
  SetDevice = '[Devices] Set Device',
  SetFromDate = '[Devices] Set From Date',
  SetToDate = '[Devices] Set To Date',
  SetTooDate = '[Devices] Set Too Date',
  SetTypePeriod = '[Devices] Set Type Period',
  LoadCurrentDevice = '[Devices] Load Current Device',
  LoadCurrentIperlReceipt = '[Devices] Load Current Iperl Receipt',
  LoadCurrentStatusDevice = '[Devices] Load Current Status Device',
  SetCurrentStatusDevice = '[Devices] Set Current Status Device',
  SetActiveStatus = '[Devices] Set Active Status'
}

export class SetFilterTableDevicesActions implements Action {
  readonly type = DevicesActionTypes.Set_Filter_Table_Devices;
  constructor(public payload: string) {}
}

export class LoadCurrentIperlReceiptAction implements Action {
  readonly type = DevicesActionTypes.LoadCurrentIperlReceipt;

  constructor(public payload: CurrentIperlReceipt) {
  }
}

export class LoadDevicesAction implements Action {
  readonly type = DevicesActionTypes.LoadDevicess;

  constructor(public payload: TableAllDevice) {}
}

export class LoadWidgetsDevicessAction implements Action {
  readonly type = DevicesActionTypes.LoadWidgetsDevicess;

  constructor(public payload: Widget[]) {}
}

export class SetDeviceAction implements Action {
  readonly type = DevicesActionTypes.SetDevice;

  constructor(public payload: ICurrentDevInfo) {}
}

export class SetTitleForTableAction implements Action {
  readonly type = DevicesActionTypes.SetTitleForTable;

  constructor(public payload: string) {}
}

export class SetFromDateAction implements Action {
  readonly type = DevicesActionTypes.SetFromDate;

  constructor(public payload: Date) {}
}

export class SetToDateAction implements Action {
  readonly type = DevicesActionTypes.SetToDate;

  constructor(public payload: Date) {}
}
export class SetTooDateAction implements Action {
  readonly type = DevicesActionTypes.SetTooDate;

  constructor(public payload: Date) {}
}

export class SetTypePeriodAction implements Action {


  readonly type = DevicesActionTypes.SetTypePeriod;

  constructor(public payload: string) {}
}

export class LoadCurrentDeviceAction implements Action {
  readonly type = DevicesActionTypes.LoadCurrentDevice;

  constructor(public payload: TableAllDevice) {}
}

export class SetCurrentStatusDeviceAction implements Action {
  readonly type = DevicesActionTypes.SetCurrentStatusDevice;

  constructor(public payload: DeviceForStatus) {}
}

export class LoadCurrentStatusDeviceAction implements Action {
  readonly type = DevicesActionTypes.LoadCurrentStatusDevice;

  constructor(public payload: DeviceForStatus) {}
}

export class SetActiveStatusAction implements Action {
  readonly type = DevicesActionTypes.SetActiveStatus;

  constructor(public payload: number) {}
}


export type DevicesActions = LoadDevicesAction | SetDeviceAction | SetFilterTableDevicesActions |
  SetFromDateAction | SetToDateAction | SetTooDateAction | SetTypePeriodAction | LoadCurrentDeviceAction
  | LoadWidgetsDevicessAction | SetTitleForTableAction | LoadCurrentIperlReceiptAction
  | SetCurrentStatusDeviceAction | LoadCurrentStatusDeviceAction | SetActiveStatusAction;

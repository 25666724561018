import { Action } from "@ngrx/store";

export enum LoadingActionTypes {
  isLoadings = "isLoadings",
  isWidgetsLoading = "isWidgetsLoading",
  isEnterAppLoading = "isEnterAppLoading",
}

export class IsLoadings implements Action {
  readonly type = LoadingActionTypes.isLoadings;

  constructor(public payload: boolean) {}
}
export class IsWidgetsLoading implements Action {
  readonly type = LoadingActionTypes.isWidgetsLoading;

  constructor(public payload: boolean) {}
}
export class IsEnterAppLoadingAction implements Action {
  readonly type = LoadingActionTypes.isEnterAppLoading;

  constructor(public payload: boolean) {}
}
export type LoadingActions =
  | IsLoadings
  | IsWidgetsLoading
  | IsEnterAppLoadingAction;

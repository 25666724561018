import {InfoActiveThreshold, TableDataHead, Thresholds, ValidationDataPressure} from '@interfaces';
import { Action } from '@ngrx/store';

export enum TelemetryAction {
   LOAD_ALL_TABLE_PRESSURE = '[TELEMETRY] LOAD_ALL_TABLE_PRESSURE',
     UPDATE_ONE_DEVICE_TABLE_PRESSURE = '[TELEMETRY] UPDATE_ONE_DEVICE_TABLE_PRESSURE',
    IS_ACTIVE_DETAIL_BLOCK = '[TELEMETRY] IS_ACTIVE_DETAIL_BLOCK',
     SET_CURRENT_DEVICE_PRESSURE = '[TELEMETRY] SET_CURRENT_DEVICE_PRESSURE',
    LOAD_DATA_CURRENT_DEVICE_PRESSURE = '[TELEMETRY] LOAD_DATA_CURRENT_DEVICE_PRESSURE',
     LOAD_INFO_ACTIVE_THRESHOLDS_FOR_ONE_DEVICE = '[TELEMETRY] LOAD_INFO_ACTIVE_THRESHOLDS_FOR_ONE_DEVICE',
     UPDATE_ONE_DEVICE_TABLE_PRESSURE_OBJECT = '[TELEMETRY] UPDATE_ONE_DEVICE_TABLE_PRESSURE_OBJECT',
     LOAD_TELEMETRY_DATA_TABLE_PRESSURE_ALL = '[TELEMETRY] LOAD_TELEMETRY_DATA_TABLE_PRESSURE_ALL',
     SET_ACTIVE_TAB_TELEMETRY_ONE_DEVICE = '[TELEMETRY] SET_ACTIVE_TAB_TELEMETRY_ONE_DEVICE',
     SET_DIMENSION = '[TELEMETRY] SET_DEMENSION',
     SET_STATE_AFTER_LOAD = '[TELEMETRY]  SET_STATE_AFTER_LOAD',
     Clear = '[TELEMETRY] Loguot'
}

export class ClearStateTelemAction implements Action {
  readonly type = TelemetryAction.Clear;
  constructor() {}
}

export class SetStateAfterTelemPressureAction implements Action {
    readonly type = TelemetryAction.SET_STATE_AFTER_LOAD;
    constructor() {}
}
export class LoadAllTablePressureAction implements Action {
  readonly type = TelemetryAction.LOAD_ALL_TABLE_PRESSURE;
  constructor(public payload: TableDataHead) {}
}
export class SetDimensionTeemAction implements Action {
  readonly type = TelemetryAction.SET_DIMENSION;
  constructor(public payload: string) {}
}

export class UpdateOneDevicePressureAction implements Action {
    readonly type = TelemetryAction.UPDATE_ONE_DEVICE_TABLE_PRESSURE;
    constructor(public payload: ValidationDataPressure) {}
}
export class UpdateOneDevicePressureObjectAction implements Action {
  readonly type = TelemetryAction.UPDATE_ONE_DEVICE_TABLE_PRESSURE_OBJECT;
  constructor(public payload: any) {}
}

export class IsActiveDetailBlockAction implements Action {
    readonly type = TelemetryAction.IS_ACTIVE_DETAIL_BLOCK;
    constructor(public payload: boolean) {}
}

export class SetCurrentDevicePressureAction implements Action {
    readonly type = TelemetryAction.SET_CURRENT_DEVICE_PRESSURE;
    constructor(public payload: any) {}
}

export class LoadDataCurrentTablePressureAction implements Action {
    readonly type = TelemetryAction.LOAD_DATA_CURRENT_DEVICE_PRESSURE;
    constructor(public payload: any[]) {}
}

export class LoadDataAllDataTablePressureAction implements Action {
    readonly type = TelemetryAction.LOAD_TELEMETRY_DATA_TABLE_PRESSURE_ALL;
    constructor(public payload: any) {}
}

export class LoadInfoUpdateThresholdsForOneDevice implements Action {
    readonly type = TelemetryAction.LOAD_INFO_ACTIVE_THRESHOLDS_FOR_ONE_DEVICE;
    constructor(public payload: Thresholds<InfoActiveThreshold>) {}
}

export class SetActiveTabTelemetryOneDevice implements Action {
    readonly type = TelemetryAction.SET_ACTIVE_TAB_TELEMETRY_ONE_DEVICE;

    constructor(public payload: string) {}
}

export type TelemetryPageActions = LoadAllTablePressureAction
| SetCurrentDevicePressureAction | ClearStateTelemAction
| LoadDataCurrentTablePressureAction
| IsActiveDetailBlockAction
| UpdateOneDevicePressureAction
| UpdateOneDevicePressureObjectAction
| LoadInfoUpdateThresholdsForOneDevice | SetStateAfterTelemPressureAction
| LoadDataAllDataTablePressureAction | SetDimensionTeemAction
| SetActiveTabTelemetryOneDevice ;

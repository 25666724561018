import { Injectable } from "@angular/core";
import { HttpRequest } from "@angular/common/http";
import { TokensService } from "../tokens.service";

@Injectable({
  providedIn: "root",
})
export class ReqestCloneJwt {
  constructor(private tokens: TokensService) {}

  public addTokenToRequest(request: HttpRequest<any>): HttpRequest<any> {
    return request.clone({
      setHeaders: { Authorization: `Bearer ` + this.tokens.getTokenAccess() },
    });
  }
}

import { NavigationPoints } from "@enum";
import { arrayFromEnum } from "./array-from-enum.util";

export const getInitialActivePageUtil = (): NavigationPoints => {
  const arrayPaths: string[] = arrayFromEnum(NavigationPoints);
  const currentPath: string = window.location.pathname.substr(1);
  return arrayPaths.includes(currentPath)
    ? (currentPath as NavigationPoints)
    : NavigationPoints.main;
};

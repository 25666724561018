import { Injectable } from "@angular/core";
import { PrimeNGConfig } from "primeng/api";
import { TranslateService } from "@ngx-translate/core";
import { GET_PRIME_LOCALE } from "../localies/locale-compare-matrix";
import { distinctUntilChanged } from "rxjs";

@Injectable()
export class PrimeNgConfigService {
  constructor(
    private primengConfig: PrimeNGConfig,
    private translate: TranslateService,
  ) {}

  public init(): void {
    this.primengConfig.zIndex = {
      modal: 1100, // dialog, sidebar
      overlay: 1000, // dropdown, overlaypanel
      menu: 1000, // overlay menus
      tooltip: 1100, // tooltip
    };

    this.primengConfig.ripple = true;

    this.initConfigLocalePrimeComponent();
  }

  private initConfigLocalePrimeComponent(): void {
    this.setPrimeNGLocale();

    this.translate.onLangChange
      .pipe(distinctUntilChanged())
      .subscribe(() => this.setPrimeNGLocale());
  }

  private setPrimeNGLocale(): void {
    const lang: string = this.translate.currentLang;
    const config: Record<string, unknown> = GET_PRIME_LOCALE[lang];
    this.primengConfig.setTranslation(config);
  }
}

import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "@guards";
import { NavigationPoints } from "@enum";

const routes: Routes = [
  {
    path: "",
    redirectTo: "main",
    pathMatch: "full",
  },
  {
    path: NavigationPoints.login,
    loadChildren: () =>
      // @ts-ignore
      import("./pages/login/login.module").then((m) => m.LoginModule),
  },
  {
    path: NavigationPoints.main,
    loadChildren: () =>
      // @ts-ignore
      import("./pages/main/main.module").then((m) => m.MainModule),
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

export const LOCALE_UA: Record<string, unknown> = {
  accept: "Так",
  addRule: "Додати правило",
  am: "до полудня",
  apply: "Прийняти",
  cancel: "Відміна",
  choose: "Виберіть",
  chooseDate: "Виберіть дату",
  chooseMonth: "Виберіть місяць",
  chooseYear: "Виберіть рік",
  clear: "Очистити",
  completed: "Завершено",
  contains: "Містить",
  custom: "Користувальницький",
  dateAfter: "Дата після",
  dateBefore: "Дата до",
  dateFormat: "dd.mm.yy",
  dateIs: "Дата дорівнює",
  dateIsNot: "Дата не дорівнює",
  dayNames: [
    "Неділя",
    "Понеділок",
    "Вівторок",
    "Середа",
    "Четвер",
    "П'ятниця",
    "Субота",
  ],
  dayNamesMin: ["Нд", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
  dayNamesShort: ["Нед", "Пон", "Втр", "Срд", "Чтв", "Птн", "Сбт"],
  emptyFilterMessage: "Результатів не знайдено",
  emptyMessage: "Немає доступних варіантів",
  emptySearchMessage: "Результатів не знайдено",
  emptySelectionMessage: "Немає вибраного елемента",
  endsWith: "Закінчується",
  equals: "Дорівнює",
  fileSizeTypes: ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
  filter: "Фільтр",
  firstDayOfWeek: 1,
  gt: "Більш ніж",
  gte: "Більш ніж або дорівнює",
  lt: "Менше ніж",
  lte: "Менш ніж або дорівнює",
  matchAll: "Порівняти все",
  matchAny: "Збіг з будь-яким",
  medium: "Нормальний",
  monthNames: [
    "Січень",
    "Лютий",
    "Березень",
    "Квітень",
    "Травень",
    "Червень",
    "Липень",
    "Серпень",
    "Вересень",
    "Жовтень",
    "Листопад",
    "Грудень",
  ],
  monthNamesShort: [
    "Січ",
    "Лют",
    "Бер",
    "Квіт",
    "Трав",
    "Чер",
    "Лип",
    "Сер",
    "Вер",
    "Жов",
    "Лист",
    "Груд",
  ],
  nextDecade: "Наступне десятиліття",
  nextHour: "Наступна година",
  nextMinute: "Наступна хвилина",
  nextMonth: "Наступний місяць",
  nextSecond: "Наступна секунда",
  nextYear: "Наступний рік",
  noFilter: "Немає фільтра",
  notContains: "Не містить",
  notEquals: "Не дорівнює",
  now: "Тепер",
  passwordPrompt: "Введіть пароль",
  pending: "В очікуванні",
  pm: "після полудня",
  prevDecade: "Попереднє десятиліття",
  prevHour: "Попередня година",
  prevMinute: "Попередня хвилина",
  prevMonth: "Попередній місяць",
  prevSecond: "Попередня секунда",
  prevYear: "Попередній рік",
  reject: "Ні",
  removeRule: "Видалити правило",
  searchMessage: "{0} результатів доступно",
  selectionMessage: "{0} елементів вибрано",
  showMonthAfterYear: false,
  startsWith: "Починається з",
  strong: "Гарний",
  today: "Сьогодні",
  upload: "Завантажити",
  weak: "Простий",
  weekHeader: "Тиж.",
  aria: {
    cancelEdit: "Скасувати редагування",
    close: "Закрити",
    collapseRow: "Рядок згорнутий",
    editRow: "Редагування рядка",
    expandRow: "Рядок розгорнутий",
    falseLabel: "Невірно",
    filterConstraint: "Обмеження фільтра",
    filterOperator: "Оператор фільтра",
    firstPageLabel: "Перша сторінка",
    gridView: "У вигляді сітки",
    hideFilterMenu: "Сховати меню фільтра",
    jumpToPageDropdownLabel: "Перейти до списку сторінок, що розкривається",
    jumpToPageInputLabel: "Перейти до введення сторінки",
    lastPageLabel: "Остання сторінка",
    listView: "У вигляді списку",
    moveAllToSource: "Перемістити все в джерело",
    moveAllToTarget: "Перемістити все до приймача",
    moveBottom: "Перемістити в кінець",
    moveDown: "Перемістити вниз",
    moveTop: "Перемістити на початок",
    moveToSource: "Перемістити до джерела",
    moveToTarget: "Перемістити до приймача",
    moveUp: "Перемістити вгору",
    navigation: "Навігація",
    next: "Наступний",
    nextPageLabel: "Наступна сторінка",
    nullLabel: "Не вибраний",
    otpLabel: "Введіть символ одноразового пароля {0}",
    pageLabel: "{page}",
    passwordHide: "Приховати пароль",
    passwordShow: "Показати пароль",
    previous: "Попередній",
    previousPageLabel: "Попередня сторінка",
    rotateLeft: "Повернути вліво",
    rotateRight: "Повернути праворуч",
    rowsPerPageLabel: "Рядок на сторінці",
    saveEdit: "Зберегти редагування",
    scrollTop: "Прокрутити на початок",
    selectAll: "Вибрано всі елементи",
    selectRow: "Вибрано рядок",
    showFilterMenu: "Показати меню фільтра",
    slide: "Слайд",
    slideNumber: "{slideNumber}",
    star: "1 зірка",
    stars: "{star} зірок",
    trueLabel: "Вірно",
    unselectAll: "Усі елементи не вибрані",
    unselectRow: "Рядок не вибрано",
    zoomImage: "Збільшити зображення",
    zoomIn: "Збільшити",
    zoomOut: "Зменшити",
  },
};
